import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import IndicatorItem from './IndicatorItem';

const FiltersDialog = ({ filterOptions, intl }) => (
    <div className="edit-filter-mask-dialog">
        <div className="flex-it column">
            {filterOptions.map(({ icon, titleId, classes, onClickEdit, onClickClear }) => (
                <IndicatorItem
                    key={intl.formatMessage({ id: titleId })}
                    icon={icon}
                    title={intl.formatMessage({ id: titleId })}
                    classes={classes}
                    onClickEdit={onClickEdit}
                    onClickClear={onClickClear}
                />
            ))}
        </div>
    </div>
);

FiltersDialog.propTypes = {
    filterOptions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.node.isRequired,
            titleId: PropTypes.string.isRequired,
            classes: PropTypes.string,
            onClickEdit: PropTypes.func.isRequired,
            onClickClear: PropTypes.func.isRequired,
        })
    ).isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(FiltersDialog);

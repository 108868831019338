import BaseController from './BaseController';

import UserSettingsDataSource from '../dataSources/UserSettingsDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import VisualReportDataSource from '../dataSources/VisualReportDataSource';

import UserMapView from '../objects/UserMapView';
import InitialView from '../objects/InitialView';

class UserSettingsController extends BaseController {
    static get name() {
        return 'UserSettingsController';
    }

    static getInstance(options) {
        return new UserSettingsController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            USER_MAP_VIEWS_REQUEST: this.onUserMapViews,
            USER_SETTINGS_LOAD_REQUEST: this.onUserSettingsLoadRequest,
            SAVE_USER_MAP_VIEW_REQUEST: this.onSaveUserMapViewRequest,
            EDIT_USER_MAP_VIEW_REQUEST: this.onEditUserMapViewRequest,
            REMOVE_USER_MAP_VIEW_REQUEST: this.onRemoveUserMapViewRequest,
        });

        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.userSettingsDataSource = this.activateSource(
            UserSettingsDataSource,
        );
        this.visualReportDataSource = this.activateSource(VisualReportDataSource);
    }

    onUserMapViews() {
        const { userMapViews } = this.userSettingsDataSource;
        this.bus.emit('USER_MAP_VIEWS', userMapViews);
    }

    onUserSettingsLoadRequest = () => {
        // load user saved map views
        this.userSettingsDataSource.loadUserMapViews().then(
            userMapViews => {
                this.bus.emit('USER_SETTINGS_LOAD_SUCCESS', userMapViews);
            },
            error => console.error(error),
        );

        // load user saved visual reports
        this.visualReportDataSource.loadVisualReports().then(
            visualReports => {
                this.bus.emit('VISUAL_REPORTS_LOAD_SUCCESS', visualReports);
            },
            error => console.error(error),
        );
    };

    onSaveUserMapViewRequest = payload => {
        const { mapInstanceId, changes } = payload;
        const mapInstance =
            this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const currentMapView = mapInstance.initialView;

        const userMapViewPayload = {
            title: changes.title,
            centerLat: changes.latitude,
            centerLng: changes.longitude,
            zoom: changes.zoom,
            boundingBoxNeLat: currentMapView.boundingBoxNeLat,
            boundingBoxNeLng: currentMapView.boundingBoxNeLng,
            boundingBoxSwLat: currentMapView.boundingBoxSwLat,
            boundingBoxSwLng: currentMapView.boundingBoxSwLng,
        };

        this.userSettingsDataSource
            .saveUserMapView(userMapViewPayload)
            .then(response => {
                if (!response.error) {
                    const savedMapView = response;
                    const userMapView = new UserMapView({
                        id: savedMapView.id,
                        title: savedMapView.title,
                        mapView: new InitialView(savedMapView),
                    });
                    this.userSettingsDataSource.userMapViews.push(userMapView);
                }
                this.bus.emit('USER_MAP_VIEW_SAVED');
            });
    };

    onEditUserMapViewRequest = payload => {
        const { mapViewId, changes } = payload;
        const { mapView } = this.userSettingsDataSource.userMapViews.find(
            item => item.id === mapViewId,
        );

        const latitudeDelta = changes.latitude - mapView.centerLat;
        const longitudeDelta = changes.longitude - mapView.centerLng;

        const updateMapViewPayload = {
            mapViewId,
            title: changes.title,
            centerLat: changes.latitude,
            centerLng: changes.longitude,
            zoom: changes.zoom,
            boundingBoxNeLat: mapView.boundingBoxNeLat + latitudeDelta,
            boundingBoxNeLng: mapView.boundingBoxNeLng + longitudeDelta,
            boundingBoxSwLat: mapView.boundingBoxSwLat + latitudeDelta,
            boundingBoxSwLng: mapView.boundingBoxSwLng + longitudeDelta,
        };

        this.userSettingsDataSource
            .updateUserMapView(updateMapViewPayload)
            .then(response => {
                if (!response.error) {
                    const updatedItem =
                        this.userSettingsDataSource.userMapViews.find(
                            userMapView => userMapView.id === mapViewId,
                        );

                    const { title, centerLat, centerLng, zoom } =
                        updateMapViewPayload;
                    const {
                        boundingBoxNeLat,
                        boundingBoxNeLng,
                        boundingBoxSwLat,
                        boundingBoxSwLng,
                    } = updateMapViewPayload;

                    // When user changes lat/long or zoom navigate to new map point
                    const updatePosition =
                        updatedItem &&
                        (updatedItem.mapView.centerLat !== centerLat ||
                            updatedItem.mapView.centerLng !== centerLng ||
                            updatedItem.mapView.zoom !== zoom);

                    if (updatedItem) {
                        updatedItem.title = title;
                        updatedItem.mapView.centerLat = centerLat;
                        updatedItem.mapView.centerLng = centerLng;
                        updatedItem.mapView.zoom = zoom;
                        updatedItem.mapView.boundingBoxNeLat = boundingBoxNeLat;
                        updatedItem.mapView.boundingBoxNeLng = boundingBoxNeLng;
                        updatedItem.mapView.boundingBoxSwLat = boundingBoxSwLat;
                        updatedItem.mapView.boundingBoxSwLng = boundingBoxSwLng;
                    }

                    this.bus.emit('USER_MAP_VIEW_UPDATED', {
                        updatedItem,
                        updatePosition,
                    });
                }
            });
    };

    onRemoveUserMapViewRequest = payload => {
        this.userSettingsDataSource
            .removeUserMapView({ mapViewId: payload.id })
            .then(response => {
                if (!response.error) {
                    const deletedItemIndex =
                        this.userSettingsDataSource.userMapViews.findIndex(
                            item => item.id === payload.id,
                        );
                    if (deletedItemIndex !== -1) {
                        this.userSettingsDataSource.userMapViews.splice(
                            deletedItemIndex,
                            1,
                        );
                    }
                }

                this.bus.emit('USER_MAP_VIEW_DELETED');
            });
    };
    
    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default UserSettingsController;

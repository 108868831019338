// @ts-check
import Fuse from 'fuse.js';
import BaseController from './BaseController';
import PointsDataSource from '../dataSources/PointsDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import SearchDataSource from '../dataSources/SearchDataSource';
import LocationAnalysisItem from '../objects/LocationAnalysisItem';
import LocationAnalysisItemOrigin from '../enums/LocationAnalysisItemOrigin';
import dataToXlsx from '../helpers/ExcelHelper';
import AppConfig from '../appConfig';
import { ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS } from '../enums/PoiTypes';
import {
    DEMOGRAPHY_REPORT_DATA,
    FACILITY_REPORT_DATA,
    SQFT_PER_CAPITA_REPORT_DATA,
} from '../helpers/ReportDefiniton';
import ExcelNumberFormat from '../enums/ExcelNumberFormat';

const SEARCH_THRESHOLD = 0.4;
const DOWNLOAD_LIMIT = 1000;

const SIZE_CATS = ['5x5', '5x10', '10x10', '10x15', '10x20', '10x30'];

class PointsController extends BaseController {
    static get name() {
        return 'PointsController';
    }

    static getInstance(options) {
        return new PointsController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            GEO_JSON_METADATA_LOAD_REQUEST: this.onGeoJsonMetadataLoadRequest,
            MAP_LOAD: this.onMapLoad,
            GEO_JSON_METADATA_REQUEST: this.onGeoJsonMetadataRequest,
            POINTS_POPUP_FIELDS_REQUEST: this.onPointPopupFieldsRequest,
            POINTS_FILTERS_REQUEST: this.onPointsFilterRequest,
            POINTS_SEARCH_FIELDS_REQUEST: this.onPointsSearchFieldsRequest,
            APPLY_POINTS_FILTERS_REQUEST: this.onApplyPointsFilter,
            SEARCH_POINT_GEOJSON_REQUEST: this.onGeoJsonSearch,
            ADD_FEATURE_TO_LOCATION_ANALYSIS_ITEMS: this.onAddFeatureToLocationAnalysis,
            FEATURE_TO_LOCATION_ANALYSIS: this.onFeatureToLocationAnalysis,
            UPDATE_GEO_JSON_LAYER_GROUP_VISIBILITY: this.onUpdateGeoJsonLayerGroupVisibility,
            UPDATE_GEO_JSON_LAYER_VISIBILITY: this.onUpdateGeoJsonLayerVisibility,
            GET_OLAP_REPORT_DATA: this.onOlapPointReportRequest,
            REMOVE_FACILITY_FILTER: this.onRemoveFacilityFilter,
            CLEAR_FACILITY_FILTER: this.onClearFacilityFilter,
            START_LOCATION_ANALYSIS: this.onStartLocationAnalysis,
        });
        this.pointsDataSource = this.activateSource(PointsDataSource);
        this.projectDataSource = this.activateSource(ProjectDataSource);
        /** @type {import('../dataSources/SearchDataSource').default} */
        this.searchDataSource = this.activateSource(SearchDataSource);
        this.servicePointFilter = [];
        this.searchTerm = '';
        this.allServices = [];
        this.removedNodes = [];
        this.addedCriteria = [];
        this.appliedFilters = {};
        this.filterCombinerType = null;
    }

    onMapLoad = async () => {
        try {
            // Fetch point data on map load if geoJSON is defined
            await this.pointsDataSource.loadPointsGeoJson();
            const geoJson = this.pointsDataSource.pointsGeoJson;
            if (geoJson) {
                this.bus.emit('POINTS_GEOJSON', geoJson);
            }

            // Initialize fuzzy search
            const fields4search = this.searchPropertiesToFields().filter(
                filter => filter.weight > 0,
            );
            this.fuse = new Fuse(geoJson.features, {
                includeScore: true, // score 0 perfect, 1 no match at all
                isCaseSensitive: false,
                shouldSort: true, // sorted by score ascending
                threshold: SEARCH_THRESHOLD, // closer to 0, stronger match
                findAllMatches: true, // this slows down the search
                ignoreLocation: true, // ignore where in the string the pattern appears
                ignoreFieldNorm: true,
                minMatchCharLength: 3, // higher number, better match, less results
                keys: fields4search.map(({ property, weight }) => ({
                    // https://fusejs.io/examples.html#weighted-search
                    name: `properties.${property}`, // due to geo feature structure
                    weight,
                })),
            });
        } catch (e) {
            console.error(e);
        }
    };

    onGeoJsonMetadataLoadRequest = async () => {
        try {
            // First load the corresponding metadata
            await this.pointsDataSource.loadPointsMetadata();
            // For now this event is not consumed by any component
            this.bus.emit('GEO_JSON_METADATA_LOAD_SUCCESS', this.pointsDataSource.pointsMetadata);
        } catch (e) {
            console.error(e);
        }
    };

    onPointPopupFieldsRequest() {
        const popupFields = this.popupPropertiesToFields();
        this.bus.emit('POINTS_POPUP_FIELDS', { popupFields });
    }

    onGeoJsonMetadataRequest(geoJsonMetadataRequest) {
        this.bus.emit(
            'GEO_JSON_METADATA',
            this.pointsDataSource.pointsMetadata,
            geoJsonMetadataRequest.source,
        );
    }

    onUpdateGeoJsonLayerGroupVisibility(e) {
        const { visible, mapInstanceId } = e;
        this.bus.emit('MAP_APPLY_GEO_JSON_LIBRARY_LAYERS_UPDATE', { mapInstanceId, visible });
    }

    onUpdateGeoJsonLayerVisibility(e) {
        const { layerVisibility, featurePropertyNameEquivalentToLayerId } = e;
        this.bus.emit('MAP_APPLY_GEO_JSON_LAYER_UPDATE', {
            layerVisibility,
            featurePropertyNameEquivalentToLayerId,
        });
    }

    /**
     * @param {import('@turf/helpers').Feature} feature
     */
    onAddFeatureToLocationAnalysis = feature => {
        this.bus.emit('ADD_TO_LOCATION_ANALYSIS_ITEMS', {
            mapInstanceId: this.projectDataSource.defaultMapInstance.id,
            locationAnalysisItem: this.mapFeatureToLocationAnalysisItem(feature),
        });
    };

    getReverseLocationType = type => {
        switch (type) {
            // SelfStorage specific types
            case 'SELFSTORAGE_FACILITY':
                return 'ss-facility';
            case 'SELFSTORAGE_CONSTRUCTION':
                return 'ss-construction';
            default:
                return 'CUSTOM_POINT';
        }
    };

    getLocationType = type => {
        switch (type) {
            // SelfStorage specific types
            case 'ss-facility':
                return 'SELFSTORAGE_FACILITY';
            case 'ss-construction':
                return 'SELFSTORAGE_CONSTRUCTION';
            // Unkown type, default to custom point
            default:
                return 'CUSTOM_POINT';
        }
    };

    getIconForPointType = type => {
        switch (type) {
            case 'SELFSTORAGE_FACILITY':
                return 'warehouse';
            case 'SELFSTORAGE_CONSTRUCTION':
                return 'front_loader';
            default:
                return 'place';
        }
    };

    /**
     * @param {object} param0
     * @param {import('../objects/LocationAnalysisItem').default} param0.userLocation
     * @param {string} param0.mapInstanceId
     * @description Prepares feature for analysis if it is a POI. Else, proceed straight to analysis
     */
    onStartLocationAnalysis = ({ userLocation, mapInstanceId }) => {
        let itemToEmit;
        let mapInstanceIdToEmit;
        // If selected item is hotel, construction site or convention center, we make Location analysis item
        // then attach feature to our Location analysis item and continue with our analysis
        if (
            ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS.some(pt => pt === userLocation.metadata.type)
        ) {
            const analysisItem = new LocationAnalysisItem({
                id: userLocation.id,
                type: this.getReverseLocationType(userLocation.metadata.type),
                value: userLocation.value,
                point: userLocation.point,
                itemOrigin: LocationAnalysisItemOrigin.USER_SAVED_LOCATION,
                // add default selection and analysis type
                // we will need to define the default analysis type and values
                analysisTypeId: 'DRIVING_TIME',
                selection: new Set([5, 15]),
                isGeoAvailable: false,
                searchBoxOrigin: 'POINT',
                icon: this.getIconForPointType(userLocation.metadata.type),
            });
            const { idProperty } = this.pointsDataSource.pointsMetadata.pointPreviewProperties;
            const feature = this.pointsDataSource.pointsGeoJson.features.find(
                geoJsonItem => geoJsonItem.properties[idProperty] === analysisItem.id,
            );

            itemToEmit = this.mapFeatureToLocationAnalysisItem(
                feature,
                LocationAnalysisItemOrigin.USER_SAVED_LOCATION,
            );
            mapInstanceIdToEmit =
                mapInstanceId == null
                    ? this.projectDataSource.defaultMapInstance.id
                    : mapInstanceId;
        } else {
            // If saved location is custom point/location, assign default values for emit
            itemToEmit = userLocation;
            mapInstanceIdToEmit = mapInstanceId;
        }
        this.bus.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
            selectedItem: itemToEmit,
            mapInstanceId: mapInstanceIdToEmit,
            showInsights: AppConfig.constants.searchBox.shouldShowInsightsOnPointSearch,
        });
    };

    /**
     * @param {object} param0
     * @param {import('@turf/helpers').Feature} param0.feature
     * @param {string} param0.mapInstanceId
     */
    onFeatureToLocationAnalysis = ({ feature, mapInstanceId }) => {
        this.bus.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
            mapInstanceId:
                mapInstanceId == null
                    ? this.projectDataSource.defaultMapInstance.id
                    : mapInstanceId,
            selectedItem: this.mapFeatureToLocationAnalysisItem(feature),
            showInsights: AppConfig.constants.searchBox.shouldShowInsightsOnPointSearch,
        });
    };

    mapPointTypeTovalidVisualReportParam = type => {
        switch (type) {
            case 'nopricing':
            case 'pricing':
                return 'ss-facility';
            case 'construction':
                return 'ss-construction';
            case 'hotel':
                return 'hotel';
            case 'convention_center':
                return 'convention_center';
            case 'construction_site':
                return 'construction_site';
            default:
                return 'place';
        }
    };

    /**
     * Transforms a given point feature into a location analysis item
     * @param {import('@turf/helpers').Feature} feature
     * @returns {LocationAnalysisItem}
     */
    mapFeatureToLocationAnalysisItem = (
        feature,
        locationAnalysisOrigin = LocationAnalysisItemOrigin.GEO_JSON,
    ) => {
        // Transform the point feature into a location analysis item
        const { idProperty, mainProperties } =
            this.pointsDataSource.pointsMetadata.pointPreviewProperties;
        const name = mainProperties.map(property => feature.properties[property]).join(', ');
        const pointTypeProperty =
            this.pointsDataSource.pointsMetadata.mapProperties.legend
                .featurePropertyNameEquivalentToLayerId;
        return new LocationAnalysisItem({
            id: feature.properties[idProperty],
            type: this.mapPointTypeTovalidVisualReportParam(feature.properties[pointTypeProperty]),
            value: name,
            point: {
                lng: feature.geometry.coordinates[0],
                lat: feature.geometry.coordinates[1],
            },
            itemOrigin: locationAnalysisOrigin,
            // add default selection and analysis type
            analysisTypeId: AppConfig.constants.defaultLocationAnalysisSelectionType,
            feature,
            selection: new Set(AppConfig.constants.defaultLocationAnalysisSelection),
            searchBoxOrigin: 'POINT',
            icon: this.getIconForPointType(this.getLocationType(pointTypeProperty)),
        });
    };

    onPointsFilterRequest = async () => {
        this.bus.emit(
            'POINTS_FILTERS_RESPONSE',
            this.filterPropertiesToFields(),
            this.addedCriteria,
            this.appliedFilters,
            this.filterCombinerType,
        );
    };

    onPointsSearchFieldsRequest = () => {
        this.bus.emit('POINTS_SEARCH_FIELDS_RESPONSE', this.searchPropertiesToFields());
    };

    /**
     *
     * @param {object} payload
     * @param {string} payload.filterCombinerType
     * @param {import('../').AppliedPointFilters} payload.appliedFilters
     * @param {import('../').PointsFilterField[]} payload.addedCriteria
     */
    onApplyPointsFilter = ({ filterCombinerType, appliedFilters, addedCriteria }) => {
        this.addedCriteria = addedCriteria;
        this.filterCombinerType = filterCombinerType;
        this.appliedFilters = { ...this.appliedFilters, ...appliedFilters };
        this.bus.emit('MAP_APPLY_GEO_JSON_LAYER_UPDATE', {
            filterCombinerType,
            appliedFilters,
        });
    };

    onRemoveFacilityFilter = property => {
        if (
            Object.keys(this.appliedFilters).length > 0 &&
            this.addedCriteria.length > 0 &&
            this.filterCombinerType
        ) {
            delete this.appliedFilters[property.appliedFiltersProperty];
            const indexOfCriteria = this.addedCriteria.findIndex(
                element => element.property === property.appliedFiltersProperty,
            );
            this.addedCriteria.splice(indexOfCriteria, 1);
            this.bus.emit('MAP_APPLY_GEO_JSON_LAYER_UPDATE', {
                filterCombinerType: this.filterCombinerType,
                appliedFilters: this.appliedFilters,
            });
        }
    };

    onClearFacilityFilter = () => {
        this.addedCriteria = [];
        this.appliedFilters = {};
        this.filterCombinerType = null;
        this.bus.emit('MAP_APPLY_GEO_JSON_LAYER_UPDATE', {
            filterCombinerType: undefined,
            appliedFilters: undefined,
        });
    };

    /**
     *
     * @param {object} payload
     * @param {string} payload.searchTerm
     * @param {number} payload.maxResults
     */
    onGeoJsonSearch = ({ searchTerm, maxResults = 10 }) => {
        // Gets the results sorter by score
        // Search config is part of onMapLoad() implementation
        const results = this.fuse.search(searchTerm);
        this.bus.emit('SEARCH_POINT_GEOJSON_RESPONSE', {
            count: results.length,
            results: results.slice(0, maxResults).map(r => r.item),
        });
    };

    onOlapPointReportRequest = async ({ mapInstanceId, olapReportId, olapReportName }) => {
        await this.onSelfStorageOlapReportRequest(mapInstanceId, olapReportId, olapReportName);
    };

    /**
     * Create a XLSX/CSV report using olap data
     * @param {*} mapInstanceId map instance
     * @param {string} reportId id of report
     * @param {string} reportName name of report, used for XLSX/CSV file name
     */
    onSelfStorageOlapReportRequest = async (mapInstanceId, reportId, reportName) => {
        const mapInstance = this.projectDataSource.getActiveMapInstance(mapInstanceId);
        const { locationAnalysisItem } = mapInstance;
        const { analysisType } = locationAnalysisItem;

        /** @type {import('../types').OlapSiteAnalysisPayload} */
        const payload = {
            site: {
                type: 'custom',
                lng: locationAnalysisItem.point.lng,
                lat: locationAnalysisItem.point.lat,
            },
            reportContours: {
                type: analysisType.VISUAL_REPORT_PROFILE,
                values: locationAnalysisItem.sortedSelectionAsArray,
            },
        };

        switch (reportId) {
            case 'olap_demography_report':
                await this.createDemographyReport(
                    payload,
                    locationAnalysisItem,
                    analysisType,
                    reportName,
                );
                break;
            case 'olap_sqft_per_capita_report':
                await this.createSqftPerCapitaReport(
                    payload,
                    locationAnalysisItem,
                    analysisType,
                    reportName,
                );
                break;
            case 'selfstorage_facility_data':
                await this.createSelfstorageFacilityReport(
                    payload,
                    locationAnalysisItem,
                    analysisType,
                    reportName,
                );
                break;
            default:
                throw new Error(`Unknown report ID for GeoJSON report: ${reportId}`);
        }

        this.bus.emit('CREATE_REPORT_SUCCESS');
    };

    createDemographyReport = async (payload, locationAnalysisItem, analysisType, reportName) => {
        const demographySectionPromises = this.pointsDataSource.fetchOlapSiteAnalysis(
            payload,
            'demography',
        );
        await Promise.all(demographySectionPromises).then(sections => {
            sections.forEach(section => {
                const demography = section.data;
                // Convert string values to number
                demography.total_household_income = +demography.total_household_income;
                demography.total_household_income_projected =
                    +demography.total_household_income_projected;
                demography.total_retail_sales = +demography.total_retail_sales;
                demography.income_per_capita = +demography.income_per_capita;
                demography.income_per_capita_projected = +demography.income_per_capita_projected;

                // Calculate growth
                demography.population_2020_current_growth =
                    demography.population - demography.population_2020;
                demography.population_growth_projected =
                    demography.population_projected_census - demography.population;
                demography.population_avg_annual_growth_projected =
                    demography.population_growth_projected / 5;

                demography.households_2020_current_growth =
                    demography.households - demography.households_2020;
                demography.households_growth_projected =
                    demography.households_projected - demography.households;
                demography.households_avg_annual_growth_projected =
                    demography.households_growth_projected / 5;

                // Calculate the average annual growth rate from 2020 to the current year.
                // This is done by dividing the total growth by the number of years between 2020 and the current year (currently 3 years).
                // Update the divisor when new data is added to reflect the correct number of years.
                demography.population_2020_current_avg_annual_growth =
                    demography.population_2020_current_growth / 3;
                demography.households_2020_current_avg_annual_growth =
                    demography.households_2020_current_growth / 3;

                demography.population_age_6_to_17yrs =
                    demography.population_age_6_to_11yrs + demography.population_age_12_to_17yrs;
                demography.population_age_6_to_17yrs_projected =
                    demography.population_age_6_to_11yrs_projected +
                    demography.population_age_12_to_17yrs_projected;

                demography.population_age_above_25yrs =
                    demography.population_age_25_to_34yrs +
                    demography.population_age_35_to_44yrs +
                    demography.population_age_45_to_54yrs +
                    demography.population_age_55_to_64yrs +
                    demography.population_age_65_to_74yrs +
                    demography.population_age_75_to_84yrs +
                    demography.population_age_above_85yrs;

                demography.units = demography.housing_occupied + demography.housing_vacant;
                demography.units_2020 =
                    demography.homeowners_2020 +
                    demography.renters_2020 +
                    demography.housing_vacant_2020;
                demography.units_projected =
                    demography.housing_occupied_projected + demography.housing_vacant_projected;
            });

            const options = {
                title: 'Single Location Demographic Report 2020',
                sheetName: 'Single Location Demographic Rep',
                locationAnalysisItem: locationAnalysisItem,
                analysisType: analysisType,
                reportName: reportName,
            };
            this.createOlapReport(
                sections.map(section => section.data),
                options,
                DEMOGRAPHY_REPORT_DATA,
            );
        });
    };

    createSqftPerCapitaReport = async (payload, locationAnalysisItem, analysisType, reportName) => {
        const summaryDetailedSectionPromises = this.pointsDataSource.fetchOlapSiteAnalysis(
            payload,
            'executive-summary-detailed',
        );
        const executiveSummarySectionPromises = this.pointsDataSource.fetchOlapSiteAnalysis(
            payload,
            'executive-summary',
        );
        const sections = [];
        await Promise.all(summaryDetailedSectionPromises).then(detailedSections => {
            detailedSections.forEach(section => {
                const demography = section.data.demography;
                const facilities = section.data.facilities;

                const sectionData = {
                    population_density: demography.population_density,
                    population: demography.population,
                    incoming_population_census:
                        demography.population_projected_census - demography.population,
                    incoming_population_housing:
                        demography.population_projected_housing - demography.population,
                    population_projected_census: demography.population_projected_census,
                    population_projected_housing: demography.population_projected_housing,
                    population_growth_census:
                        (demography.population_projected_census - demography.population) /
                        demography.population,
                    population_growth_housing:
                        (demography.population_projected_housing - demography.population) /
                        demography.population,
                    median_household_income: demography.median_household_income,
                    median_household_income_projected: demography.median_household_income_projected,
                    average_household_income: demography.average_household_income,
                    households: demography.households,
                    housing_occupied_percent: demography.homeowners / demography.households,
                    housing_renters_percent: demography.renters / demography.households,

                    facilities_count: facilities.filter(
                        ({ under_construction }) => !under_construction,
                    ).length,
                    facilities_cc: facilities.filter(({ has_cc_units }) => has_cc_units).length,
                    facilities_count_incoming: facilities.filter(
                        ({ under_construction }) => under_construction,
                    ).length,
                    facilities_count_projected: facilities.length,

                    gross_sqft: facilities
                        .filter(({ under_construction }) => !under_construction)
                        .reduce((sum, { sqft }) => sum + sqft, 0),
                    gross_sqft_incoming: facilities
                        .filter(({ under_construction }) => under_construction)
                        .reduce((sum, { sqft }) => sum + sqft, 0),

                    gross_sqft_projected: facilities.reduce((sum, { sqft }) => sum + sqft, 0),

                    rent_sqft: facilities
                        .filter(({ under_construction }) => !under_construction)
                        .reduce((sum, { rent_sqft }) => sum + rent_sqft, 0),
                    rent_sqft_incoming: facilities
                        .filter(({ under_construction }) => under_construction)
                        .reduce((sum, { rent_sqft }) => sum + rent_sqft, 0),

                    rent_sqft_projected: facilities.reduce(
                        (sum, { rent_sqft }) => sum + rent_sqft,
                        0,
                    ),

                    sqft_driveup: facilities
                        .filter(({ under_construction }) => !under_construction)
                        .reduce((sum, { sqft_driveup }) => sum + sqft_driveup, 0),
                    rent_sqft_driveup: facilities
                        .filter(({ under_construction }) => !under_construction)
                        .reduce((sum, { rent_sqft_driveup }) => sum + rent_sqft_driveup, 0),
                };
                sectionData.facilities_cc_percent =
                    sectionData.facilities_cc / sectionData.facilities_count;
                sectionData.sqft_driveup_percent =
                    sectionData.sqft_driveup / sectionData.gross_sqft;
                sectionData.rent_sqft_driveup_percent =
                    sectionData.rent_sqft_driveup / sectionData.rent_sqft;

                sectionData.sqft_per_capita = sectionData.rent_sqft / sectionData.population;
                sectionData.sqft_per_household = sectionData.rent_sqft / sectionData.households;

                sections.push(sectionData);
            });
        });
        await Promise.all(executiveSummarySectionPromises).then(summarySections => {
            summarySections.forEach((section, idx) => {
                const data = section.data;
                const sectionData = sections[idx];

                sectionData.sqft_per_capita_projected_census =
                    data.sqft_per_capita_projected_census;
                sectionData.sqft_per_capita_projected_housing =
                    data.sqft_per_capita_projected_housing;
            });
        });

        const options = {
            title: 'Square Foot per Capita',
            sheetName: 'Square Foot per Capita',
            locationAnalysisItem: locationAnalysisItem,
            analysisType: analysisType,
            reportName: reportName,
        };
        this.createOlapReport(sections, options, SQFT_PER_CAPITA_REPORT_DATA);
    };

    // Helper function to create a cell style
    createCellStyle = ({
        fontName = 'Calibri',
        fontSize = 11,
        bold = false,
        italic = false,
        alignment = {},
        fillColor = null,
        border = null,
    } = {}) => ({
        font: { name: fontName, sz: fontSize, bold, italic },
        alignment: alignment,
        fill: fillColor ? { patternType: 'solid', fgColor: { rgb: fillColor } } : undefined,
        border: border
            ? {
                  top: { style: 'thin', color: { rgb: '000000' } },
                  bottom: { style: 'thin', color: { rgb: '000000' } },
              }
            : undefined,
    });

    // Utility to create merged cell range
    createMergedCell = (startCol, startRow, endCol, endRow) => ({
        s: { c: startCol, r: startRow },
        e: { c: endCol, r: endRow },
    });

    // Main function for creating the OLAP report
    createOlapReport = (sections, options, reportData) => {
        const data = [['', options.title]];
        const cellStyles = [
            {
                cell: 'B1',
                style: this.createCellStyle({
                    fontName: 'Cambria',
                    fontSize: 16,
                    bold: true,
                    alignment: { vertical: 'center', horizontal: 'center' },
                }),
            },
        ];
        const headers = [''];

        options.locationAnalysisItem.sortedSelectionAsArray.forEach((selection, idx) => {
            const columnLetter = String.fromCharCode(66 + idx * 2);
            headers.push(
                `${selection} ${options.analysisType.UNIT} ${options.analysisType.CONTOUR_TYPE} - ${options.locationAnalysisItem.value}`,
                '',
            );
            cellStyles.push({
                cell: `${columnLetter}2`,
                style: this.createCellStyle({
                    fontName: 'Cambria',
                    fontSize: 11,
                    bold: true,
                    alignment: { wrapText: true, vertical: 'center', horizontal: 'center' },
                }),
            });
        });

        data.push(headers, []);
        const mergedCells = [this.createMergedCell(1, 0, 6, 0)]; // B1:G1 - merge title cells
        const cellFormats = [];

        reportData.forEach(group => {
            data.push([group.title]);
            const groupRow = data.length;

            cellStyles.push({
                cell: `A${groupRow}`,
                style: this.createCellStyle({
                    fontName: 'Cambria',
                    fontSize: 11,
                    bold: true,
                    fillColor: 'D3D3D3',
                    border: true,
                }),
            });
            mergedCells.push(
                this.createMergedCell(0, groupRow - 1, sections.length * 2, groupRow - 1),
            );

            group.items.forEach(dataPoint => {
                // Start a new row with the title of the data point
                const dataRow = [dataPoint.title];
                sections.forEach((section, idx) => {
                    // Retrieve the value for the current data point's variable from the section
                    const value = section[dataPoint.variable];
                    dataRow.push(value !== null && value !== undefined ? value : '');

                    const format = dataPoint.format || ExcelNumberFormat.FORMAT_NUMBER;

                    // Check if the data point has a "percentageOf" field (used for calculating percentages)
                    if (dataPoint.percentageOf) {
                        const percentageOfValue = value / section[dataPoint.percentageOf];
                        dataRow.push(
                            percentageOfValue || percentageOfValue === 0 ? percentageOfValue : '',
                        );

                        // Define the cell address for the percentage value (if applicable)
                        const percentageCell = `${String.fromCharCode(67 + idx * 2)}${data.length + 1}`;

                        cellFormats.push({
                            cell: percentageCell,
                            format: ExcelNumberFormat.FORMAT_PERCENT,
                        });
                        cellStyles.push({
                            cell: percentageCell,
                            style: this.createCellStyle({ fillColor: 'FEFEE1' }),
                        });
                    } else {
                        dataRow.push('');
                    }

                    // Define the cell address for the main value
                    const valueCell = `${String.fromCharCode(66 + idx * 2)}${data.length + 1}`;

                    // Add the format and style for the main value cell
                    cellFormats.push({ cell: valueCell, format });
                    cellStyles.push({
                        cell: valueCell,
                        style: this.createCellStyle({
                            fontName: 'Calibri',
                            fillColor:
                                format === ExcelNumberFormat.FORMAT_PERCENT ? 'FEFEE1' : null,
                        }),
                    });
                });
                data.push(dataRow);
            });

            data.push([]); // Empty row after each group
        });

        // Add footer notes
        data.push(
            ...[
                [],
                [],
                ['Note:'],
                [
                    'For data sources, citations and notes please take a look at the sheet titled "Sources & Notes."',
                ],
                [`© Social Explorer 2005-${new Date().getFullYear()}`],
            ],
        );
        cellStyles.push(
            {
                cell: `A${data.length - 2}`,
                style: this.createCellStyle({
                    fontName: 'Cambria',
                    fontSize: 11,
                    bold: true,
                    italic: true,
                }),
            },
            {
                cell: `A${data.length - 1}`,
                style: this.createCellStyle({
                    fontName: 'Cambria',
                    fontSize: 9,
                    alignment: { wrapText: true },
                }),
            },
            {
                cell: `A${data.length}`,
                style: this.createCellStyle({ fontName: 'Cambria', fontSize: 11, bold: true }),
            },
        );

        const excelOptions = {
            merges: mergedCells,
            rowHeights: [
                { idx: 0, height: 40 },
                { idx: 1, height: 40 },
                { idx: data.length - 2, height: 40 },
            ],
            colWidths: [{ idx: 0, width: 180 }],
            cellStyles,
            cellFormats,
        };

        options.locationAnalysisItem.sortedSelectionAsArray.forEach((_, idx) => {
            const colIdx = idx * 2 + 1;
            excelOptions.merges.push(this.createMergedCell(colIdx, 1, colIdx + 1, 1));
            excelOptions.colWidths.push({ idx: colIdx, width: 75 }, { idx: colIdx + 1, width: 75 });
        });

        const sourcesAndNotesSheet = this.createSourcesAndNotesSheet(
            options.reportName,
            'PACCOMOPPORTUNITY2020',
            [],
        );
        const sheets = [
            { data, name: options.sheetName, options: excelOptions },
            sourcesAndNotesSheet,
        ];

        dataToXlsx(sheets, options.reportName, true, true, null);
    };

    // Sources and Notes Sheet Creation
    createSourcesAndNotesSheet = (reportName, tableName, notes) => ({
        name: 'Sources & Notes',
        data: [
            ['Sources & Notes'],
            [`Social Explorer - ${reportName}`],
            [],
            [`Social Explorer Tables - ${tableName}`],
            ['Dataset Notes:'],
            ...notes.map(note => [note]),
        ],
        options: {
            rowHeights: [
                { idx: 0, height: 40 },
                { idx: 1, height: 40 },
            ],
            colWidths: [{ idx: 0, width: 400 }],
            cellStyles: [
                {
                    cell: 'A1',
                    style: this.createCellStyle({
                        fontName: 'Cambria',
                        fontSize: 14,
                        bold: true,
                        alignment: { vertical: 'center', horizontal: 'center' },
                    }),
                },
                {
                    cell: 'A2',
                    style: this.createCellStyle({
                        fontName: 'Cambria',
                        fontSize: 16,
                        bold: true,
                        alignment: { vertical: 'center', horizontal: 'center' },
                    }),
                },
                {
                    cell: 'A4',
                    style: this.createCellStyle({
                        fontName: 'Cambria',
                        fontSize: 11,
                        bold: true,
                        italic: true,
                    }),
                },
                {
                    cell: 'A5',
                    style: this.createCellStyle({
                        fontName: 'Cambria',
                        fontSize: 11,
                        italic: true,
                    }),
                },
                { cell: 'A6', style: this.createCellStyle({ fontName: 'Cambria', fontSize: 9 }) },
            ],
        },
    });

    createSelfstorageFacilityReport = async (
        payload,
        locationAnalysisItem,
        analysisType,
        reportName,
    ) => {
        const sectionPromises = this.pointsDataSource.fetchOlapSiteAnalysis(
            payload,
            'selfstorage-facility',
        );
        const data = [FACILITY_REPORT_DATA.map(value => value.title)];
        await Promise.all(sectionPromises).then(sections => {
            sections.forEach(section => {
                const facilities = section.data;
                facilities.forEach(facility => {
                    facility.category = facility.under_construction
                        ? 'construction'
                        : facility.has_pricing
                          ? 'pricing'
                          : 'nopricing';
                });
                data.push(
                    facilities.map(facility => {
                        let mappedFacility = [];
                        FACILITY_REPORT_DATA.forEach(dataPoint => {
                            let value = facility[dataPoint.variable];
                            if (dataPoint.variable === 'under_construction') {
                                value = value ? 'Construction' : 'Operating facility';
                            }
                            if (typeof value === 'boolean') {
                                mappedFacility.push(value ? 'Yes' : 'No');
                            } else {
                                mappedFacility.push(value);
                            }
                        });
                        return mappedFacility;
                    }),
                );
            });
        });

        // if there is no contour with data available, show error msg and exit
        const noData = data.slice(1).every(arry => !arry.length);

        if (noData) {
            this.bus.emit('DOWNLOAD_REPORT_ERROR', {
                message: 'Cannot download Excel report. No data found!',
            });
            return;
        }

        const sheetNames = locationAnalysisItem.sortedSelectionAsArray.map(
            value => `${analysisType.NAME} ${value} ${analysisType.UNIT}`,
        );
        const headerItems = data[0];
        const sheets = [];

        data.slice(1).forEach((ringData, idx) => {
            const xlsxRingData = ringData.map(row => {
                const obj = {};
                headerItems.forEach((headerItem, headerItemIdx) => {
                    obj[headerItem] = row[headerItemIdx];
                });
                return obj;
            });
            sheets.push({ data: xlsxRingData, name: sheetNames[idx] });
        });

        dataToXlsx(sheets, reportName, false, false, headerItems);
    };

    // TODO: this uses recursion which is nasty. Consider rewriting this to not use recursion.
    /**
     * Recursively gets the addresses of every location analysis item
     * @param {*} locationAnalysisItems
     * @param {number} index item which is currently being processed
     * @returns Nothing - the addresses gets appended to the locationAnalysisItems param
     */
    getAddressForSingleLocationAnalysisItem = async (locationAnalysisItems, index = 0) => {
        const selectedItem = locationAnalysisItems[index];

        if (!selectedItem) return;

        try {
            const address = await this.searchDataSource.hereReverseGeocode(
                {
                    point: selectedItem._point,
                },
                true,
            );
            if (address) {
                const { street, houseNumber, city, stateCode } = address;
                selectedItem.address = `${street} ${houseNumber}`;
                selectedItem.city = city;
                selectedItem.state = stateCode;
            }
        } catch (error) {
            console.error(error);
        }

        await this.getAddressForSingleLocationAnalysisItem(locationAnalysisItems, index + 1);
    };

    // Properties to fields mapping
    searchPropertiesToFields = () => {
        const metadata = this.pointsDataSource.pointsMetadata;
        return metadata.searchProperties.map(property => ({
            property,
            label: metadata.properties[property].label,
            weight: metadata.properties[property].searchWeight,
            isTitle: metadata.properties[property].isTitle,
        }));
    };

    downloadPropertiesToFields = () => {
        const metadata = this.pointsDataSource.pointsMetadata;
        return metadata.downloadProperties.map(property => ({
            property,
            label: metadata.properties[property].label,
            type: metadata.properties[property].type,
            format: metadata.properties[property].format,
        }));
    };

    fieldFromPropertyId = (propertyId, metadata, isIndented) => ({
        property: propertyId,
        label: metadata.properties[propertyId].label,
        isTitle: metadata.properties[propertyId].isTitle,
        isHighlighted: metadata.properties[propertyId].isHighlighted,
        isWebsite: metadata.properties[propertyId].isWebsite,
        isIndented,
        format: metadata.properties[propertyId].format,
    });

    popupPropertiesToFields = () => {
        const metadata = this.pointsDataSource.pointsMetadata;
        const popupProperties = metadata.popupProperties;

        const result = {
            sameForAllPointTypes: popupProperties.sameForAllPointTypes,
            typeProperty: popupProperties.typeProperty,
            propertiesForPointType: {},
        };

        Object.keys(popupProperties.propertiesForPointType).forEach(pointType => {
            result.propertiesForPointType[pointType] = [];
            popupProperties.propertiesForPointType[pointType].forEach(property => {
                if (Array.isArray(property)) {
                    property.forEach(item => {
                        result.propertiesForPointType[pointType].push(
                            this.fieldFromPropertyId(item, metadata, true),
                        );
                    });
                } else {
                    result.propertiesForPointType[pointType].push(
                        this.fieldFromPropertyId(property, metadata, false),
                    );
                }
            });
        });

        return result;
    };

    filterPropertiesToFields = () => {
        const propertyValues = {};
        // Get all feature properties and values
        const geoJson = this.pointsDataSource.pointsGeoJson;
        geoJson.features.forEach(feature => {
            if (feature.properties) {
                Object.keys(feature.properties).forEach(property => {
                    if (propertyValues[property] == null) {
                        propertyValues[property] = [];
                    }
                    if (feature.properties[property] != null) {
                        propertyValues[property].push(feature.properties[property]);
                    }
                });
            }
        });
        const metadata = this.pointsDataSource.pointsMetadata;
        return metadata.filterProperties.map(property => {
            /** @type {import('../').PointsFilterField} */
            const filter = {
                property,
                type: metadata.properties[property].type,
                label: metadata.properties[property].label,
                prefix: metadata.properties[property].filterPrefix,
                suffix: metadata.properties[property].filterSuffix,
                filterType: metadata.properties[property].defaultFilterType,
                value: metadata.properties[property].defaultFilterValue,
            };
            const values = Array.from(new Set(propertyValues[property]));
            if (filter.type === 'string') {
                filter.values = values.filter(value => {
                    // Exclude empty string only if the label is 'Management Type'
                    return !(filter.label === 'Management Type' && value === "");
                });
            } else if (filter.type === 'number') {
                filter.min = Math.min(...values);
                filter.max = Math.max(...values);
            } else if (filter.type === 'boolean') {
                filter.values = [true, false];
            }
            return filter;
        });
    };

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default PointsController;

module.exports={
  "variablePercentBase": "Variable percent base",
  "changeData": "Change data",
  "noVisibleDataLayers": "(no visible data layers)",
  "changeGeographyLevel": "Change geography level",
  "changeVisualizationType": "Change visualization type",
  "changeDataVariable": "Change data variable",
  "changeCurrentVariable": "Change current variable",
  "difference": "Difference",
  "percentChange": "Percent change",
  "compositionChange": "Composition change",
  "unknown": "Unknown viz type",
  "exploreChangeOverTime": "Explore change over time",
  "year": "year",
  "years": "years",
  "changeYear": "Change year",
  "geographyLevel": "Geography level",
  "visualizationType": "Visualization Type",
  "shadedArea": "Shaded Area",
  "bubbles": "Bubbles",
  "dotDensity": "Dot Density",
  "maskMapData": "Mask map data",
  "showDataOnlyForSelectedRegion": "Show data only for selected region.",
  "filterAreasByCriteria": "Filter areas by criteria",
  "identifyLocationsBasedOnSearchParametersYouSpecify": "Identify locations based on search parameters you specify.",
  "mapLayers": "Map layers",
  "uploadDataEditBasemapShowSatelliteView": "Upload data, edit basemap, show satellite view.",
  "uploadData": "Upload data",
  "addLayer": "Add layer",
  "satelliteView": "Satellite view",
  "satelliteMap": "Satellite map",
  "standardView": "Standard view",
  "streetView": "Street map view",
  "annotateMap": "Annotate map",
  "addMarkersLinesShapesOrGroundOverlaysOnTheMap": "Add markers, lines, shapes or ground overlays on the map.",
  "removeFromList": "Remove from list",
  "duplicate": "Duplicate",
  "clearMask": "Clear mask",
  "basemapLayers": "Basemap layers",
  "basemap": "Basemap",
  "layerLibrary": "Layer library",
  "addLayers": "Add layers",
  "selectTheLatitudeAndLongitudeColumns": "Select the latitude and longitude columns from your file to put placemarks on the map.",
  "selectTheLatitudeAndLongitude": "Select the latitude and longitude",
  "numberSelected": "{number} selected",
  "selectLayersFromLibrary": "Select layers from library",
  "styleSatelliteView": "Style satellite view",
  "dataOpacity": "Data opacity",
  "satelliteColor": "Satellite color",
  "satelliteViewOptions": "satellite view options",
  "streetViewOptions": "Street view options",
  "baseMapOptions": "Base map options",
  "useDrawingToolsToDraw": "Use drawing tools to draw polygons, rectangles, polylines, circles, and markers on the map.",
  "loadingAnnotations": "Loading annotations...",
  "paletteColors": "Palette colors",
  "paletteTitle": "Palette title",
  "copyColorsFromAnExistingPalette": "Copy colors from an existing palette",
  "adjustDollarsFromCurrentYear": "Adjust dollars from {currentYear} to have the same buying power as:",
  "cpiCorrection": "CPI Correction",
  "selectDollarYear": "Select dollar year",
  "clearFilters": "Clear filters",
  "timePeriod": "Time period",
  "editLayer": "Edit layer",
  "removeLayer": "Remove layer",
  "personalLayers": "Personal layers",
  "addNewLayerFrom": "Add new layer from:",
  "compareWithYear": "Compare with year/s: {compareYears}",
  "dataIsCalculatedBasedOn": "Data is calculated based on",
  "dataIsCalculatedBasedOnTheLowestGeographyAvailable": "Data is calculated based on the lowest geography available.",
  "searchSource": "Search source",
  "exportingLayer": "Exporting layer",
  "geographiesSelected": "geographies selected",
  "geographySelected": "geography selected",
  "showSearchInViewMode": "Show search in view mode",
  "allowUserToChangeDataGeographyLevel": "Allow user to change data geography level",
  "viewMode": "View mode",
  "annotationHelp": "annotation help",
  "clearChangeOverTime": "Clear Change over time",
  "showSubGeographies": "Show sub-geographies",
  "includeUpwardGeographies": "Include corresponding (upward) geographies",
  "selectGeographies": "Select geographies",
  "clickOnTheMapToSelectGeographies": "Click on the map to select geographies or",
  "addFilter": "Add criteria",
  "chooseVariable": "Choose variable",
  "allData": "All Data",
  "uploadYourPointData": "Upload your point data",
  "dropYourCsvFileHere": "Drop your CSV file here",
  "orIfYouPrefer": "or if you prefer",
  "editList": "Edit list",
  "csvFileTooLarge": "CSV file too large. Please use file smaller than",
  "uploadPreviouslyDownloadedGeoFile": "Upload previously downloaded file containing selected geographic areas",
  "selectUpToLimitYears": "Select up to {limit} years",
  "close": "Close",
  "toggleSubheader": "More actions",
  "totalResults": "{total} results",
  "addValueAndPressEnterToApplyFilter": "Add value and press enter to apply filter",
  "gettingSurveyDetails": "Getting survey details",
  "openDataDictionary": "Open Data Dictionary",
  "copyCitation": "Copy Citation",
  "citationFormat": "Citation format",
  "displaysActualChange": "Displays actual change between two numerical quantities as bubbles. The size of each circle corresponds to it's value - higher the value, larger the circle.",
  "displaysDifferenceBetweenTwoNumerical": "Displays difference between two numerical quantities as percentage of starting value.",
  "displaysDifferenceBetweenTwoPercentages": "Displays difference between two percentages.",
  "visualizeChangeOverTimeAs": "Visualize change over time as...",
  "removeFromSavedList": "Remove from saved list",
  "matchAll": "Match all",
  "matchAny": "Match any",
  "matchNone": "Match none",
  "changeVariable": "Change variable",
  "allCategories": "All categories",
  "chooseDataVariable": "Choose data variable",
  "disclaimer": "Disclaimer",
  "citationDisclaimer": "Data elements have been formatted to meet the latest citation standards. These citations are not a replacement for the latest guidebooks or your instructor's requirements. Double-check capitalization, dates, and names and make any necessary corrections",
  "expandDriveTime": "Expand",
  "minimizeDriveTime": "Collapse",
  "closeAnalysis": "Clear analysis",
  "fitToBounds": "Fit to bounds",
  "aggregation": "Aggregation",
  "points": "Points",
  "beta": "Beta",
  "visualizeLayerAs": "Visualize layer as",
  "currentlyNotSupported": "Currently not supported for bubbles",
  "findFacilitiesByCriteria": "Find facilities by criteria",
  "dataFilter": "Data filter",
  "geoDataFilter": "Geo data filter"
}

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import RadioButton from '../../components/form/RadioButton';
import Loader from '../Loader';

class LocationAnalysisFeaturedReports extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            /** @type {Object.<string, import('../../objects/MetaSurvey').default>} key is the survey name */
            featuredReportsSurveys: {},
            /** @type {Array.<import('../../types').FeaturedSurveyReport | import('../../types').FeaturedOlapReport>} */
            featuredReports: undefined,
            /** @type {boolean} */
            loading: true,
            /** @type {boolean} */
            downloadReport: false,
            /** @type {number} */
            selectedFeaturedReportIdx: undefined,
            /** @type {boolean} */
            isUserStarterTier: false,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            CUSTOM_MAP_SELECTION_SURVEYS: this.onSurveysResponse,
            CUSTOM_MAP_SELECTION_FEATURED_REPORTS: this.onFeaturedReportsResponse,
            USER_INFO_GET_SUCCESS: this.onUserInfo,
        });

        this.emit('CUSTOM_MAP_SELECTION_FEATURED_REPORTS_REQUEST');
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserInfo = (userInfo, source) => {
        if (source !== this) return;
        this.setState({
            isUserStarterTier: userInfo && userInfo.isStarter(),
        });
    };

    /** @param {Array.<import('../../types').FeaturedSurveyReport | import('../../types').FeaturedOlapReport>} featuredReports */
    onFeaturedReportsResponse = featuredReports => {
        this.setState({ featuredReports }, () => {
            const surveyNamesToRetrieve = featuredReports
                .filter(featuredReport => featuredReport.source === 'survey' && !featuredReport.downloadReport)
                .map(featuredSurveyReport => featuredSurveyReport.surveyCode);

            this.emit('CUSTOM_MAP_SELECTION_SURVEYS_REQUEST', {
                surveyNames: surveyNamesToRetrieve,
            });
        });
    };

    /**
     * @param {object} param0
     * @param {Object.<string, import('../../objects/MetaSurvey').default>} param0.surveys
     */
    onSurveysResponse = ({ surveys }) => {
        this.setState(
            {
                featuredReportsSurveys: surveys,
                loading: false,
            },
            () => {
                // set the first featured report as default selected
                if (this.state.featuredReports.length > 0) {
                    this.onSelectedFeaturedReportIdxChange(0);
                }
            }
        );
    };

    onSelectedFeaturedReportIdxChange = selectedReportIdx => {
        const {
            featuredReports,
        } = this.state;

        const newSelectedFeaturedReport = featuredReports[selectedReportIdx];
        this.setState(
            {
                selectedFeaturedReportIdx: selectedReportIdx,
            },
            () => {
                switch (newSelectedFeaturedReport.source) {
                case 'olap':
                    this.onOlapFeaturedReportSelected(newSelectedFeaturedReport);
                    break;
                case 'survey':
                    this.onSurveyFeaturedReportSelected(newSelectedFeaturedReport);
                    break;
                default:
                    throw new Error(`Unknown featured report source: ${newSelectedFeaturedReport.source}`);
                }
            }
        );
    };

    onOlapFeaturedReportSelected = selectedFeaturedReport => {
        this.setState(
            {
                downloadReport: true,
            },
            () => {
                this.emit('CUSTOM_MAP_SELECTION_SET_OLAP_REPORT', {
                    reportName: selectedFeaturedReport.name,
                    downloadReport: true,
                    reportId: selectedFeaturedReport.id,
                });
            }
        );
    };

    onSurveyFeaturedReportSelected = selectedFeaturedReport => {
        const { mapInstanceId } = this.props;
        const { featuredReportsSurveys } = this.state;

        const selectedSurvey =
            featuredReportsSurveys[selectedFeaturedReport.surveyCode];
        const downloadReport = !!selectedFeaturedReport.downloadReport;

        this.setState(
            {
                downloadReport,
            },
            () => {
                this.emit('CUSTOM_MAP_SELECTION_REPORT_PARAMS_CHANGE', {
                    reportParams: {
                        selectedReportTopic: {
                            id: selectedFeaturedReport.reportTopicId,
                            text: selectedFeaturedReport.name,
                        },
                        selectedSurveys: selectedSurvey ? [selectedSurvey] : [],
                        selectedFeaturedReportSurveyCode: selectedFeaturedReport.surveyCode,
                        defaultAggregationType: selectedFeaturedReport.aggregationType,
                    },
                    downloadReport,
                    mapInstanceId,
                });
            }
        );
    };

    render() {
        const {
            loading,
            downloadReport,
            featuredReports,
            selectedFeaturedReportIdx,
            isUserStarterTier,
        } = this.state;

        // If we select a featured report that will be downloaded in Excel
        // there is no need to load anything, so no need to show the loader
        const showLoader = downloadReport ? false : loading;

        if (showLoader) {
            return (
                <Loader
                    text={this.props.intl.formatMessage({
                        id: 'dataBrowser.loadingReportInformation',
                    })}
                />
            );
        }

        const options = featuredReports.map((featuredReport, idx) => (
            <RadioButton
                key={featuredReport.name}
                name={featuredReport.name}
                value={idx}
                checked={idx === selectedFeaturedReportIdx}
                onClick={() => this.onSelectedFeaturedReportIdxChange(idx)}
                disabled={isUserStarterTier && featuredReport.disabledForStarterTier}
                disabledMessage={this.props.intl.formatMessage({
                    id: 'dataBrowser.upgradeToProForAccess',
                })}
            />)
        );

        return <div className="featured-report-list">{options}</div>;
    }
}

LocationAnalysisFeaturedReports.propTypes = {
    intl: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default injectIntl(LocationAnalysisFeaturedReports);

// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AppConfig from '../../appConfig';
import format from '../../helpers/NumberFormatter';
import LocationAnalysisItem from '../../objects/LocationAnalysisItem';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import Distance from '../distance/Distance';
import Time from '../distance/Time';
import SuggestEditFacilityButton from './SuggestEditFacilityButton';
import FacilityManagementTypes from '../../enums/FacilityManagementTypes';
import BlurOverlay from '../BlurOverlay';

/**
 * @typedef Props
 * @property {import('../../').PopupFields} popupFields
 * @property {import('@turf/helpers').Feature[]} features
 * @property {() => void} onSelect
 *
 * @param {Props} props
 */
class InfoBubblePointFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPoiIndex: 0,
    };
  }

  render() {
    const {
      features,
      popupFields,
      onOpenPointInfo,
      locationAnalysisItem,
      suggestEditFormClickBack,
      isContentBlurred
    } = this.props;
    const feature = features[this.state.currentPoiIndex];
    const featureType = feature.properties[popupFields.typeProperty];
    const featureTypePopupFields = popupFields.sameForAllPointTypes
      ? popupFields.propertiesForPointType.default
      : popupFields.propertiesForPointType[featureType];
    const pointType = feature.properties.category;
    const managementType = feature.properties.management_type;
    const iconMap = {
      telephone: {icon: 'phone'},
      website: {icon: 'language'},
      point_distance: {icon: 'pin_drop'},
      has_cc_units: {icon: 'ac_unit', label: 'Climate Controlled'},
      rent_sqft_driveup: {icon: 'airport_shuttle', label: 'Drive-up'},
    };

    const showBlurOverlay = isContentBlurred && pointType === 'construction';

    let pointTag;

    switch (managementType) {
      case FacilityManagementTypes.reit:
          pointTag = 'REIT';
          break;
      case FacilityManagementTypes.sophisticated:
          pointTag = 'SOPHISTICATED OPERATOR';
          break;
      case FacilityManagementTypes.nonDesignated:
          pointTag = 'NON-DESIGNATED';
          break;
      default:
          pointTag = pointType === 'construction' ? 'CONSTRUCTION SITE' : 'NON-DESIGNATED';
    }

    const title = featureTypePopupFields
      .filter(field => field.isTitle)
      .map(field => <div key={field.property}>{feature.properties[field.property]}</div>);

    const getUrlDomain = website => {
      try {
        return new URL(website.toString()).hostname;
      } catch (e) {
        console.error(`Website URL ${website} is malformed.`);
        return null;
      }
    };

    const editFormPayload = {
      category: 'Storage Facility',
      location: feature.properties.full_address,
      name: feature.properties.name,
      telephone: feature.properties.telephone,
      website: feature.properties.website,
    };

    const openInGoogleMaps = (
      <div className="open-in-google-maps__geojson">
        <a
          className="google-map-link md-18"
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.google.com/maps/search/?api=1&query=${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`}
        >
          {feature.properties.full_address}
        </a>
      </div>
    );

    const openMoreInfo = () => {
      const facilityType =
        feature.properties.category.toString() === 'construction'
          ? 'ss-construction'
          : 'ss-facility';
      const newLocationAnalysisItem = new LocationAnalysisItem({
        type: facilityType,
        id: feature.properties.facility_id,
        value: feature.properties.name,
        point: { lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1] },
        itemOrigin: LocationAnalysisItemOrigin.LIBRARY_LAYER_FEATURE,
        analysisTypeId: AppConfig.constants.defaultLocationAnalysisSelectionType,
        selection: new Set(AppConfig.constants.defaultLocationAnalysisSelection),
      });
      
      const visualReportProps = {
        report: 'selfstorage-point-info',
        pointType: facilityType,
        facilityId: feature.properties.facility_id,
      };
      onOpenPointInfo(newLocationAnalysisItem, visualReportProps);
    };

    let distanceFromPin;
    if (locationAnalysisItem) {
      const lng = feature.geometry.coordinates[0];
      const lat = feature.geometry.coordinates[1];

      const showTime = locationAnalysisItem.isComplete && locationAnalysisItem.isIsochrone;

      distanceFromPin = (
        <div className="self-storage-info-bubble">
            <div className="flex-it center justify-center" style={{ gap: '12px' }}>
                <div className="tag">
                    <Distance startPoint={locationAnalysisItem.point} endPoint={{lng, lat}}/>
                </div>
                {showTime && <span className="geojson-point-popup__divider"/>}
                {showTime && (
                    <div className="flex-it center justify-center" style={{ gap: '8px' }}>
                        <i className="material-icons md-18 opacity-054 "> directions_car </i>
                        <div className="tag">
                            <Time
                                startPoint={locationAnalysisItem.point}
                                endPoint={{lng, lat}}
                                showIcon={false}
                                mapboxRoutingProfile={locationAnalysisItem.mapboxRoutingProfile}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
      );
    }
    const updateFieldsToIncludePointDistance = (featureTypePopupFields) => {
      const distanceFromPinField = {
        property: 'point_distance',
        label: 'Distance from Pin'
      };
      // Render website after Distance from Pin field
      const websiteIndex = featureTypePopupFields.findIndex(field => field.property === 'website');
      return [
        ...featureTypePopupFields.slice(0, websiteIndex + 1),
        distanceFromPinField,
        ...featureTypePopupFields.slice(websiteIndex + 1),
      ];
    }

    const updatedFeatureTypePopupFields = updateFieldsToIncludePointDistance(featureTypePopupFields);
    const basicInfoRows = ['telephone', 'website', 'point_distance'];
    const blueIconInfoRows = ['has_cc_units', 'rent_sqft_driveup'];
    const rows = updatedFeatureTypePopupFields
        .filter(field => basicInfoRows.includes(field.property))
        .filter(
            field => !field.isTitle &&
                (field.property === 'point_distance' || (feature.properties[field.property] !== null &&
                    feature.properties[field.property] !== 'null'))
        )
        .filter(field => field.property === 'point_distance' || feature.properties[field.property] != null)
        .map(field => {
            let value = field.property === 'point_distance' ? distanceFromPin : feature.properties[field.property];
            if (!field.isWebsite && field.format) {
                value = format({ number: value, numberFormat: field.format });
            }
            const isFacilityWebsiteVisible = field.isWebsite && feature.properties[field.property];
            const propertyIcon = iconMap[field.property];
            return (
                value && (
                    <li
                        key={field.property}
                        className={classNames('flex-it center flex-start data-row no-vertical-padding')}
                    >
                        <div className="data-row__label popup">
                            {propertyIcon && (
                                <div className="flex-it center">
                                    {propertyIcon.icon && (
                                        <i
                                            className={`align-middle material-icons md-18 opacity-054 
                                                ${blueIconInfoRows.includes(field.property)
                                                    ? "primary-color"
                                                    : ""}
                                                ${field.property === 'point_distance' 
                                                    ? "material-icons-outlined" 
                                                    : "material-icons"}    
                                            `}
                                        >
                                            {propertyIcon.icon}
                                        </i>
                                    )}
                                    {propertyIcon.label && <span>{propertyIcon.label}</span>}
                                </div>
                            )}
                        </div>
                        <div className="data-row__value ellipsis">
                            {isFacilityWebsiteVisible ? (
                                <a
                                    href={feature.properties[field.property]}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="data-row__link"
                                >
                                    {getUrlDomain(feature.properties[field.property])}
                                </a>
                            ) : (
                                <span className="data-row__value__light">{value}</span>
                            )}
                        </div>
                    </li>
                )
        );
        });

    const visiblePropertyIcons = ['has_cc_units', 'rent_sqft_driveup'];
    const blueIconRows = updatedFeatureTypePopupFields
        .filter(field => visiblePropertyIcons.includes(field.property))
        .filter(({ property, isTitle }) =>
            visiblePropertyIcons.includes(property) &&
            !isTitle &&
            feature.properties[property] &&
            feature.properties[property] !== 'null'
        )
        .sort((a, b) => {
          return visiblePropertyIcons.indexOf(a.property) - visiblePropertyIcons.indexOf(b.property);
        })
        .map((field) => {
          const propertyIcon = iconMap[field.property];

          return (
              <div>
                  {propertyIcon && (
                      <div className="flex-it center" style={{gap: '8px'}}>
                          {propertyIcon.icon && (
                              <i className="align-middle material-icons md-18 opacity-054 primary-color">
                                  {propertyIcon.icon}
                              </i>
                          )}
                          {propertyIcon.label && <h6>{propertyIcon.label}</h6>}
                      </div>
                  )}
              </div>
          );
    });

    const calculatePercentageOf = (part, total) => {
        if (total === 0) {
            return 0;
        }
        // Returns a string rounded to 2 decimals
        return ((part / total) * 100).toFixed(2).concat('%');
    };

    const bottomPropertiesToShow = ['acres', 'rent_sqft', 'rent_sqft_driveup'];
    const bottomLegendRows = updatedFeatureTypePopupFields
        .filter(field => bottomPropertiesToShow.includes(field.property))
        .filter(
            field => !field.isTitle &&
            feature.properties[field.property] !== null &&
            feature.properties[field.property] !== 'null'
        )
        .filter(field => feature.properties[field.property] != null)
        .map((field) => {
            let value = feature.properties[field.property];
            let label = field.property === 'rent_sqft' ? 'Total rentable Sq. Ft.' : field.label;
            if (!field.isWebsite && field.format) {
                if (field.property === 'rent_sqft_driveup') {
                    value = calculatePercentageOf(value, feature.properties['rent_sqft']);
                    label = 'Drive-up'
                }
                else {
                    value = format({ number: value, numberFormat: field.format });
                }
            }
            return (
                value && (
                    <div className="flex-it" style={{gap: '4px'}}>
                        <h6>{value}</h6>
                        <span className="data-row__value__light no-wrap"> {label} </span>
                    </div>
                )
            );
        });

    const AdditionalInfoRows = ({ blueIconRows, bottomLegendRows }) => {
        const allRows = [...blueIconRows, ...bottomLegendRows];
        return (
            <ul className="geojson-point-popup__stats-row">
                {allRows.map((row, index) => (
                    <li key={`stats-row-${index}`} className="geojson-point-popup__row-value">
                        {row}
                        {index < allRows.length - 1 && (index % 2 === 0) && <span className="geojson-point-popup__divider"/>}
                    </li>
                ))}
          </ul>
          );
    };

    return (
        <div className="geojson-point-popup geojson-point-popup__ss_facility">
            <div className="geojson-point-popup__header">
                    <span className="geojson-point-popup__title">SELF STORAGE</span>
                    <span className="geojson-point-popup__tag">{pointTag}</span>
            </div>
            <div className="position-relative">
                { showBlurOverlay && <BlurOverlay /> }  
                <div className="geojson-point-popup__subheader">
                    <div className="geojson-point-popup__suggest-edit align-top">
                        <h5 className="geojson-point-popup__ss-facility-title">{title}</h5>
                        <SuggestEditFacilityButton
                            editFormPayload={editFormPayload}
                            clickBack={suggestEditFormClickBack}
                        />
                    </div>
                    {openInGoogleMaps}
                </div>
                {rows.length > 0 && (<ul className="geojson-point-popup__row"> {rows} </ul>)}
                <AdditionalInfoRows
                    blueIconRows={blueIconRows}
                    bottomLegendRows={bottomLegendRows}
                />

                <div
                    className={classNames(
                        'flex-it center geojson-point-popup__footer',
                        this.props.features.length > 1 ? 'space-between nav' : 'justify-content-start'
                    )}
                >
                    <button onClick={openMoreInfo} className="btn btn-details">
                        View more details
                    </button>

                    {this.props.features.length > 1 && (
                        <div className="flex-it center space-between geojson-point-popup__poi">
                            <div className="current-item">
                                {this.state.currentPoiIndex + 1}/{this.props.features.length}
                            </div>
                            <div className="flex-it center">
                                <button
                                    disabled={this.state.currentPoiIndex === 0}
                                    className="btn btn-pagination"
                                    onClick={() =>
                                        this.setState(prevState => ({currentPoiIndex: prevState.currentPoiIndex - 1}))
                                    }
                                >
                                    <i className="material-icons">keyboard_arrow_left</i>
                                </button>
                                <button
                                    disabled={this.state.currentPoiIndex === this.props.features.length - 1}
                                    className="btn btn-pagination"
                                    onClick={() =>
                                        this.setState(prevState => ({currentPoiIndex: prevState.currentPoiIndex + 1}))
                                    }
                                >
                                    <i className="material-icons">keyboard_arrow_right</i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
      );
  }
}

InfoBubblePointFeature.propTypes = {
  popupFields: PropTypes.object.isRequired,
  features: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  onOpenPointInfo: PropTypes.func,
  suggestEditFormClickBack: PropTypes.func,
  locationAnalysisItem: PropTypes.object,
  isContentBlurred: PropTypes.bool,
};

InfoBubblePointFeature.defaultProps = {
  onSelect: undefined,
  onOpenPointInfo: undefined,
  suggestEditFormClickBack: undefined,
  locationAnalysisItem: undefined,
  isContentBlurred: false,
};

export default InfoBubblePointFeature;

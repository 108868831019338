import SubscriptionProducts from '../enums/SubscriptionProducts';
import UserRole from '../enums/UserRole';

class UserInfo {
    get userId() {
        return this._userId;
    }

    set userId(userId) {
        this._userId = userId;
    }

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get product() {
        return this._product;
    }

    set product(product) {
        this._product = product;
    }


    get isProUser() {
        return true; // this.hasRole(UserRole.PRODUCT_PRO);
    }

    get isLogged() {
        return this.userId !== -1;
    }

    get licenseInfo() {
        return this._licenseInfo;
    }

    set licenseInfo(licenseInfo) {
        this._licenseInfo = licenseInfo;
    }

    get activeSubscriptions() {
        return this._activeSubscriptions;
    }

    set activeSubscriptions(activeSubscriptions) {
        this._activeSubscriptions = activeSubscriptions;
    }

    /**
     * Get JSON representation of the userInfo
     * @returns {object} JSON representation of the userInfo
     */
    toJSON() {
        return {
            userId: this.userId,
            name: this.name,
            product: this.product,
            licenseInfo: this.licenseInfo,
        };
    }

    hasRole(role) {
        return this.product.toLowerCase() === role.toLowerCase();
    }

    hasAnyRole(roles) {
        // allow all to students for now
        const userInfo = this;
        if (userInfo.hasRole(UserRole.PRODUCT_STUDENT)) return true;
        // if it is PRO user and array of roler is empty return true
        if (userInfo.hasRole(UserRole.PRODUCT_PRO) && roles.length === 0) return true;
        return roles.some(roleName => userInfo.hasRole(roleName));
    }

    isPro() {
        return this.activeSubscriptionsContainProduct(SubscriptionProducts.ApplicationAccess.TRACTIQ_SELF_STORAGE);
    }
    
    isStarter() {
        return (
            !this.isPro() &&
            this.activeSubscriptionsContainProduct(SubscriptionProducts.ApplicationAccess.TRACTIQ_SELF_STORAGE_STARTER_TIER)
        );
    }

    getUserProduct() {
        return this.isPro() ? SubscriptionProducts.ProductTypes.PROFESSIONAL_PRODUCT :
            this.isStarter() ? SubscriptionProducts.ProductTypes.STARTER_PRODUCT : SubscriptionProducts.ProductTypes.OTHER;
    }
    
    activeSubscriptionsContainProduct(product) {
        return this.activeSubscriptions.some((subscription) => {
            return subscription.products
                .map((product) => product.productId)
                .includes(product);
        });
    }

    hasFeatureToggle = (featureId) => {
        return this.activeSubscriptions.some((subscription) =>
            subscription.products.some((product) =>
                product.configuration.features && product.configuration.features.includes(featureId),
            ),
        );
    };
}

export default UserInfo;

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import BusComponent from '../BusComponent';
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props>}
 */
class UserLocationSeeAllButton extends BusComponent {
    constructor(props, context) {
        super(props, context);
    }

    onClick = () => {
        window.open(AppConfig.constants.links.visualReportsLink);
    };

    render() {
        const { intl } = this.props;
        return (
            <div className="user-locations-list__see-all">
                <button onClick={this.onClick} className="btn-raised btn-rounded">
                    {intl.formatMessage({ id: 'seeAll' })}
                </button>
            </div>
        );
    }
}

UserLocationSeeAllButton.propTypes = {
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserLocationSeeAllButton);

import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

import LocationAnalysisItem from '../objects/LocationAnalysisItem';
import UserMapView from '../objects/UserMapView';
import InitialView from '../objects/InitialView';

class UserSettingsDataSource extends GLU.DataSource {
    static get name() {
        return 'UserSettingsDataSource';
    }

    static getInstance() {
        return new UserSettingsDataSource();
    }

    constructor() {
        super(() => false);
        this._userMapViews = [];
        /** @type {LocationAnalysisItem[]} */
        this._userLocations = [];
    }

    get userMapViews() {
        return this._userMapViews;
    }

    /** @returns {LocationAnalysisItem[]} */
    get userLocations() {
        return this._userLocations;
    }

    loadUserMapViews() {
        return new Promise((resolve, reject) => {
            Api.userMapView.getUserMapViews().then(response => {
                if (response.body) {
                    this._userMapViews = response.body.map(mapView => {
                        const userMapView = new UserMapView({
                            id: mapView.id,
                            title: mapView.title,
                            mapView: new InitialView(mapView),
                        });

                        return userMapView;
                    });
                }
                resolve(this._userMapViews);
            }, reject);
        });
    }

    saveUserMapView(payload) {
        return new Promise(resolve => {
            Api.userMapView.insertUserMapView({ payload }).then(response => {
                // Add item to data source
                resolve(response.body);
            });
        });
    }

    updateUserMapView(payload) {
        return new Promise((resolve, reject) => {
            Api.userMapView.updateUserMapView({ payload }).then(response => {
                resolve(response.body);
            }, reject);
        });
    }

    removeUserMapView(payload) {
        return new Promise((resolve, reject) => {
            Api.userMapView.deleteUserMapView({ payload }).then(response => {
                resolve(response.body);
            }, reject);
        });
    }
    
}

export default UserSettingsDataSource;

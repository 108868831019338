// @ts-check
import PointsFilter from '../../objects/pointsFilter/PointsFilter';
import PointsFilterRule from '../../objects/pointsFilter/PointsFilterRule';
import BaseHandler from './BaseHandler';

class PointsHandler extends BaseHandler {
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData.geoJsonLayersData;
        this._isMapLoaded = this.map.loaded();

        this.setGluBusEvents({
            POINTS_GEOJSON: this.onPointsGeoJsonLoaded,
            SELECT_POINT_FEATURE: this.onSelectPointFeature,
            MAP_APPLY_GEO_JSON_LIBRARY_LAYERS_UPDATE: this.applyGeoJSONLibraryLayersUpdate,
            MAP_APPLY_GEO_JSON_CLUSTERING_TOGGLE: this.applyGeoJSONClusteringToggle,
            MAP_APPLY_GEO_JSON_LAYER_UPDATE: this.applyGeoJsonLayerUpdate,
            SHOULD_USE_CLUSTERS_REQUEST: this.onShouldUseClustersRequest,
            TOGGLE_CLUSTERING: this.onApplyClusteringFromFilterPanel,
            APPLIED_FILTERS_RESPONSE: this.onApplyFilters,
        });

        /** @type {import('mapbox-gl').Popup} */
        this._popup = undefined;
        this._layerVisibility = undefined;
        this._featurePropertyNameEquivalentToLayerId = undefined;
        this._filterCombinerType = undefined;
        this._appliedFilters = undefined;
        this._shouldUseClusters = false;
        this.bus.once('GEO_JSON_METADATA', this.onGeoJsonMetadataRetrieved);
        this.emit('GEO_JSON_METADATA_REQUEST', { source: this });
    }

    /**
     * @param {import('../../').PointsMetadata} geoJsonMetadata
     * @param target
     */
    onGeoJsonMetadataRetrieved = (geoJsonMetadata, target) => {
        if (target === this && geoJsonMetadata) {
            this._data.setGeoJsonLayersData(geoJsonMetadata);
        }
    };

    onApplyFilters = (eventMap) => {
        if (eventMap.appliedGeoFilters) {
            this._appliedFilters = eventMap.appliedGeoFilters.appliedFilters;
            this._filterCombinerType = eventMap.appliedGeoFilters.filterCombinerType;
        }
        this.applyGeoJsonLayerUpdate(eventMap);
    }

    onPointsGeoJsonLoaded = geoJson => {
        this._data.setGeoJsonSourceData(geoJson);
        this.bus.emit("GET_APPLIED_FILTERS_REQUEST");
    };


    /** @param {import('@turf/helpers').Feature} feature  */
    onSelectPointFeature = feature => {
        // Fly to feature
        this.map.flyTo({
            center: feature.geometry.coordinates,
            zoom: 14,
            animate: false,
        });
    };

    // apply the visibility change to the map
    applyGeoJSONLibraryLayersUpdate(e) {
        const { visible } = e;
        this._data.layers.forEach(layer => this.map.removeLayer(layer.id));

        this._data.layers.forEach(layer => {
            layer.layout.visibility = visible ? 'visible' : 'none';
            this.map.addLayer(layer);
        });
    }

    onApplyClusteringFromFilterPanel = () => {
        this._shouldUseClusters = true;
        this.applyGeoJSONClusteringToggle({ useClusters: this._shouldUseClusters });
    }

    onShouldUseClustersRequest = () => {
        this.emit('SHOULD_USE_CLUSTERS_RESPONSE', { useClusters: this._shouldUseClusters });
    }

    // apply the visibility change to the map
    applyGeoJSONClusteringToggle(e) {
        const { useClusters } = e;
        this._shouldUseClusters = useClusters;
        this.map.removeSource(this._data.sourceId);
        this._data.source.cluster = useClusters;
        this.map.addSource(this._data.sourceId, this._data.source);
    }

    setLocalsFromGeoJsonLayerUpdateParams(e) {
        const { layerVisibility, featurePropertyNameEquivalentToLayerId, filterCombinerType, appliedFilters } = e;
        // check if there are facility filters from legend and save them
        if (e.hasOwnProperty('layerVisibility') && e.hasOwnProperty('featurePropertyNameEquivalentToLayerId')) {
            this._layerVisibility = layerVisibility;
            this._featurePropertyNameEquivalentToLayerId = featurePropertyNameEquivalentToLayerId;
        }
        // check if there are facility filters from filter and save them
        if (e.hasOwnProperty('filterCombinerType') && e.hasOwnProperty('appliedFilters')) {
            this._filterCombinerType = filterCombinerType;
            this._appliedFilters = appliedFilters;
        }
    }

    // apply the visibility change to the map
    // it is assumed that
    //  there exists a single GeoJSON file
    //  within it there is a specific property that is used to classify features into distinct groups & display them as such
    //  dingo-maps GeoJSON support metadata defines
    //      - that property
    //      - layers created virtually from features based on classification by that property
    //      - all discrete values in classification property are names of distinct virtualized layers
    // this method determines which features get populated into the map based on classifications used
    // clusters are styled based on the number of classes active
    //  if one class is active, its style is applied to clusters
    //  if multiple classes are active, default style is applied to clusters
    applyGeoJsonLayerUpdate(e) {
        this.setLocalsFromGeoJsonLayerUpdateParams(e);

        let areAllLayersHidden = false;
        // Remove layers and source
        this._data.layers.forEach(layer => {
            if (this.map.getLayer(layer.id)) {
                this.map.removeLayer(layer.id);
            }
        })
        if (this.map.getSource(this._data.sourceId)) {
            this.map.removeSource(this._data.sourceId);
        }
        const filteredSourceData = {
            type: 'FeatureCollection',
            features: [],
        };
        // Reset the cluster color
        this._data.resetClusterLayerColor();
        // if there are filters from legend, filter facilities based on those criteria
        if (this._layerVisibility && this._featurePropertyNameEquivalentToLayerId) {
            if (this._layerVisibility.every(el => el.visible)) {
                // all visible so use all the points
                this._data.resetData();
                areAllLayersHidden = false;
            } else if (this._layerVisibility.every(el => el.visible === false)) {
                areAllLayersHidden = true;
            }
            // Get the visible layer ids
            const visibleLayers = this._layerVisibility
                .filter(l => l.visible)
                .map(l => l.layerId);
            // Filter the features
            filteredSourceData.features = this._data.geoJsonSourceData.features
                .filter(f => visibleLayers.includes(f.properties[this._featurePropertyNameEquivalentToLayerId]));
            // If there is only one visible layer change the color of cluster circle
            if (visibleLayers.length === 1) {
                // change the color of cluster
                const visibleLayerId = visibleLayers[0]; // there is only one
                this._data.updateClusterLayerColor(visibleLayerId);
            }
        }
        const hasAppliedFilters = this._appliedFilters && Object.keys(this._appliedFilters).length > 0;
        let filteredFeatures = [];
        const geoJsonSourceData = this._data.geoJsonSourceData ? this._data.geoJsonSourceData.features : [];

        // if there are filters applied and all layers visible
        if (this._filterCombinerType && hasAppliedFilters && !areAllLayersHidden) {
            const pointsFilterRules = Object.keys(this._appliedFilters).map(
                appliedFilterProperty =>
                    new PointsFilterRule({
                        property: appliedFilterProperty.replace(/[@][0-9]+/g, ''),
                        value: this._appliedFilters[appliedFilterProperty].value,
                        filterType: this._appliedFilters[appliedFilterProperty].filterType,
                    }),
            );
            // Let's create the point filter object
            const pointsFilter = new PointsFilter({
                filterCombinerType: this._filterCombinerType,
                filterRules: pointsFilterRules,
            });
            // Filter the geo json file
            filteredFeatures = pointsFilter.filter(filteredSourceData.features.length > 0 ? filteredSourceData.features : geoJsonSourceData);
            // if there are no applied filters but there are facilities filtered by legend filter, then show only those facilities
        } else if (!hasAppliedFilters && !areAllLayersHidden) {
            filteredFeatures = filteredSourceData.features.length > 0 ? filteredSourceData.features : geoJsonSourceData;
        }
        // Set filtered source data
        filteredSourceData.features = filteredFeatures;
        this._data.setFilteredSourceData(filteredSourceData);

        // Change the data field in the source with the new source with filtered features
        // Add source and layers
        if (this.map.isStyleLoaded() ) {
            if (this.map.getSource(this._data.sourceId)) {
                this.map.getSource(this._data.sourceId).setData(filteredSourceData);
            } else {
                this.map.addSource(this._data.sourceId, this._data.source);
            }

            this._data.layers.forEach(layer => {
                if (!this.map.getLayer(layer.id)) {
                    this.map.addLayer(layer);
                }
            });
        }
    }
}

export default PointsHandler;

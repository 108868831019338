import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import BusComponent from '../../BusComponent';
import AppConfig from '../../../appConfig';

import AdditionalSettingsIconColors from '../../../enums/AdditionalSettingsIconColors';
import AdditionalSettingsItem from './AdditionalSettingsItem';

class FilterAreasByCriteriaItem extends BusComponent {
    onDataFilterClick = () => {
        const { isUserPro, mapInstanceId } = this.props;
        if (isUserPro) {
            this.emit('ENTER_DATA_FILTER_MODE', { mapInstanceId });
        } else {
            this.emit('LOGIN_REQUEST', {
                checkCount: 30,
                url: AppConfig.constants.links.licenses,
            });
        }
    };

    render() {
        const { dataFilterDisabled, isUserPro, intl } = this.props;

        let filterBadge;
        if (dataFilterDisabled) {
            filterBadge = (
                <div className="badge badge--disabled">
                    {intl.formatMessage({ id: dataFilterDisabled.label })}
                </div>
            );
        } else if (!isUserPro) {
            filterBadge = (<div className="badge badge--pro">
                {intl.formatMessage({ id: 'proFeature' })}
            </div>);
        }

        return (
            <AdditionalSettingsItem
                badge={filterBadge}
                icon={<i className="material-icons">tune</i>}
                disabled={!!dataFilterDisabled}
                onItemClick={this.onDataFilterClick}
                title={intl.formatMessage({ id: 'dataBrowser.filterAreasByCriteria' })}
                description={intl.formatMessage({ id: 'dataBrowser.identifyLocationsBasedOnSearchParametersYouSpecify' })}
                iconStyle={{ background: AdditionalSettingsIconColors.FILTER }}
            />
        );
    }
}

FilterAreasByCriteriaItem.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    dataFilterDisabled: PropTypes.object,
    isUserPro: PropTypes.bool,
    intl: PropTypes.object.isRequired,
};

FilterAreasByCriteriaItem.defaultProps = {
    isUserPro: false,
    dataFilterDisabled: undefined,
};

export default injectIntl(FilterAreasByCriteriaItem);

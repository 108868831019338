const SubscriptionProducts = {
    ApplicationAccess: {
        TRACTIQ_SELF_STORAGE: 'ACCESS__TRACTIQ_SELFSTORAGE',
        TRACTIQ_SELF_STORAGE_STARTER_TIER: 'ACCESS__TRACTIQ_SELFSTORAGE_STARTER_TIER',
    },

    ApplicationAccessConfigurationOptions: {
        ENABLE_RAW_DATA_EXPORT: 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_RAW_DATA_EXPORT',
        ENABLE_SITE_ANALYSIS_API: 'ACCESS__TRACTIQ_SELFSTORAGE__ENABLE_SITE_ANALYSIS_API',
        USES_BETA_MAPS_APP: 'ACCESS__TRACTIQ_SELFSTORAGE__USES_BETA_MAPS_APP',
    },

    DataPackages: {
        TRACTIQ_SELF_STORAGE_BASE: 'DATA_PACKAGE__TRACTIQ_SELFSTORAGE_BASE',
        PROPRIETARY_SINGLE_LOCATION_REPORT: 'DATA_PACKAGE__PROPRIETARY_SINGLE_LOCATION_REPORT',
    }
};

export default SubscriptionProducts;

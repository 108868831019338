// @ts-check
import React from 'react';

import BusComponent from '../BusComponent';

/**
 * @typedef Props
 * @property {import('@turf/helpers').Feature} feature
 * @property {string} mapInstanceId
 * @property {string[]} searchFields
 *
 * @extends {BusComponent<Props>}
 */
class PointSearchResultListItem extends BusComponent {
    onClick = () => {
        this.emit('FEATURE_TO_LOCATION_ANALYSIS', { feature: this.props.feature, mapInstanceId: this.props.mapInstanceId });
    };

    render() {
        const { searchFields, feature } = this.props;

        const title = searchFields
            .filter(searchField => searchField.isTitle)
            .map(searchField => feature.properties[searchField.property])
            .join('');

        const other = searchFields
            .filter(searchField => !searchField.isTitle)
            .map(searchField => feature.properties[searchField.property])
            .join(', ');

        const [first, second] = other.split(',');
        const tooltipText = `${title} -- ${first + ',' || ''} ${second || ''}`.trim();

        return (
            <li className="search-results__list-item" onClick={this.onClick}>
                <div
                    className="flex-it space-between center grow stretch-width"
                    title={tooltipText}
                >
                    <div className="flex-it stretch flex-item ellipsis">
                        <span className="main-content">{title}</span>
                        <span className="other-content">, {other}</span>
                    </div>
                </div>
            </li>
        );
    }
}

export default PointSearchResultListItem;

module.exports={
    "saveToPlaces": "Save to my places",
    "savedInMyPlaces": "Saved in My places",
    "viewLocationList": "View list",
    "savedPlacesTooltip": "Saved places",
    "myPlaces": "My places",
    "description": "Save the locations you use frequently and reuse them anytime you need. Click the 'Save as' button in the top right of the Analyze market window to add this location to your markets.",
    "note": "Note: We save only the center of selected geography as Place.",
    "selectLocation": "Click and add a pin to map",
    "defaultLocationName": "Custom pin",
    "savedMarkets": "Saved markets"
}

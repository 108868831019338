// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { hasParentNode, hasParentNodeWithClass } from '../../helpers/Util';
import BusComponent from '../BusComponent';
import UserLocationList from './UserLocationList';
import UserLocationListTrigger from './UserLocationListTrigger';
import UserLocationEmptyList from './UserLocationEmptyList';
import UserLocationSeeAllButton from './UserLocationSeeAllButton';

/**
 * @typedef Props
 * @property {string[]} mapInstanceIds
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} open
 * @property {import('../../types').VisualReport[]} visualReports
 *
 * @extends {BusComponent<Props, State>}
 */
class UserLocations extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            open: false,
            visualReports: [],
        };
        this.focusBackElement = undefined;
    }

    componentDidMount() {
        this.bindGluBusEvents({
            VISUAL_REPORTS: this.onListUpdate,
            VISUAL_REPORT_SAVE_SUCCESS: this.onListUpdate,
            VISUAL_REPORT_DELETE_SUCCESS: this.onListUpdate,
            LOCATION_ANALYSIS_MODE_STARTED: this.onLocationAnalysis,
            LOCATION_ANALYSIS_ITEM_UPDATED: this.onLocationAnalysis,
            CUSTOM_LOCATION_REQUEST: this.onClose,
            ENTER_UPDATE_LOCATION_ANALYSIS_MODE: this.onClose,
        });
        this.emit('VISUAL_REPORTS_REQUEST');
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onMouseEvent);
        this.unbindGluBusEvents();
    }

    onMouseEvent = e => {
        if (hasParentNodeWithClass(e.target, 'view-user-location-list')) {
            return;
        }
        if (hasParentNode(e.target, this.list)) {
            return;
        }
        this.onToggle();
    };

    onLocationAnalysis = () => {
        if (this.state.open) {
            this.onToggle();
        }
    };


    /**
     * @param {object} param0
     * @param {import('../../types').VisualReport[]} param0.visualReports
     */
    onListUpdate = ({ visualReports }) => {
        this.setState({ visualReports });
    };

    focusAndClear = () => {
        if (this.focusBackElement) {
            this.focusBackElement.focus();
        }
    }

    onClose = () => {
        this.setState({ open: false }, this.focusAndClear);
        window.removeEventListener('click', this.onMouseEvent);
    }

    onToggle = event => {
        if (event && event.target) {
            event.persist();
        }
        this.setState(
            ({ open }) => ({ open: !open }),
            () => {
                if (this.state.open) {
                    window.addEventListener('click', this.onMouseEvent);
                    if (event && event.target) {
                        this.focusBackElement = event.target;
                        this.emit('SET_ON_MODAL_CLOSE_FOCUS_ELEMENT', this.focusBackElement);
                    }
                } else {
                    window.removeEventListener('click', this.onMouseEvent);
                    this.focusBackElement = undefined;
                }
            },
        );
    };

    render() {
        const { visualReports } = this.state;

        const content = visualReports && visualReports.length ? (
            <div>
                <UserLocationList
                    visualReports={visualReports.slice(0, 10)}
                    mapInstanceIds={this.props.mapInstanceIds}
                    onClose={this.onClose}
                />
                {visualReports.length > 10 && <UserLocationSeeAllButton />}
            </div>
        ) : (
            <UserLocationEmptyList onClose={this.onClose} />
        );

        return (
            <div
                className="user-locations"
                ref={c => {
                    this.list = c;
                }}
            >
                <UserLocationListTrigger
                    onClick={this.onToggle}
                    open={this.state.open}
                    mapInstanceIds={this.props.mapInstanceIds}
                />
                {this.state.open && <div className="user-locations-list">{content}</div>}
            </div>
        );
    }
}

UserLocations.propTypes = {
    mapInstanceIds: PropTypes.array.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserLocations);

import GLU from '../glu2.js/src/index';

class SessionApi extends GLU.Api {
    constructor(endpoint) {
        super(endpoint);

        this.createApiActions(
            {
                name: 'logOut',
                method: GLU.Api.Delete,
                path: '/session',
                credentials: true,
            },
            {
                name: 'sessionInfo',
                method: GLU.Api.Get,
                path: '/session-info',
                credentials: true,
            },
        );
    }
}

export default SessionApi;

// @ts-check
import React from 'react';
import BaseController from './BaseController';

import FeedbackPopup from '../components/FeedbackPopup';
import SharePopup from '../components/share/SharePopup';
import SharePopupEmbed from '../components/share/SharePopupEmbed';
import DataClassificationPopup from '../components/dataClassification/DataClassificationPopup';
import SaveAsProjectPopup from '../components/editProject/SaveAsProjectPopup';
import EditProjectInfoPopup from '../components/editProject/EditProjectInfoPopup';
import EditProjectErrorPopup from '../components/editProject/EditProjectErrorPopup';
import SubmitFacilityPopup from '../components/submitFacility/SubmitFacilityPopup';
import ReportGeosLimitPopup from '../components/reportEditor/ReportGeosLimitPopup';
import LayerLibraryEditor from '../components/layerLibrary/list/LayerLibraryEditor';
import LayerLibraryGroupInfo from '../components/layerLibrary/list/LayerLibraryGroupInfo';
import UserMapViewEditPopup from '../components/mapControls/mapViews/UserMapViewEditPopup';
import ColorPaletteEditor from '../components/colorPaletteEditor/ColorPaletteEditor';
import DeleteUserLayer from '../components/userDataUploadEditor/DeleteUserLayer';
import AddCustomAnalysisValuePopup from '../components/locationPanel/AddCustomAnalysisValuePopup';
import CustomLocationAnalysisValueLimitPopup from '../components/locationPanel/CustomLocationAnalysisValueLimitPopup';
import AutoAdjustFailedPopup from '../components/visualizationLegend/autoAdjust/AutoAdjustFailedPopup';
import VisualReportPopup from '../components/locationPanel/VisualReportPopup';
import SingleMapSwitchWarningPopup from '../components/locationPanel/SingleMapSwitchWarningPopup';
import FrameTypeSwitchWarning from '../components/mapFrame/FrameTypeSwitchWarning';

import FeedbackDataSource from '../dataSources/FeedbackDataSource';
import UserInfoDataSource from '../dataSources/UserInfoDataSource';
import ProjectDataSource from '../dataSources/ProjectDataSource';
import MailHookDataSource from '../dataSources/MailHookDataSource';

import Errors from '../enums/Error';
import UserMapViewPopupMode from '../enums/UserMapViewPopupMode';

class PopupController extends BaseController {
    static get name() {
        return 'PopupController';
    }

    static getInstance(options) {
        return new PopupController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            COLOR_PALETTE_POPUP_REQUEST: this.onColorPalettePopupRequest,
            FEEDBACK_POPUP_REQUEST: this.onFeedbackPopupRequest,
            SHARE_POPUP_REQUEST: this.onSharePopupRequest,
            SHARE_EMBED_POPUP_REQUEST: this.onShareEmbedPopupRequest,
            SEND_FEEDBACK_REQUEST: this.onFeedbackSendRequest,
            DATA_CLASSIFICATION_POPUP_REQUEST: this
                .onDataClassificationPopupRequest,
            SAVE_AS_PROJECT_POPUP_REQUEST: this.onSaveAsProjectPopupRequest,
            SAVE_PROJECT_ERROR_POPUP_REQUEST: this
                .onSaveProjectErrorPopupRequest,
            EDIT_PROJECT_INFO_POPUP_REQUEST: this.onEditProjectInfoPopupRequest,
            SUBMIT_MISSING_FACILITY_POPUP_REQUEST: this.onSubmitMissingFacilityPopupRequest,
            SUBMIT_EDITED_FACILITY_POPUP_REQUEST: this.onSubmitEditedFacilityPopupRequest,
            REPORT_GEOS_LIMIT_POPUP_REQUEST: this.onReportGeosLimitPopupRequest,
            DELETE_USER_LAYER_POPUP_REQUEST: this.onDeleteUserLayerPopupRequest,
            ADD_LAYER_LIBRARY_LAYERS_POPUP_REQUEST: this
                .onAddLayersPopupRequest,
            SHOW_LAYER_LIBRARY_GROUP_DETAILS_POPUP_REQUEST: this
                .onShowLayerLibraryGroupDetailsRequest,
            ADD_USER_MAP_VIEW_POPUP_REQUEST: this.onAddUserMapViewPopupRequest,
            EDIT_USER_MAP_VIEW_POPUP_REQUEST: this
                .onEditUserMapViewPopupRequest,
            UPDATE_VISUAL_REPORT_POPUP_REQUEST: this
                .onUpdateVisualReportPopupRequest,
            ADD_LOCATION_ANALYSIS_VALUE_POPUP_REQUEST: this
                .onAddCustomAnalysisValuePopupRequest,
            CUSTOM_ANALYSIS_LIMIT_POPUP_REQUEST: this
                .onCustomValueLimitExceededPopupRequest,
            AUTO_ADJUST_FAILED_POPUP_REQUEST: this
                .onAutoAdjustFailedPopupRequest,
            SUBMIT_FACILITY_DATA_CHANGE_REQUEST: this
                .onFacilityDataChangeRequest,
            VISUAL_REPORT_SAVE_POPUP_REQUEST: this.onVisualReportSavePopupRequest,
            SINGLE_MAP_SWITCH_WARNING_REQUEST: this.onSingleMapSwitchWarningRequest,
            ANALYSIS_REMOVAL_WARNING_REQUEST: this.onAnalysisRemovalWarningRequest,
        });
        this.projectDataSource = this.activateSource(ProjectDataSource);
        this.feedbackDataSource = this.activateSource(FeedbackDataSource);
        this.userInfoDataSource = this.activateSource(UserInfoDataSource);
        this.mailHookDataSource = this.activateSource(MailHookDataSource);
    }

    onAutoAdjustFailedPopupRequest() {
        this.bus.emit('OPEN_MODAL', {
            modalContent: React.createElement(
                AutoAdjustFailedPopup
            ),
            name: 'Auto adjust failed',
            title: 'modals.autoAdjustFailed',
            modalBoxClass: 'modal-box--auto-adjust-failed',
            modalHeaderClasses: 'modal-header--auto-adjust-failed',
            canClickAway: true,
        });
    }

    onCustomValueLimitExceededPopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            modalContent: React.createElement(
                CustomLocationAnalysisValueLimitPopup,
                {
                    geographiesLimit: request.geographiesLimit,
                },
            ),
            name: 'Custom value limit exceeded warning',
            title: 'modals.customValueLimitTitle',
            modalBoxClass: 'modal-box--custom-value-limit-warning',
            modalHeaderClasses: 'modal-header--custom-value-limit-warning',
            canClickAway: true,
        });
    }

    onAddCustomAnalysisValuePopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            modalContent: React.createElement(AddCustomAnalysisValuePopup, {
                analysisType: request.analysisType,
                existingValues: request.values,
            }),
            name: 'Add new location analysis value',
            title: 'modals.addLocationAnalysisValue',
            modalBoxClass: 'modal-box--add-location-analysis-value',
            canClickAway: true,
        });
    }

    onReportGeosLimitPopupRequest(r) {
        this.bus.emit('OPEN_MODAL', {
            modalContent: React.createElement(ReportGeosLimitPopup, {
                maxLimit: r.maxLimit,
                ignoredGeos: r.ignoredGeos,
            }),
            name: 'Report geo limit warning',
            title: 'modals.aLotOfGeographiesSelected',
            modalBoxClass: 'modal-box--report-geos-limit',
            canClickAway: true,
        });
    }

    onEditProjectInfoPopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.changeProjectTitleAndDescription',
            modalBoxClass: 'edit-project-info',
            name: 'Edit project info',
            canClickAway: true,
            modalContent: React.createElement(EditProjectInfoPopup, {
                mapInstances: this.projectDataSource.currentProject.frames[0]
                    .mapInstances,
                projectTitle: request.projectTitle,
            }),
        });
    }

    onSubmitMissingFacilityPopupRequest() {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.submitFacility.reportMissing',
            modalBoxClass: 'submit-facility',
            name: 'Report a missing facility',
            canClickAway: false,
            modalContent: React.createElement(SubmitFacilityPopup, {
                type: 'selfstorage_missing_facility_report',
                editMode: false,
            }),
        });
    }

    onSubmitEditedFacilityPopupRequest(payload) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.submitFacility.suggestEdits',
            modalBoxClass: 'submit-facility',
            name: 'Suggest edit facility',
            canClickAway: false,
            modalContent: React.createElement(SubmitFacilityPopup, {
                ...payload,
                existingFacility: payload,
                type: 'selfstorage_edit_suggestion',
                editMode: true,
            }),
        });
    }

    onSaveProjectErrorPopupRequest(r) {
        this.bus.emit('OPEN_MODAL', {
            modalBoxClass: 'modal-box--edit-project-error',
            canClickAway: true,
            name: 'Save Project Error',
            modalContent: React.createElement(EditProjectErrorPopup, {
                mapInstances: this.projectDataSource.currentProject.frames[0]
                    .mapInstances,
                error: r.error,
            }),
        });
    }

    onSaveAsProjectPopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.saveAs',
            name: 'Save project as',
            modalBoxClass: 'modal-box--save-as-project',
            canClickAway: true,
            modalContent: React.createElement(SaveAsProjectPopup, {
                mapInstances: this.projectDataSource.currentProject.frames[0]
                    .mapInstances,
                projectTitle: request.projectTitle,
                saveAsNew: request.saveAsNew,
            }),
        });
    }

    onShareEmbedPopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.share',
            name: 'Share Embed',
            modalBoxClass: 'modal-box--share-embed',
            modalContent: React.createElement(SharePopupEmbed, {
                project: this.projectDataSource.currentProject,
                snapshot: request.snapshot,
            }),
        });
    }

    onDeleteUserLayerPopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.deleteUserLayer',
            name: 'Share',
            modalBoxClass: 'modal-box--small',
            modalContent: React.createElement(DeleteUserLayer, {
                userData: request.userData,
            }),
        });
    }

    onSharePopupRequest(request) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.shareWithOthers',
            name: 'Share',
            modalBoxClass: 'modal-box--share',
            modalContent: React.createElement(SharePopup, {
                showLoader: request.showLoader,
                project: this.projectDataSource.currentProject,
                currentUser: this.userInfoDataSource.currentUser,
                snapshot: request.snapshot,
                hasUnsavedChanges: request.hasUnsavedChanges,
            }),
        });
    }

    onColorPalettePopupRequest(content) {
        /** @type {import('../').ModalParams} */
        const payload = {
            modalHeaderClasses: 'modal-header--no-header',
            modalBoxClass: 'modal-box--palette-editor',
            canClickAway: false,
            name: 'Color Palette',
            modalContent: React.createElement(ColorPaletteEditor, {
                colorPalette: content.colorPalette,
                colorPalettesList: content.colorPalettesList,
                mapInstanceId: content.mapInstanceId,
                editMode: content.editMode,
            }),
        };
        this.bus.emit('OPEN_MODAL', payload);
    }

    onAddLayersPopupRequest(content) {
        /** @type {import('../../../').ModalParams} */
        const payload = {
            modalClasses: 'modal--fullscreen',
            modalBoxClass: 'modal-box--fullscreen-modal',
            canClickAway: false,
            name: 'Layer library',
            modalContent: React.createElement(LayerLibraryEditor, {
                mapInstanceId: content.mapInstanceId,
                mapInstance: content.mapInstance,
            }),
        };
        this.bus.emit('OPEN_MODAL', payload);
    }

    onShowLayerLibraryGroupDetailsRequest(metadata) {
        /** @type {import('../../../').ModalParams} */
        const payload = {
            modalHeaderClasses: 'modal-header',
            modalBoxClass: 'modal-box--library-group-details',
            canClickAway: true,
            titleOverride: metadata.title,
            name: metadata.title,
            modalContent: React.createElement(LayerLibraryGroupInfo, {
                className: 'modal-display',
                metadata,
            }),
        };
        this.bus.emit('OPEN_MODAL', payload);
    }

    onAddUserMapViewPopupRequest(payload) {
        const mapInstance = this.projectDataSource.getActiveMapInstance(
            payload.mapInstanceId,
        );
        const currentMapView = mapInstance.initialView;

        /** @type {import('../../../').ModalParams} */
        const popupPayload = {
            modalBoxClass: 'modal-box--user-map-view',
            canClickAway: true,
            title: 'modals.newMapPosition',
            name: 'New map position',
            customFocusElement: true,
            modalContent: React.createElement(UserMapViewEditPopup, {
                mapInstanceId: payload.mapInstanceId,
                title: 'New map position',
                latitude: parseFloat(currentMapView.centerLat).toFixed(5),
                longitude: parseFloat(currentMapView.centerLng).toFixed(5),
                zoom: parseFloat(currentMapView.zoom).toFixed(1),
                mode: UserMapViewPopupMode.CREATE,
            }),
        };
        this.bus.emit('OPEN_MODAL', popupPayload);
    }

    onEditUserMapViewPopupRequest(payload) {
        const {
            item: { id, title, mapView },
            mapInstanceId,
        } = payload;

        /** @type {import('../../../').ModalParams} */
        const popupPayload = {
            modalBoxClass: 'modal-box--user-map-view',
            canClickAway: true,
            title: 'modals.editMapPosition',
            name: 'Edit map position',
            customFocusElement: true,
            modalContent: React.createElement(UserMapViewEditPopup, {
                mapViewId: id,
                mapInstanceId,
                title,
                latitude: parseFloat(mapView.centerLat).toFixed(5),
                longitude: parseFloat(mapView.centerLng).toFixed(5),
                zoom: parseFloat(mapView.zoom).toFixed(1),
                mode: UserMapViewPopupMode.EDIT,
            }),
        };
        this.bus.emit('OPEN_MODAL', popupPayload);
    }

    /**
     * Event handler for SelfStorage location data update
     * @param payload
     *        information about the user and feedback text
     */
    onFacilityDataChangeRequest(payload) {
        const onError = error => {
            this.bus.emit('SUBMIT_FACILITY_DATA_CHANGE_ERROR', {
                level: Errors.CRITICAL,
                originalError: error,
                additionalInfo:
                    'Sending facility data change failed when communicating with API. Check paths.',
            });
        };

        this.mailHookDataSource
            .postSelfStorageFacilityDataChange(payload)
            .then(() => {
                this.bus.emit('UPDATE_MODAL_STATE', {
                    title: '',
                    modalBoxClass: 'submit-facility success',
                    name: 'Facility data submitted successfully',
                });
                this.bus.emit('SUBMIT_FACILITY_DATA_CHANGE_SUCCESS');
            })
            .catch(onError);
    }


    /**
     * Event handler for Feedback Send Request
     * @param payload
     *        information about the user and feedback text
     */
    onFeedbackSendRequest(payload) {
        const onError = error => {
            this.bus.emit('FEEDBACK_ERROR', {
                level: Errors.CRITICAL,
                originalError: error,
                additionalInfo:
                    'Sending feedback failed when communicating with API. Check paths.',
            });
        };

        this.feedbackDataSource
            .postFeedback(payload)
            .then(() => {
                this.bus.emit('SEND_FEEDBACK_SUCCESS');
                this.bus.emit('CLOSE_MODAL');
            })
            .catch(onError);
    }

    /**
     *  Event handler for popup request
     *  This opens a popup with feedback fields.
     */

    onFeedbackPopupRequest() {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.feedback',
            name: 'Feedback',
            modalBoxClass: 'modal-box--feedback modal-box--medium',
            modalContent: React.createElement(FeedbackPopup),
        });
    }

    /**
     *  Event handler for popup request
     *  This opens a popup with data classification component... pass mapInstanceId to component
     *  @param {String} mapInstanceId
     */
    onDataClassificationPopupRequest(mapInstanceId) {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.editCutpoints',
            name: 'Data Classification',
            modalHeaderClasses: 'modal-header--no-header',
            canCloseModal: true,
            canClickAway: false,
            modalBoxClass: 'modal-box--edit-cutpoints',
            modalContent: React.createElement(DataClassificationPopup, {
                mapInstanceId,
            }),
        });
    }

    /** @param {import('../types').VisualReport} visualReport */
    onUpdateVisualReportPopupRequest = visualReport => {
        /** @type {import('../../../').ModalParams} */
        const params = {
            canClickAway: true,
            title: 'modals.userLocationEdit',
            modalBoxClass: 'modal-box--save-as-project',
            modalContent: React.createElement(VisualReportPopup, {
                visualReport,
                isEdit: true,
            }),
        };
        this.bus.emit('OPEN_MODAL', params);
    };

    onVisualReportSavePopupRequest = request => {
        /** @type {import('../../../').ModalParams} */
        const params = {
            title: 'modals.saveAs',
            name: 'Save insights as',
            modalBoxClass: 'modal-box--save-as-project',
            canClickAway: true,
            modalContent: React.createElement(VisualReportPopup, {
                visualReport: request.visualReport,
            }),
        };
        this.bus.emit('OPEN_MODAL', params);
    }

    /**
     * Event handler for a warning popup that warns the user of
     * the map being switched to single map before creating an analysis.
     * @param {object} param0
     * @param {string} param0.mapInstanceId
     * @param {string} param0.analysisTypeId
     */
    onSingleMapSwitchWarningRequest = ({ mapInstanceId, analysisTypeId }) => {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.createAnAnalysis',
            name: 'Single map switch warning',
            canClickAway: true,
            modalBoxClass: 'modal-box--warning-switch-modal',
            modalContent: React.createElement(SingleMapSwitchWarningPopup, {
                mapInstanceId,
                analysisTypeId,
            }),
        });
    }

    /**
     * Event handler for a warning popup that warns the user of
     * removal of an analysis before proceeding to a compare view (multiple maps).
     * @param {object} param0
     * @param {string[]} param0.mapInstanceIds
     * @param {string[]} param0.frameType
     */
    onAnalysisRemovalWarningRequest = ({ mapInstanceIds, frameType }) => {
        this.bus.emit('OPEN_MODAL', {
            title: 'modals.switchToCompareView',
            name: 'Analysis removal warning',
            canClickAway: true,
            modalBoxClass: 'modal-box--warning-switch-modal',
            modalContent: React.createElement(FrameTypeSwitchWarning, {
                mapInstanceIds,
                frameType,
            }),
        });
    }

    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default PopupController;

import React from 'react';
import classNames from 'classnames';

import AppConfig from '../../appConfig';
import { resizeCanvas } from '../../helpers/Util';

import ApplicationMode from '../../enums/ApplicationMode';
import FrameLockModes from '../../enums/FrameLockMode';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import { HelpTourTargets } from '../../enums/HelpTourDefinitions';
import Orientation from '../../enums/Orientation';

import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import MapPanelContainer from '../mapPanel/MapPanelContainer';
import SearchBoxPanel from '../searchBox/SearchBoxPanel';
import VisualizationLegend from '../visualizationLegend/VisualizationLegend';
import MapFiltersIndicator from '../mapIndicators/MapFiltersIndicator';
import MapControls from '../mapControls/MapControls';
import MapLogo from '../mapControls/MapLogo';
import DataBrowser from '../dataBrowser/DataBrowser';
import CondensedFrameComponents from './CondensedFrameComponents';
import MapInfoBubbleSwitch from '../mapControls/MapInfoBubbleSwitch';

class SideBySideMapsFrame extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            activeMapIndex: 0,
        };
    }

    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.SIDE_BY_SIDE_MAPS);
        AppConfig.sentryRecordEvent('entered side-by-syde map frame');

        if (this.props.displayDataBrowser) {
            const leftMap = this.props.frame.mapInstances[0];
            if (this.props.mapInstanceId === leftMap.id) {
                this.handleLeftDataBrowserRequest();
            } else {
                this.handleRightDataBrowserRequest();
            }
        }
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    handleMapMove = ({ originalEvent, source }) => {
        const { frame } = this.props;
        const [leftMapInstance, rightMapInstance] = frame.mapInstances;

        if (frame.lockMode !== FrameLockModes.POSITION || originalEvent.lockMode) return;

        const lockMode = {
            lockMode: true,
        };

        const location = {
            center: source.dragonflyMap.getCenter(),
            zoom: source.dragonflyMap.getZoom(),
            bearing: source.dragonflyMap.getBearing(),
            pitch: source.dragonflyMap.getPitch(),
        };

        if (source.id === leftMapInstance.id && this.mapRightRef && this.mapRightRef.dragonflyMap) {
            this.mapRightRef.dragonflyMap.jumpTo(location, lockMode);
        } else if (source.id === rightMapInstance.id && this.mapLeftRef && this.mapLeftRef.dragonflyMap) {
            this.mapLeftRef.dragonflyMap.jumpTo(location, lockMode);
        }
    };

    generateThumbnail() {
        if (!this._leftMapImageData || !this._rightMapImageData) return;

        const canvas = document.createElement('canvas');
        canvas.width = this._leftMapImageData.width + this._rightMapImageData.width;
        canvas.height = Math.max(this._leftMapImageData.height, this._rightMapImageData.height);

        const ctx = canvas.getContext('2d');
        ctx.putImageData(this._leftMapImageData, 0, 0);
        ctx.putImageData(this._rightMapImageData, this._leftMapImageData.width, 0);
        ctx.globalCompositeOperation = 'copy';
        ctx.scale(1, -1);
        ctx.translate(0, -canvas.height);
        ctx.drawImage(canvas, 0, 0);

        const thumbnail = resizeCanvas(canvas).toDataURL();
        const thumbnailLarge = canvas.toDataURL('image/jpeg', 0.7);

        this.emit('UPDATE_CURRENT_FRAME_THUMBNAIL_REQUEST', { thumbnail, thumbnailLarge });
    }

    handleLeftMapImageReady = imageData => {
        this._leftMapImageData = imageData;
        this.generateThumbnail();
    };

    handleRightMapImageReady = imageData => {
        this._rightMapImageData = imageData;
        this.generateThumbnail();
    };

    handleMapSwitch = activeMapIndex => {
        this.setState({ activeMapIndex });
    };

    handleLeftDataBrowserRequest = () => {
        this.setState({ showLeftDataBrowser: true });
    };

    handleRightDataBrowserRequest = () => {
        this.setState({ showRightDataBrowser: true });
    };

    handleLeftDataBrowserClose = () => {
        this.setState({ showLeftDataBrowser: false });
    };

    handleRightDataBrowserClose = () => {
        this.setState({ showRightDataBrowser: false });
    };

    handleSearchVisibilityToggle = searchExpanded => {
        this.setState({ searchExpanded });
    };

    renderFrameComponents() {
        const {
            frame,
            isUserPro,
            isCondensedLayout,
            children,
        } = this.props;

        const { activeMapIndex, searchExpanded, showLeftDataBrowser, showRightDataBrowser } = this.state;
        const { applicationMode } = this.context;
        const [leftMapInstance, rightMapInstance] = frame.mapInstances;

        if (isCondensedLayout) {
            return (
                <CondensedFrameComponents
                    isUserPro={isUserPro}
                    mapInstances={frame.mapInstances}
                    mapViewer={activeMapIndex === 0 ? this.mapLeftRef : this.mapRightRef}
                    activeMapIndex={activeMapIndex}
                    onMapChange={this.handleMapSwitch}
                    onShowDataBrowser={activeMapIndex === 0 ? this.handleLeftDataBrowserRequest : this.handleRightDataBrowserRequest}
                    applicationMode={applicationMode}
                >
                    {children}
                </CondensedFrameComponents>
            );
        }

        return (
            <div className="frame__components">
                <div className="frame__components-block frame__components-block--top-left">
                    {this.mapLeftRef && !showLeftDataBrowser &&
                        <MapPanelContainer
                            mapInstance={leftMapInstance}
                            mapInstanceIndex={frame.mapInstances.indexOf(leftMapInstance)}
                            isUserPro={isUserPro}
                            onShowDataBrowser={this.handleLeftDataBrowserRequest}
                            mapViewer={this.mapLeftRef}
                            tourId={HelpTourTargets.MAP_PANEL}
                        />
                    }
                    <MapFiltersIndicator mapInstance={leftMapInstance} alignLeft />
                </div>
                <div className="frame__components-block frame__components-block--top-right">
                    {this.mapRightRef && !showRightDataBrowser &&
                        <MapPanelContainer
                            mapInstance={rightMapInstance}
                            mapInstanceIndex={frame.mapInstances.indexOf(rightMapInstance)}
                            isUserPro={isUserPro}
                            onShowDataBrowser={this.handleRightDataBrowserRequest}
                            mapViewer={this.mapRightRef}
                        />
                    }
                    <div className="flex-it flex-end stretch-width">
                        {!searchExpanded &&
                            <MapFiltersIndicator mapInstance={rightMapInstance} />
                        }
                        {ApplicationMode.isViewMode(applicationMode) &&
                            <SearchBoxPanel
                                mapInstanceIds={frame.mapInstances.map(m => m.id)}
                                onToggleVisibility={this.handleSearchVisibilityToggle}
                            />
                        }
                    </div>
                </div>
                <div className="frame__components-block frame__components-block--bottom-left">
                    <MapControls mapInstance={leftMapInstance} alignLeft />
                    <VisualizationLegend
                        mapInstance={leftMapInstance}
                        orientation={Orientation.LEFT}
                    />
                </div>
                <div className="frame__components-block frame__components-block--bottom-right">
                    <VisualizationLegend
                        mapInstance={rightMapInstance}
                        orientation={Orientation.RIGHT}
                        tourId={HelpTourTargets.MAP_LEGEND}
                    />
                    <div className="flex-it column center">
                        {this.props.children}
                        <MapControls mapInstance={rightMapInstance} />
                    </div>
                    <MapInfoBubbleSwitch mapInstance={rightMapInstance} />
                </div>
                <MapLogo />
            </div>
        );
    }

    render() {
        const { frame, isCondensedLayout } = this.props;
        const { activeMapIndex, showLeftDataBrowser, showRightDataBrowser } = this.state;

        const [leftMapInstance, rightMapInstance] = frame.mapInstances;
        const frameClasses = classNames('frame frame--side-by-side', {
            'frame--condensed-layout': isCondensedLayout,
        });
        const leftMapClasses = classNames('frame__map', {
            'frame__map--inactive': isCondensedLayout && activeMapIndex === 1,
        });
        const rightMapClasses = classNames('frame__map', {
            'frame__map--inactive': isCondensedLayout && activeMapIndex === 0,
        });

        return (<div className={frameClasses}>
            <div className={leftMapClasses}>
                <MapViewer
                    ref={c => (this.mapLeftRef = c)}
                    mapInstance={leftMapInstance}
                    onImageReady={this.handleLeftMapImageReady}
                    onMove={this.handleMapMove}
                    annotations
                    leftMap
                />
            </div>
            {!isCondensedLayout && <div className="frame__map-separator" />}
            <div className={rightMapClasses}>
                <MapViewer
                    ref={c => (this.mapRightRef = c)}
                    mapInstance={rightMapInstance}
                    onImageReady={this.handleRightMapImageReady}
                    onMove={this.handleMapMove}
                    annotations
                />
            </div>
            {this.renderFrameComponents()}
            <div className="frame__browser frame__browser--left">
                {showLeftDataBrowser && <DataBrowser mapInstance={leftMapInstance} onClose={this.handleLeftDataBrowserClose} />}
            </div>
            <div className="frame__browser frame__browser--right">
                {showRightDataBrowser && <DataBrowser mapInstance={rightMapInstance} onClose={this.handleRightDataBrowserClose} />}
            </div>
        </div>);
    }
}

export default SideBySideMapsFrame;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import ApplicationMode from '../../enums/ApplicationMode';
import ClearDialog from './ClearDialog';
import EditFiltersDialog from './EditFiltersDialog';
import Indicator from './Indicator';
import { injectIntl } from "react-intl";
import FilterCombiner from "../../enums/FilterCombiner";

const INDICATOR_OPTIONS = {
    DATA_FILTER: 1,
    MASK: 2,
    MASK_AND_FILTER: 3,
    GEOJSON_FILTER: 4,
    MASK_AND_FILTER_AND_GEOJSON_FILTER: 5,
    MASK_AND_GEOJSON_FILTER: 6,
    DATA_AND_GEOJSON_FILTER: 7
};

class MapFiltersIndicator extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            clearDialogOption: undefined,
            editFiltersDialogVisible: false,
            appliedGeoFilters: undefined,
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            MAP_MASKING_FILTER_APPLIED: this.onMaskingFilterUpdated,
            MAP_MASKING_FILTER_REMOVED: this.onMaskingFilterUpdated,
            MAP_DATA_FILTER_APPLIED: this.onMaskingFilterUpdated,
            MAP_DATA_FILTER_REMOVED: this.onMaskingFilterUpdated,
            APPLY_POINTS_FILTERS_REQUEST: this.onApplyPointsFilters,
            HIDE_FILTER_AND_MASK_INDICATOR: this.onHideMapFiltersIndicator,
            APPLIED_FILTERS_RESPONSE: this.onAppliedFiltersResponse,
        });
        this.emit('GET_APPLIED_FILTERS_REQUEST');
    }

    cleanAppliedFilters = (data) => {
        if (!data) {
            return;
        }
        const { appliedFilters, addedCriteria } = data;
        if (!addedCriteria || !appliedFilters) {
            return;
        }

        const allowedKeys = new Set(addedCriteria.map((item) => item.property));
        const filteredAppliedFilters = Object.fromEntries(
            Object.entries(appliedFilters).filter(([key]) => allowedKeys.has(key))
        );

        const cleanedData = {
            ...data,
            appliedFilters: filteredAppliedFilters
        };

        const isEmpty = Object.keys(cleanedData.appliedFilters).length === 0 && cleanedData.addedCriteria.length === 0;

        if (isEmpty) {
            this.setState({
                appliedGeoFilters: undefined
            });

            return undefined;
        }

        return cleanedData;
    };

    mapToAppliedGeoFilters = (eventMap, prevFilters = undefined) => {
        if (Object(eventMap).isEmpty && !prevFilters) {
            return null;
        }
        const cleanedData = this.cleanAppliedFilters(eventMap);

        if (!cleanedData && !prevFilters) {
            return null;
        }

        const prevAppliedFilters = (prevFilters && prevFilters.appliedFilters) ? prevFilters.appliedFilters : {};
        const newAppliedFilters = (cleanedData && cleanedData.appliedFilters) ? cleanedData.appliedFilters : {};

        const mergedAppliedFilters = { ...prevAppliedFilters, ...newAppliedFilters };

        return {
            ...prevFilters,
            ...cleanedData,
            appliedFilters: mergedAppliedFilters
        };
    };

    onAppliedFiltersResponse = eventMap => {
        this.setState({ appliedGeoFilters: this.mapToAppliedGeoFilters(eventMap) });
    }

    onApplyPointsFilters = (eventMap) => {
        this.setState((prevState) => {
            const mappedEvent = this.mapToAppliedGeoFilters(eventMap, prevState);
            this.emit('UPDATE_APPLIED_FILTERS_REQUEST', { appliedGeoFilters: mappedEvent });

            return { appliedGeoFilters: mappedEvent };
        });
    };

    componentWillReceiveProps() {
        this.setState({ clearDialogOption: undefined });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onMaskingFilterUpdated(eventMap) {
        if (eventMap.source.id === this.props.mapInstance.id) {
            this.forceUpdate();
        }
    }

    onHideMapFiltersIndicator = () => {
        this.setState({
            clearDialogOption: undefined,
            editFiltersDialogVisible: false,
            appliedGeoFilters: undefined,
        });
    }

    /*
    Before switching to a new frame it's necessary to close the previous and then open the new one.
    This is handled by the controller of the given frame and then further by FrameContainer.
     */
    handleIndicatorClick = newMode => {
        const { mapInstance, applicationFrameType } = this.props;

        switch (true) {
            // Switching from report editor frame is handled in a special way so that the user is always
            // returned to the report frame after going to mask or filter frame.
            case applicationFrameType === ApplicationFrameType.REPORT_EDITOR:
                this.emit('EXIT_REPORT_MODE_AND_ENTER_NEW_MODE', {
                    reportParentMapInstanceId: mapInstance.reportParentMapInstanceId,
                    newMode,
                });
                this.emit('EXIT_REPORT_MODE');
                break;

            // If the current application frame is mask editor before the switch is made to a different
            // frame the mask frame must be closed first.
            case applicationFrameType === ApplicationFrameType.MASK_EDITOR:
                if (newMode !== 'ENTER_MASK_MODE') {
                    this.emit('EXIT_MASK_MODE', {
                        mapInstance,
                        mapInstanceId: mapInstance.id,
                        newMode,
                    });

                    if (newMode === 'SHOW_POINTS_PANEL') {
                        // We need to add a simple timeout because the bus event isn't connected to a handler yet
                        setTimeout(() => {
                            this.emit('SHOW_POINTS_PANEL', {
                                mapInstance,
                                mapInstanceId: mapInstance.id,
                            });
                        }, 100);
                        return;
                    }
                }
                break;

            // If the current application frame is filter editor, before the switch is made to a different
            // frame the filter frame must be closed first.
            case applicationFrameType === ApplicationFrameType.DATA_FILTER_EDITOR:
                if (newMode !== 'ENTER_DATA_FILTER_MODE') {
                    this.emit('EXIT_DATA_FILTER_MODE', {
                        mapInstance,
                        mapInstanceId: mapInstance.id,
                        newMode,
                    });

                    if (newMode === 'SHOW_POINTS_PANEL') {
                        // We need to add a simple timeout because the bus event isn't connected to a handler yet
                        setTimeout(() => {
                            this.emit('SHOW_POINTS_PANEL', {
                                mapInstance,
                                mapInstanceId: mapInstance.id,
                            });
                        }, 100);
                        return;
                    }
                }
                break;

            case applicationFrameType === ApplicationFrameType.MAP_EDITOR:
                this.emit('HIDE_MAP_LAYERS_EDITOR', {
                    mapInstance,
                    newMode,
                });
                break;

            default:
                this.emit(newMode, {
                    mapInstance,
                    mapInstanceId: mapInstance.id,
                });
        }
    };

    toggleEditFiltersView = () => {
        const { editFiltersDialogVisible } = this.state;
        this.setState({ editFiltersDialogVisible: !editFiltersDialogVisible });
    };

    handleClear = clearDialogOption => {
        this.setState({ clearDialogOption });
    };

    handleClearMask = () => {
        const { mapInstance } = this.props;

        this.emit('REMOVE_MASKING_FILTER_REQUEST', {
            mapInstanceId: mapInstance.id,
            dataGeoFilter: Object.values(mapInstance.dataGeoFilters)[0],
        });
        this.setState({ clearDialogOption: undefined });
    };

    handleClearDataFilter = () => {
        const { mapInstance } = this.props;

        this.emit('REMOVE_DATA_FILTER_REQUEST', {
            mapInstanceId: mapInstance.id,
        });
        this.setState({ clearDialogOption: undefined });
    };

    handleClearGeoJsonFilter = () => {
        this.emit('MAP_APPLY_GEO_JSON_LAYER_UPDATE', {
            addedCriteria: [],
            appliedFilters: {},
            filterCombinerType: FilterCombiner.MATCH_ALL.type
        });
        this.emit('CLEAR_FACILITY_FILTER_FROM_PANEL');
        this.emit('CLEAR_FACILITY_FILTER');
        this.emit('UPDATE_APPLIED_FILTERS_REQUEST', {
            appliedGeoFilters: undefined
        });
        this.setState({ clearDialogOption: undefined, appliedGeoFilters: undefined });
    };

    handleCancelClear = () => {
        this.setState({ clearDialogOption: undefined });
    };

    getClearDialogFunction = (clearDialogOption) => {
        switch (clearDialogOption) {
            case INDICATOR_OPTIONS.GEOJSON_FILTER:
                return this.handleClearGeoJsonFilter;
            case INDICATOR_OPTIONS.MASK:
                return this.handleClearMask;
            case INDICATOR_OPTIONS.DATA_FILTER:
                return this.handleClearDataFilter;
            default:
                return null;
        }
    };

    getClearDialogText = (clearDialogOption) => {
        const { intl } = this.props;

        switch (clearDialogOption) {
            case INDICATOR_OPTIONS.GEOJSON_FILTER:
                return intl.formatMessage({ id: 'dataBrowser.geoDataFilter' });
            case INDICATOR_OPTIONS.MASK:
                return intl.formatMessage({ id: 'dataBrowser.maskMapData' });
            case INDICATOR_OPTIONS.DATA_FILTER:
                return intl.formatMessage({ id: 'dataBrowser.dataFilter' });
            default:
                return 'Filter';
        }
    };

    getOptions = clearDialogOption => {
        const { mapInstance } = this.props;
        const { appliedGeoFilters } = this.state;

        const areMultipleFiltersSelected = (appliedGeoFilters && mapInstance.hasDataFilter) ||
            (appliedGeoFilters && mapInstance.hasMaskingFilter) ||
            (mapInstance.hasMaskingFilter && mapInstance.hasDataFilter);

        const options = [
            {
                condition: areMultipleFiltersSelected,
                result: {
                    text: this.getClearDialogText(clearDialogOption),
                    clearDialogFunction: this.getClearDialogFunction(clearDialogOption),
                    clickHandle: this.toggleEditFiltersView,
                    clearDialogOption
                },
            },
            {
                condition: mapInstance.hasMaskingFilter,
                result: {
                    text: this.getClearDialogText(clearDialogOption),
                    clearDialogOption: INDICATOR_OPTIONS.MASK,
                    clearDialogFunction: this.handleClearMask,
                    clickHandle: () => this.handleIndicatorClick('ENTER_MASK_MODE'),
                },
            },
            {
                condition: appliedGeoFilters,
                result: {
                    text: this.getClearDialogText(clearDialogOption),
                    clearDialogOption: INDICATOR_OPTIONS.GEOJSON_FILTER,
                    clearDialogFunction: this.handleClearGeoJsonFilter,
                    clickHandle: () => this.handleIndicatorClick('SHOW_POINTS_PANEL')
                },
            },
            {
                condition: mapInstance.hasDataFilter,
                result: {
                    text: this.getClearDialogText(clearDialogOption),
                    clearDialogOption: INDICATOR_OPTIONS.DATA_FILTER,
                    clearDialogFunction: this.handleClearDataFilter,
                    clickHandle: () => this.handleIndicatorClick('ENTER_DATA_FILTER_MODE')
                },
            },
        ];

        const match = options.find(option => option.condition);
        return match ? match.result : null;
    }

    render() {
        const { editFiltersDialogVisible, clearDialogOption, appliedGeoFilters } = this.state;
        const { alignLeft, mapInstance } = this.props;
        const { applicationMode, isCondensedLayout } = this.context;

        const options = this.getOptions(clearDialogOption);
        // No masking or filtering or unsupported application mode
        if (applicationMode === ApplicationMode.VIEW || applicationMode === ApplicationMode.EMBED || options === null) return null;

        const filterOptions = [
            {
                condition: mapInstance.hasDataFilter,
                config: {
                    icon: <i className="material-icons md-16">tune</i>,
                    titleId: 'dataBrowser.filterAreasByCriteria',
                    classes: 'indicator-item__filter',
                    onClickEdit: () => this.handleIndicatorClick('ENTER_DATA_FILTER_MODE'),
                    onClickClear: () => this.handleClear(INDICATOR_OPTIONS.DATA_FILTER),
                },
            },
            {
                condition: appliedGeoFilters,
                config: {
                    icon: <i className="material-icons md-16">place</i>,
                    titleId: 'dataBrowser.findFacilitiesByCriteria',
                    classes: 'indicator-item__geojson',
                    onClickEdit: () => this.handleIndicatorClick('SHOW_POINTS_PANEL'),
                    onClickClear: () => this.handleClear(INDICATOR_OPTIONS.GEOJSON_FILTER),
                },
            },
            {
                condition: mapInstance.hasMaskingFilter,
                config: {
                    icon: <i className="socex-icon-mask-1" />,
                    titleId: 'dataBrowser.maskMapData',
                    classes: 'indicator-item__mask',
                    onClickEdit: () => this.handleIndicatorClick('ENTER_MASK_MODE'),
                    onClickClear: () => this.handleClear(INDICATOR_OPTIONS.MASK),
                },
            },
        ].filter(option => option.condition).map(option => option.config);

        if (clearDialogOption) {
            const clearDialogOptions = this.getOptions(clearDialogOption);

            return (
                <ClearDialog
                    text={clearDialogOptions.text}
                    onCancel={this.handleCancelClear}
                    onClear={clearDialogOptions.clearDialogFunction}
                />
            );
        }
        return (
            <div className={classNames('mask-filter-indicator', { 'mask-filter-indicator--left': alignLeft })}>
                <Indicator
                    isCondensedLayout={isCondensedLayout}
                    text={`Filters (${filterOptions.length})`}
                    multipleOptions={filterOptions.length > 1}
                    clickHandle={options.clickHandle}
                    onClickClear={() => this.handleClear(options.clearDialogOption || clearDialogOption)}
                    editFiltersDialogVisible={editFiltersDialogVisible}
                />
                {editFiltersDialogVisible && (
                    <EditFiltersDialog filterOptions={filterOptions} />
                )}
            </div>
        );
    }
}

MapFiltersIndicator.propTypes = {
    mapInstance: PropTypes.object.isRequired,
    applicationFrameType: PropTypes.string,
    alignLeft: PropTypes.bool
};

MapFiltersIndicator.defaultProps = {
    applicationFrameType: undefined,
    alignLeft: false
};

export default injectIntl(MapFiltersIndicator);

// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';

import BusComponent from './BusComponent';
import AppConfig from '../appConfig';

class BlurOverlay extends BusComponent {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const subject = this.props.intl.formatMessage({id: 'starterTier.emailSubject'});
        const body = this.props.intl.formatMessage({id: 'starterTier.emailBody'});
    
        const mailto = `mailto:${AppConfig.constants.upgradeEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        
        return (
            <div className="blur-overlay flex-it justify-center center column">
                <div className="blur-overlay__upgrade-text">{this.props.intl.formatMessage({id: 'starterTier.upgradeToPro'})}</div>
                <a
                    className="blur-overlay__contact-us"
                    href={mailto}
                >
                    {this.props.intl.formatMessage({id: 'starterTier.contactUs'})}
                </a>
            </div>
        );
    }
}

export default injectIntl(BlurOverlay);

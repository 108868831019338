// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import AppConfig from '../../appConfig';
import BusComponent from '../BusComponent';
import { POINT_TYPES } from '../../enums/PointTypes';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('../../types').VisualReport} visualReport
 *
 * @extends {BusComponent<Props>}
 */
class UserLocationListItem extends BusComponent {
    onClick = () => {
        this.bus.emit('START_LOCATION_ANALYSIS', {
            visualReport: this.props.visualReport,
            mapInstanceId: this.props.mapInstanceId,
        });
    };

    onEdit = () => {
        this.emit(
            'UPDATE_VISUAL_REPORT_POPUP_REQUEST',
            this.props.visualReport,
        );
    };

    onDelete = () => {
        this.emit('VISUAL_REPORT_DELETE_REQUEST', this.props.visualReport.id);
    };

    /**
     * @param {import('../../types').PointType | undefined} type
     * @returns {string}
     */
    getIconForPointType = type => {
        switch (type) {
            case POINT_TYPES.ssFacility:
                return 'warehouse';
            case POINT_TYPES.ssConstruction:
                return 'front_loader';
            default:
                return 'place';
        }
    }

    render() {
        const poiIcon = this.getIconForPointType(this.props.visualReport.pointType);
        return (
            <div className="user-locations-list-item">
                <div className="flex-it center grow" onClick={this.onClick}>
                    <div className="user-locations-list-item__icon">
                        <i className="material-icons">{poiIcon}</i>
                    </div>
                    <span className="ellipsis">
                        {this.props.visualReport.title}
                    </span>
                </div>
                <button
                    className="btn-icon user-locations-list-item__button"
                    onClick={this.onEdit}
                >
                    <i className="material-icons">edit</i>
                </button>
                <button
                    className="btn-icon user-locations-list-item__button"
                    onClick={this.onDelete}
                >
                    <i className="material-icons">delete</i>
                </button>
            </div>
        );
    }
}

UserLocationListItem.propTypes = {
    visualReport: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
};

export default UserLocationListItem;

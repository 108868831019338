import React from 'react';

import BusComponent from '../BusComponent';
import SingleMapFrame from './SingleMapFrame';
import SideBySideMapsFrame from './SideBySideMapsFrame';
import SwipeMapsFrame from './SwipeMapsFrame';
import MapEditorFrame from './MapEditorFrame';
import MapReportFrame from './MapReportFrame';
import MapMaskEditorFrame from './MapMaskEditorFrame';
import MapExportFrame from './mapExport/MapExportFrame';
import DataFilterEditorFrame from './DataFilterEditorFrame';
import InfoBubble from '../infoBubble/InfoBubble';
import FrameType from '../../enums/FrameType';
import ApplicationMode from '../../enums/ApplicationMode';
import withResponsivness from './responsiveFrame';
import Share from '../share/Share';
import classnames from 'classnames';
import InfoBubbleMode from '../../enums/InfoBubbleMode';

const ResponsiveSingleMapFrame = withResponsivness(SingleMapFrame, 800);
const ResponsiveSideBySideMapFrame = withResponsivness(SideBySideMapsFrame, 800);
const ResponsiveSwipeMapFrame = withResponsivness(SwipeMapsFrame, 800);

class FrameContainer extends BusComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            displayMapEditorFrame: false,
            displayMapReportFrame: false,
            displayMapMaskEditorFrame: false,
            displayMapExportFrame: false,
            displayDataFilterEditorFrame: false,
            lastFrame: undefined,
            displayDataBrowser: false,
            appliedGeoFilters: undefined,
        };

        this.bindGluBusEvents({
            DISPLAY_MAP_EDITOR_FRAME: this.onDisplayMapEditorFrame,
            HIDE_MAP_LAYERS_EDITOR: this.onHideMapEditorFrame,
            DISPLAY_MAP_REPORT_FRAME: this.onDisplayMapReportFrame,
            HIDE_MAP_REPORT_FRAME: this.onHideMapReportFrame,
            DISPLAY_MAP_MASK_EDITOR_FRAME: this.onDisplayMapMaskEditorFrame,
            HIDE_MAP_MASK_EDITOR_FRAME: this.onHideMapMaskEditorFrame,
            DISPLAY_DATA_FILTER_EDITOR_FRAME: this.onDisplayDataFilterEditorFrame,
            HIDE_DATA_FILTER_EDITOR_FRAME: this.onHideDataFilterEditorFrame,
            EXIT_REPORT_MODE_AND_ENTER_NEW_MODE: this.onHideMapReportAndEnterNewFrame,
            DISPLAY_MAP_EXPORT_FRAME: this.onDisplayMapExportFrame,
            HIDE_MAP_EXPORT_FRAME: this.onHideMapExportFrame,
            RESET_FRAME_CONTAINER: this.resetFrameContainer,
            // if the user logs in
            USER_INFO_LOAD_SUCCESS: this.onUserInfo,
            USER_INFO_GET_SUCCESS: this.onUserInfo,
            // Find facilities filter request for dropdown
            UPDATE_APPLIED_FILTERS_REQUEST: this.onUpdateAppliedFilters,
            GET_APPLIED_FILTERS_REQUEST: this.onGetAppliedPointsFiltersRequest,
        });
    }

    onGetAppliedPointsFiltersRequest() {
        this.emit('APPLIED_FILTERS_RESPONSE', this.state.appliedGeoFilters);
    };

    onUpdateAppliedFilters = (eventMap) => {
        if (eventMap.hasOwnProperty('appliedGeoFilters') && !eventMap.appliedGeoFilters) {
            this.setState({
                appliedGeoFilters: undefined
            });
        }
        else {
            this.setState({ appliedGeoFilters: eventMap.appliedGeoFilters });
        }
    };

    componentDidMount() {
        this.emit('USER_INFO_GET_REQUEST', { source: this });
        this.emit('FRAME_CONTAINER_MOUNTED', { mapInstances: this.props.frame.mapInstances });
    }

    componentDidUpdate() {
        this.state.displayDataBrowser = false;
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onUserInfo(userInfo, source) {
        if (source && this !== source) return;
        const isUserLoggedIn = userInfo && userInfo.userId !== -1;
        const isUserPro = true; // userInfo && userInfo.product.find(licence => licence === 'PRO') !== undefined;
        this.setState({
            currentUser: userInfo,
            isUserLoggedIn,
            isUserPro,
        });
    }

    onDisplayMapMaskEditorFrame({ mapInstance }) {
        this.setState({
            mapMaskEditorMapInstance: mapInstance,
            displayMapMaskEditorFrame: true,
        });
        // info bubble must be set to hover so masking can work
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.HOVER, storePreviousMode: true });
    }

    onHideMapReportAndEnterNewFrame({ reportParentMapInstanceId, newMode }) {
        const mapInstance = this.props.frame.mapInstances.find(mi => reportParentMapInstanceId === mi.id);
        this.setState({
            mapReportMapInstance: undefined,
            parentReportMapInstance: undefined,
            displayMapReportFrame: false,
            lastFrame: 'report',
        }, () => {
            this.emit(newMode, {
                mapInstanceId: mapInstance.id,
                mapInstance,
            });
        });
    }

    onHideMapMaskEditorFrame({ mapInstance, newMode }) {
        if (this.state.lastFrame === 'report') {
            this.setState({
                mapMaskEditorMapInstance: undefined,
                displayMapMaskEditorFrame: false,
                lastFrame: undefined,
            }, () => {
                this.emit('ENTER_REPORT_MODE', {
                    mapInstanceId: mapInstance.id,
                    mapInstance,
                });
            });
        } else {
            this.setState({
                mapMaskEditorMapInstance: undefined,
                displayMapMaskEditorFrame: false,
            }, this.goToNewMode(mapInstance, newMode));
        }
        // info bubble mode should be restored to previous mode after leaving
        this.emit('INFO_BUBBLE_MODE_RESTORE_PREVIOUS_REQUEST');
    }

    onDisplayDataFilterEditorFrame({ mapInstance, surveyName }) {
        this.setState({
            dataFilterEditorMapInstance: mapInstance,
            displayDataFilterEditorFrame: true,
            surveyName,
        });
        // info bubble must be set to hover
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.HOVER, storePreviousMode: true });
    }

    onHideDataFilterEditorFrame({ mapInstance, newMode, displayDataBrowser }) {
        if (this.state.lastFrame === 'report') {
            this.setState({
                dataFilterEditorMapInstance: undefined,
                displayDataFilterEditorFrame: false,
                lastFrame: undefined,
            }, () => {
                this.emit('ENTER_REPORT_MODE', {
                    mapInstanceId: mapInstance.id,
                    mapInstance,
                });
            });
        } else {
            this.setState({
                dataFilterEditorMapInstance: undefined,
                displayDataFilterEditorFrame: false,
                displayDataBrowser,
                mapInstanceId: mapInstance.id,
            }, this.goToNewMode(mapInstance, newMode));
        }

        // info bubble mode should be restored to previous mode after leaving
        this.emit('INFO_BUBBLE_MODE_RESTORE_PREVIOUS_REQUEST');
    }

    onDisplayMapEditorFrame({ mapInstanceId, initialView }) {
        const { frame } = this.props;

        this.setState({
            mapEditorMapInstance: frame.mapInstances.find(m => m.id === mapInstanceId),
            mapEditorInitialView: initialView,
            displayMapEditorFrame: true,
        });
    }

    onHideMapEditorFrame({ mapInstance, newMode }) {
        this.setState({
            mapEditorMapInstance: undefined,
            displayMapEditorFrame: false,
        }, this.goToNewMode(mapInstance, newMode));
    }

    onDisplayMapReportFrame({ mapInstance, parentMapInstance }) {
        this.setState({
            mapReportMapInstance: mapInstance,
            parentReportMapInstance: parentMapInstance,
            displayMapReportFrame: true,
        });
        // info bubble must be set to hover
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.HOVER, storePreviousMode: true });
        this.emit('REPORT_MODE_ACTIVATED', { mapInstance });
    }

    onHideMapReportFrame() {
        this.setState({
            mapReportMapInstance: undefined,
            parentReportMapInstance: undefined,
            displayMapReportFrame: false,
        });

        this.emit('REPORT_MODE_DEACTIVATED', {});

        // info bubble mode should be restored to previous mode after leaving
        this.emit('INFO_BUBBLE_MODE_RESTORE_PREVIOUS_REQUEST');
    }

    onDisplayMapExportFrame({ mapInstance }) {
        this.setState({
            mapExportMapInstance: mapInstance,
            displayMapExportFrame: true,
        });
        // info bubble must be set to hover
        this.emit('INFO_BUBBLE_MODE_UPDATE_REQUEST', { infoBubbleMode: InfoBubbleMode.HOVER, storePreviousMode: true });
    }

    onHideMapExportFrame() {
        this.setState({
            mapExportMapInstance: undefined,
            displayMapExportFrame: false,
        });

        // info bubble mode should be restored to previous mode after leaving
        this.emit('INFO_BUBBLE_MODE_RESTORE_PREVIOUS_REQUEST');
    }

    goToNewMode = (mapInstance, newMode) => {
        if (newMode) {
            this.emit(newMode, {
                mapInstanceId: mapInstance.id,
                mapInstance,
            });
        }
    }

    resetFrameContainer() {
        this.setState({
            displayMapEditorFrame: false,
            displayMapReportFrame: false,
            displayMapMaskEditorFrame: false,
            displayMapExportFrame: false,
            displayDataFilterEditorFrame: false,
            lastFrame: undefined,
        });
    }

    renderFrameControls() {
        const { applicationMode } = this.context;

        const canShare = applicationMode === ApplicationMode.EMBED;

        return (
            <div className="flex-it column center">
                {canShare && <Share />}
            </div>
        );
    }

    render() {
        // Map export frame should be first so it opens even if user is currently in some other mode like mask or annotate
        if (this.state.displayMapExportFrame) {
            return (<div className="frame-container">
                <MapExportFrame mapInstance={this.state.mapExportMapInstance} />
            </div>);
        } else if (this.state.displayMapEditorFrame) {
            return (<div className="frame-container">
                <MapEditorFrame
                    initialView={this.state.mapEditorInitialView}
                    isUserPro={this.state.isUserPro}
                    mapInstance={this.state.mapEditorMapInstance}
                />
            </div>);
        } else if (this.state.displayMapReportFrame) {
            return (<div className="frame-container">
                <MapReportFrame
                    mapInstance={this.state.mapReportMapInstance}
                    parentMapInstance={this.state.parentReportMapInstance}
                />
                <InfoBubble
                    mapInstances={[this.state.mapReportMapInstance]}
                    geoTitleOnly
                />
            </div>);
        } else if (this.state.displayMapMaskEditorFrame) {
            return (<div className="frame-container">
                <MapMaskEditorFrame mapInstance={this.state.mapMaskEditorMapInstance} />
                <InfoBubble
                    mapInstances={[this.state.mapMaskEditorMapInstance]}
                    geoTitleOnly
                />
            </div>);
        } else if (this.state.displayDataFilterEditorFrame) {
            return (<div className={classnames('frame-container', this.props.className)}>
                <DataFilterEditorFrame
                    key="map-filtering-frame"
                    options={this.context.options}
                    mapInstance={this.state.dataFilterEditorMapInstance}
                    surveyName={this.state.surveyName}
                />
                <InfoBubble
                    options={this.context.options}
                    mapInstances={[this.state.mapMaskEditorMapInstance]}
                    geoTitleOnly
                />
            </div>);
        }

        const { frame } = this.props;
        const { currentUser, isUserPro, displayDataBrowser, mapInstanceId } = this.state;

        let currentFrame;
        switch (frame.type) {
        case FrameType.SINGLE_MAP:
            currentFrame = (
                <ResponsiveSingleMapFrame
                    frame={frame}
                    currentUser={currentUser}
                    isUserPro={isUserPro}
                    displayDataBrowser={displayDataBrowser}
                >
                    {this.renderFrameControls()}
                </ResponsiveSingleMapFrame>);
            break;
        case FrameType.SIDE_BY_SIDE_MAPS:
            currentFrame = (
                <ResponsiveSideBySideMapFrame
                    frame={frame}
                    currentUser={currentUser}
                    isUserPro={isUserPro}
                    displayDataBrowser={displayDataBrowser}
                    mapInstanceId={mapInstanceId}
                >
                    {this.renderFrameControls()}
                </ResponsiveSideBySideMapFrame>)
                ;
            break;
        case FrameType.SWIPE:
            currentFrame = (
                <ResponsiveSwipeMapFrame
                    frame={frame}
                    currentUser={currentUser}
                    isUserPro={isUserPro}
                    displayDataBrowser={displayDataBrowser}
                    mapInstanceId={mapInstanceId}
                >
                    {this.renderFrameControls()}
                </ResponsiveSwipeMapFrame>
            );
            break;
        default:
            currentFrame = (<pre
                key="default-frame"
            >{JSON.stringify(frame, null, 2)}</pre>);
            break;
        }

        return (
            <div className="frame-container">
                {currentFrame}
                <InfoBubble mapInstances={this.props.frame.mapInstances} />
            </div>
        );
    }
}

export default FrameContainer;

import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';

import AppConfig from '../appConfig';

class VisualReportDataSource extends GLU.DataSource {
    static get name() {
        return 'VisualReportDataSource';
    }

    static getInstance() {
        return new VisualReportDataSource();
    }

    constructor() {
        super(() => false);
        /** @type {import('../types').VisualReport[]} */
        this._visualReports = [];
    }

    /** @returns {import('../types').VisualReport[]}  */
    get visualReports() {
        return this._visualReports;
    }
    /**
     * @returns {Promise<import('../types').VisualReport[]>}
     */
    loadVisualReports = () =>
        new Promise((resolve, reject) => {
            Api.visualReport
                .getVisualReports()
                .then(response => {
                    if (response.body) {                        
                        this._visualReports = response.body.map(this.parseVisualReportResponse);
                    }
                    resolve(this._visualReports);
                }, reject);
        });

    /**
     * @param {import('../types').VisualReport} visualReport
     * @returns {Promise<import('../types').VisualReport>}
     */
    saveVisualReport = visualReport => {
        const { title, point, profile, selection, reportType, pointType, facilityId } = visualReport;
        const payload = {
            title,
            definition: {
                point,
                selection,
                profile,
                pointType,
                facilityId,
            },
            type: reportType,
            tenant: AppConfig.constants.tenant,
        };
        return new Promise(resolve => {
            Api.visualReport.addVisualReport({ payload }).then(response => {
                const vReport = this.parseVisualReportResponse(response.body);
                this._visualReports.unshift(vReport);
                resolve(visualReport);
            });
        });
    };

    /**
     * @param {object} payload
     * @param {string} payload.title
     * @param {number} payload.reportId
     */
    updateVisualReport(payload) {
        return new Promise((resolve, reject) => {
            Api.visualReport.updateVisualReport({ payload }).then(response => {
                const visualReport = this.parseVisualReportResponse(response.body);
                // Replace new report with the old one
                // Remove old visual report
                const index = this._visualReports.findIndex(
                    vReport => vReport.id === payload.reportId,
                );
                // Add updated visual report to the same position
                this._visualReports[index] = visualReport;
                resolve(visualReport);
            }, reject);
        });
    }

    /**
     * @param {number} reportId
     */
    deleteVisualReport(reportId) {
        return new Promise((resolve, reject) => {
            Api.visualReport
                .deleteVisualReport({
                    payload: {
                        reportId,
                    },
                })
                .then(() => {
                    // Remove deleted user location
                    const index = this._visualReports.findIndex(
                        vReport => vReport.id === reportId,
                    );
                    this._visualReports.splice(index, 1);
                    resolve(this._visualReports);
                }, reject);
        });
    }

    /**
     * @param {import('../types').VisualReportResponse} visualReportResponse
     * @returns {import('../types').VisualReport}
     */
    parseVisualReportResponse = visualReportResponse => ({
        id: visualReportResponse.id,
        title: visualReportResponse.title,
        point: visualReportResponse.definition.point,
        selection: visualReportResponse.definition.selection,
        profile: visualReportResponse.definition.profile,
        pointType: visualReportResponse.definition.pointType,
        facilityId: visualReportResponse.definition.facilityId,
        reportType: visualReportResponse.type,
    });
}

export default VisualReportDataSource;

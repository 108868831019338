import AppConfig from '../appConfig';
import ProjectApi from './ProjectApi';
import FeedbackApi from './FeedbackApi';
import HereGeocodeApi from './HereGeocodeApi';
import HereReverseGeocodeApi from './HereReverseGeocodeApi';
import HereAutocompleteApi from './HereAutocompleteApi';
import MetadataApi from './MetadataApi';
import ShareApi from './ShareApi';
import S3Api from './S3Api';
import SessionApi from './SessionApi';
import ReportApi from './ReportApi';
import VariableApi from './VariableApi';
import UserMapViewsApi from './UserMapViewsApi';
import UserLocationsApi from './UserLocationsApi';
import UserDataApi from './UserDataApi';
import MapboxApi from './MapboxApi';
import NewReportApi from './NewReportApi';
import DingoApi from './DingoApi';
import SeApi from './SeApi';
import VisualReportsApi from './VisualReportsApi';
import MailHookApi from './MailHookApi';
import ContoursApi from './ContoursApi';
import OlapApi from './OlapApi';

class Api {
  constructor() {
    this.initialize();
  }

  get project() {
    return this._project;
  }

  get hereAutocomplete() {
    return this._hereAutocomplete;
  }

  get hereGeocode() {
    return this._hereGeocode;
  }

  get hereReverseGeocode() {
    return this._hereReverseGeocode;
  }

  get metadata() {
    return this._metadata;
  }

  get feedback() {
    return this._feedback;
  }

  get share() {
    return this._share;
  }

  get s3() {
    return this._s3;
  }

  get session() {
    return this._session;
  }

  get report() {
    return this._report;
  }

  get newReport() {
    return this._newReport;
  }

  get variable() {
    return this._variable;
  }

  get userMapView() {
    return this._userMapView;
  }

  get userLocation() {
    return this._userLocation;
  }

  get userData() {
    return this._userData;
  }

  get mapbox() {
    return this._mapbox;
  }

  get seWebsite() {
    return this._seWebsite;
  }

  get dingo() {
    return this._dingo;
  }

  get visualReport() {
    return this._visualReport;
  }

  get mailHook() {
    return this._mailHook;
  }

  get contours() {
    return this._contours;
  }

  get olap() {
    return this._olap;
  }

  initialize() {
    this._project = new ProjectApi(AppConfig.constants.backends.mapsApi);
    this._hereAutocomplete = new HereAutocompleteApi(
      AppConfig.constants.backends.hereSearch.autocomplete,
    );
    this._hereGeocode = new HereGeocodeApi(
      AppConfig.constants.backends.hereSearch.geocode,
    );
    this._hereReverseGeocode = new HereReverseGeocodeApi(
      AppConfig.constants.backends.hereSearch.revGeocode,
    );
    this._metadata = new MetadataApi(
      AppConfig.constants.backends.oldSocialexplorer,
    );
    this._feedback = new FeedbackApi(AppConfig.constants.backends.mapsApi);
    this._share = new ShareApi(AppConfig.constants.backends.mapsApi);
    this._s3 = new S3Api(AppConfig.constants.backends.s3api);
    this._session = new SessionApi(AppConfig.constants.backends.accounts);
    this._report = new ReportApi(AppConfig.constants.backends.report);
    this._variable = new VariableApi(AppConfig.constants.backends.website);
    this._userMapView = new UserMapViewsApi(
      AppConfig.constants.backends.userMapViewsApi,
    );
    this._userLocation = new UserLocationsApi(
      AppConfig.constants.backends.userLocationsApi,
    );
    this._userData = new UserDataApi(
      AppConfig.constants.backends.userUploadedFilesApi,
    );
    this._mapbox = new MapboxApi(AppConfig.constants.mapboxApi);
    this._seWebsite = new SeApi(AppConfig.constants.backends.sewebsite);
    this._dingo = new DingoApi(AppConfig.constants.frontends.dingo);
    this._visualReport = new VisualReportsApi(
      AppConfig.constants.backends.visualReportsApi,
    );
    this._mailHook = new MailHookApi(AppConfig.constants.backends.sewebsite);
    this._contours = new ContoursApi(AppConfig.constants.backends.sewebsite);

    // this endpoint is currently used only in tractIQ and Selfstorage
    // it should be renamed to this._report after we move SE to the new report endpoint
    this._newReport = new NewReportApi(AppConfig.constants.backends.report_api);
    this._olap = new OlapApi(AppConfig.constants.backends.sewebsite);
  }
}

export default new Api();

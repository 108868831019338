// eslint-disable-next-line import/prefer-default-export

const POINT_TYPES = {
    ssFacility: 'ss-facility',
    ssConstruction: 'ss-construction',
    influencer: 'influencer',
    housingStarts: 'housing-starts',
    place: 'place',
};

const ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS = [
    POINT_TYPES.ssFacility,
    POINT_TYPES.ssConstruction,
];

export { ALLOWED_POINT_TYPES_FOR_MY_PLACES_ANALYSIS, POINT_TYPES };

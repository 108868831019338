import Api from '../apis/Api';
import GLU from '../glu2.js/src/index';
import AppConfig from '../appConfig';
import UserInfo from '../objects/UserInfo';
import Mixpanel from '../helpers/Mixpanel';

class UserInfoDataSource extends GLU.DataSource {
    static get name() {
        return 'UserInfoDataSource';
    }

    static getInstance() {
        return new UserInfoDataSource();
    }

    get authParams() {
        return {
            geobufferToken: this.currentUser.geobufferToken,
            geobufferPermission: this.currentUser.geobufferPermission,
        };
    }

    /**
     * A getter for currently logged in user.
     * If the user is not logged in, the userID
     * value is -1 and user has only guest
     * privileges.
     * @returns {UserInfo}
     */
    get currentUser() {
        return this._currentUser;
    }

    /**
     * Function that retrieves user/session info
     * @returns {Promise}
     */
    loadUserInfo() {
        return new Promise((resolve, reject) => {
            Api.session.sessionInfo()
                .then(response => {
                    const sessionInfo = response.body;

                    if (!response.ok) {
                        reject('Failed to load user info');
                        return;
                    }

                    const userInfo = new UserInfo();

                    const user = sessionInfo.user;
                    userInfo.activeSubscriptions = sessionInfo.activeSubscriptions;
                    if(user) {
                        userInfo.userId = user.id;
                        userInfo.name = this.displayName(user.firstName, user.lastName);
    
                        userInfo.pictureSmallURL = user.pictureSmallURL;
                        userInfo.pictureLargeURL = user.pictureLargeURL;
                        userInfo.email = user.email;
                        Mixpanel.identifyAndSetUser(userInfo.userId, userInfo.name, userInfo.getUserProduct(), user.email);
                    }
                    else {
                        userInfo.userId = -1;
                    }

                    userInfo.product = sessionInfo.legacyLicense.product;
                    userInfo.licenseInfo = sessionInfo.legacyLicense.info;

                    this._currentUser = userInfo;

                    AppConfig.sentry.configureScope(scope => {
                        scope.setUser({
                            username: userInfo.name,
                            id: userInfo.userId,
                        });
                    });

                    resolve(this._currentUser);
                }, reject);
        });
    }

    logOut() {
        return new Promise((resolve, reject) => {
            Api.session.logOut()
                .then(response => {
                    const user = response.body;
                    const userInfo = new UserInfo();
                    userInfo.userId = user.id;
                    this._currentUser = userInfo;
                    resolve(userInfo);
                }, reject);
        });
    }

    displayName(firstName, lastName) {
        return [firstName, lastName].filter(Boolean).join(' ');
    }
}

export default UserInfoDataSource;

import 'babel-polyfill';

import React from 'react';
import PropTypes from 'prop-types';
import dragonfly from 'dragonfly-v3';
import EventEmiter from 'eventemitter3';
import MobileDetect from 'mobile-detect';
import { IntlProvider } from 'react-intl';

import ExplorePage from './pages/ExplorePage';
import ErrorPage from './pages/ErrorPage';
import UnsupportedPage from './pages/UnsupportedPage';
import ApplicationMode from './enums/ApplicationMode';
import messages, { locale } from './languages/languageConfig';
import AppConfig from './appConfig';

import ErrorController from './controllers/ErrorController';
import MetadataController from './controllers/MetadataController';
import ProjectController from './controllers/ProjectController';
import MapController from './controllers/MapController';
import UserInfoController from './controllers/UserInfoController';
import SearchController from './controllers/SearchController';
import PopupController from './controllers/PopupController';
import DataVisualizationController from './controllers/DataVisualizationController';
import DataClassificationController from './controllers/DataClassificationController';
import ShareController from './controllers/ShareController';
import ExportController from './controllers/ExportController';
import UploadController from './controllers/UploadController';
import ReportController from './controllers/ReportController';
import MaskController from './controllers/MaskController';
import DataFilterController from './controllers/DataFilterController';
import ApplicationModeController from './controllers/ApplicationModeController';
import InfoBubbleModeController from './controllers/InfoBubbleModeController';
import UserDataUploadController from './controllers/UserDataUploadController';
import HelpController from './controllers/HelpTourController';
import MapVisualizationController from './controllers/MapVisualizationController';
import ImportController from './controllers/ImportController';
import SessionLoggerController from './controllers/SessionLoggerController';
import LayerLibraryController from './controllers/LayerLibraryController';
import ChangeOverTimeController from './controllers/ChangeOverTimeController';
import CustomMapSelectionController from './controllers/CustomMapSelectionController';
import UserSettingsController from './controllers/UserSettingsController';
import OverrideController from './controllers/OverrideController';
import VisualReportsController from './controllers/VisualReportsController';
import PointsController from './controllers/PointsController';
import Mixpanel from './helpers/Mixpanel';

class App extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            errorMessage: undefined,
            additionalErrorMessage: undefined,
        };

        this._bus = new EventEmiter();
        this._controllers = {};
        this._dataSources = {};

        this.md = new MobileDetect(window.navigator.userAgent);
    }

    getChildContext() {
        const {
            viewCode,
            container,
            appGuid,
            frame,
            variable,
            location,
            dataBrowser,
            locationAnalysis,
            visualReport,
        } = this.props.options;

        return {
            viewCode,
            container,
            appGuid,
            bus: this.bus,
            isCondensedLayout: this.state.isCondensedLayout,
            isPhoneDevice: this.md.phone() !== null,
            isMobileDevice: this.md.mobile() !== null,
            isIframe: window.self !== window.top,
            isEmbedded: this.state.applicationMode === ApplicationMode.EMBED,
            applicationMode: this.state.applicationMode,
            onError: this.handleError,
            frame,
            variable,
            location,
            locationAnalysis,
            dataBrowser,
            visualReport,
        };
    }

    componentWillMount() {
        let controllers;
        if (this.props.options.mode === ApplicationMode.EMBED) {
            controllers = [
                ErrorController,
                MetadataController,
                MapController,
                ProjectController,
                SearchController,
                DataVisualizationController,
                DataClassificationController,
                ApplicationModeController,
                InfoBubbleModeController,
                MapVisualizationController,
                ShareController,
                PopupController,
                SessionLoggerController,
                LayerLibraryController,
                DataFilterController,
                ChangeOverTimeController,
                UserSettingsController,
                CustomMapSelectionController,
                PointsController,
            ];
        } else {
            controllers = [
                ErrorController,
                MetadataController,
                MapController,
                ProjectController,
                UserInfoController,
                PopupController,
                SearchController,
                DataVisualizationController,
                DataClassificationController,
                ShareController,
                ExportController,
                ReportController,
                MaskController,
                DataFilterController,
                UploadController,
                ApplicationModeController,
                InfoBubbleModeController,
                UserDataUploadController,
                HelpController,
                MapVisualizationController,
                ImportController,
                SessionLoggerController,
                LayerLibraryController,
                ChangeOverTimeController,
                UserSettingsController,
                CustomMapSelectionController,
                VisualReportsController,
                PointsController,
            ];
        }

        this.activateControllers(controllers);

        // Activate override controller
        if (this.props.options.mode !== ApplicationMode.EMBED) {
            this.activateOverrideController();
        }

        this.bus.on('APPLICATION_MODE_UPDATE_SUCCESS', this.handleApplicationModeUpdateSuccess);
        this.bus.on('FRAME_LAYOUT_CHANGE', this.handleFrameLayoutChange);
    }

    componentDidMount() {
        this.bus.emit('APPLICATION_MODE_UPDATE_REQUEST', {
            applicationMode: this.props.options.mode,
        });

        document.addEventListener('keyup', this.handleTabPress);
        document.addEventListener('click', this.handleClicking);

        // init mixpanel
        Mixpanel.init();
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleTabPress);
        document.removeEventListener('click', this.handleClicking);
        this.deactivateControllers();
    }

    handleClicking() {
        if (!event.detail) {
            // event.detail property holds the amount of clicks inside the event
            // Therefore, if a click is produced by react and not by the user - do not enable clicking mode
            return;
        }

        const bodyElement = document.querySelector('body');
        bodyElement.classList.add('clicking-mode');
    }

    handleTabPress() {
        if (event.key === 'Tab') {
            const bodyElement = document.querySelector('body');
            bodyElement.classList.remove('clicking-mode');
        }
    }

    activateControllers(controllers) {
        this._activeControllers = controllers;
        this._activeControllers.forEach(controller => {
            let appController = this._controllers[controller.name];
            if (!appController) {
                appController = controller.getInstance({
                    bus: this._bus,
                    activateSource: this.activateSource,
                });
                this._controllers[appController.name] = appController;
            }

            appController.activate();
        });
    }

    activateOverrideController = () => {
        this._activeControllers.push(OverrideController);
        const appController = OverrideController.getInstance({
            bus: this._bus,
            activateSource: this.activateSource,
            props: this.getChildContext(),
        });
        this._controllers[OverrideController.name] = appController;
        console.log(this._controllers);
        appController.activate();
    };

    deactivateControllers() {
        if (!Array.isArray(this._activeControllers)) {
            console.warn('No controllers have been activated.');
        } else {
            this._activeControllers.forEach(controller =>
                this.context.deactivateController(controller),
            );
        }
    }

    activateController = controller => {
        let appController = this._controllers[controller.name];
        if (!appController) {
            appController = controller.getInstance(this.getChildContext().options);
            this._controllers[appController.name] = appController;
        }

        appController.activate();
        return appController;
    };

    deactivateController = controller => {
        const appController = this._controllers[controller.name];
        if (appController) {
            appController.deactivate();
        }
    };

    activateSource = source => {
        let appSource = this._dataSources[source.name];
        if (!appSource) {
            appSource = source.getInstance();
            this._dataSources[source.name] = appSource;
        }
        return appSource;
    };

    handleFrameLayoutChange = ({ isCondensedLayout }) => {
        this.setState({ isCondensedLayout });
    };

    handleApplicationModeUpdateSuccess = applicationMode => {
        this.setState({ applicationMode });
    };

    handleError = (errorMessage, additionalErrorMessage) => {
        this.setState({ errorMessage, additionalErrorMessage });
    };

    get bus() {
        return this._bus;
    }

    render() {
        if (!this.state.applicationMode) return null;

        if (!dragonfly.supported()) {
            return <UnsupportedPage />;
        }
        if (!this.props.options.viewCode) {
            return <ErrorPage additionalErrorMessage="Default project view code not found" />;
        }
        if (this.state.errorMessage) {
            return (
                <ErrorPage
                    errorMessage={this.state.errorMessage}
                    additionalErrorMessage={this.state.additionalErrorMessage}
                />
            );
        }

        return (
            <div id="root-container">
                <IntlProvider locale={locale} messages={messages}>
                    <ExplorePage />
                </IntlProvider>
            </div>
        );
    }
}

App.propTypes = {
    options: PropTypes.object.isRequired,
};

App.childContextTypes = {
    viewCode: PropTypes.string,
    appGuid: PropTypes.string,
    container: PropTypes.string,
    bus: PropTypes.object,
    isPhoneDevice: PropTypes.bool,
    isMobileDevice: PropTypes.bool,
    isCondensedLayout: PropTypes.bool,
    isIframe: PropTypes.bool,
    isEmbedded: PropTypes.bool,
    applicationMode: PropTypes.string,
    onError: PropTypes.func,
    frame: PropTypes.string,
    variable: PropTypes.object,
    location: PropTypes.object,
    locationAnalysis: PropTypes.object,
    dataBrowser: PropTypes.object,
    visualReport: PropTypes.string,
};

export default App;

import BaseController from './BaseController';

import VisualReportDataSource from '../dataSources/VisualReportDataSource';

class VisualReportsController extends BaseController {
    static get name() {
        return 'VisualReportsController';
    }

    static getInstance(options) {
        return new VisualReportsController(options);
    }

    onActivate() {
        this.bindGluBusEvents({
            VISUAL_REPORTS_REQUEST: this.onVisualReportsRequest,
            VISUAL_REPORT_SAVE_REQUEST: this.onVisualReportSaveRequest,
            VISUAL_REPORT_UPDATE_REQUEST: this.onVisualReportUpdateRequest,
            VISUAL_REPORT_DELETE_REQUEST: this.onVisualReportDeleteRequest,
        });

        this.visualReportDataSource = this.activateSource(VisualReportDataSource);
    }

    /**
     * @param {object} param0
     * @param {import('../types').VisualReport} param0.visualReport
     */
    onVisualReportSaveRequest = ({ visualReport }) => {
        this.visualReportDataSource.saveVisualReport(visualReport).then(
            vReport => {
                this.bus.emit('VISUAL_REPORT_SAVE_SUCCESS', {
                    visualReports: this.visualReportDataSource.visualReports,
                });
            },
            error => console.error(error),
        );
    };
    
    /**
     * @param {object} param0
     * @param {string} param0.title
     * @param {number} param0.reportId
     */
    onVisualReportUpdateRequest = ({ title, reportId }) => {
        this.visualReportDataSource
            .updateVisualReport({
                title,
                reportId,
            })
            .then(
                () => {
                    this.bus.emit('VISUAL_REPORT_UPDATE_SUCCESS', {
                        visualReports: this.visualReportDataSource.visualReports,
                    });
                },
                error => console.error(error),
            );
    };

    /**
     * @param {number} reportId
     */
    onVisualReportDeleteRequest = reportId => {
        this.visualReportDataSource.deleteVisualReport(reportId).then(
            () => {
                this.bus.emit('VISUAL_REPORT_DELETE_SUCCESS', {
                    visualReports: this.visualReportDataSource.visualReports,
                });
            },
            error => console.error(error),
        );
    };

    onVisualReportsRequest = () => {
        this.bus.emit('VISUAL_REPORTS', {
            visualReports: this.visualReportDataSource.visualReports,
        });
    };


    onDeactivate() {
        this.unbindGluBusEvents();
    }
}

export default VisualReportsController;

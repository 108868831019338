import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import dragonfly from 'dragonfly-v3';
import BaseHandler from './BaseHandler';
import { parseFieldName } from '../../helpers/Util';
import InfoBubbleMode from '../../enums/InfoBubbleMode';

import InfoBubbleLibraryLayer from '../../components/infoBubble/InfoBubbleLibraryLayer';
import InfoBubbleUserData from '../../components/infoBubble/InfoBubbleUserData';
import InfoBubblePointFeature from '../../components/infoBubble/InfoBubblePointFeature';
import messages, { locale } from '../../languages/languageConfig';
import { IntlProvider } from 'react-intl';

const POPUP_OFFSET = 20;

class InteractivityHandler extends BaseHandler {
    /** @param {import('../../components/MapViewer').default} mapViewer */
    constructor(mapViewer) {
        super(mapViewer);
        this._data = mapViewer.dragonflyMapData;
        this._isMapLoaded = this.map.loaded();

        this._highlightedFeatures = [];
        this._clickedFeatures = [];
        this._hoveredSearchMarker = undefined;
        this._hoveredUserDataFeature = undefined;
        this._hoveredLibraryLayerDataFeature = undefined;
        this._hoveredPointFeatures = undefined;
        this._lastFeaturesCheckPoint = undefined;
        this._featuresHoverHandler = undefined;

        /** @type {import('mapbox-gl').Popup} */
        this._userDataPopup = new dragonfly.Popup({ closeOnClick: false, closeButton: false });
        /** @type {import('mapbox-gl').Popup} */
        this._libraryLayersDataPopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: false,
        });
        /** @type {import('mapbox-gl').Popup} */
        this._pointFeaturePopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: true,
            offset: POPUP_OFFSET,
        });

        this._feature = null;
        // GeoJson point feature
        /** @type {import('../../../../').PointsPopupField[]} */
        this._pointFeaturePopupFields = [];
        this._isUserStarterTier = false;

        this.setGluBusEvents({
            MAP_FEATURES_ROLL_OVER: this.highlightMirroredFeatures,
            MAP_FEATURES_ROLL_OUT: this.onMouseOut,
            MAP_MOUSE_MOVE: this.onHighlightHoveredFeatures,
            MAP_MOUSE_MOVE_CLICK_MODE: this.onSimpleHighlightHoveredFeatures,
            MAP_MOUSE_CLICK: this.onHighlightClickedFeatures,
            MAP_MOUSE_OUT: this.onMouseOut,
            CLEAR_HIGHLIGHTED_FEATURES: this.clearHighlightedFeatures,
            MAP_LOAD: this.onMapLoad,
            INFO_BUBBLE_MODE_UPDATE_SUCCESS: this.onSetInfoBubbleMode,
            HANDLE_POINT_FEATURE_POPUP_SELECT: this.handlePointFeaturePopupSelect,
            SHOW_SEARCHED_ITEM_POPUP: this.onShowLocationAnalysisPopup,
        });

        this.bus.once('CURRENT_INFO_BUBBLE_MODE', this.onSetInfoBubbleMode);
        this.emit('CURRENT_INFO_BUBBLE_MODE_REQUEST');
        this.bus.once('POINTS_POPUP_FIELDS', this.onPointsPopupFields);
        this.emit('POINTS_POPUP_FIELDS_REQUEST');
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    onUserInfo = (userInfo, source) => {
        if (source && this !== source) return;
        
        this._isUserStarterTier = userInfo && userInfo.isStarter();
    };

    onSetInfoBubbleMode = ({ infoBubbleMode }) => {
        this.infoBubbleMode = infoBubbleMode;
        this._userDataPopup.remove();
        this._libraryLayersDataPopup.remove();
        this._pointFeaturePopup.remove();

        this._userDataPopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: infoBubbleMode === InfoBubbleMode.CLICK,
        });
        this._libraryLayersDataPopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: infoBubbleMode === InfoBubbleMode.CLICK,
        });
        this._pointFeaturePopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: infoBubbleMode === InfoBubbleMode.CLICK,
            offset: POPUP_OFFSET,
        });
    };

    onPointsPopupFields = ({ popupFields }) => {
        this._pointFeaturePopupFields = popupFields;
    };

    remove() {
        if (this._featuresHoverHandler) {
            clearTimeout(this._featuresHoverHandler);
        }
        this._userDataPopup.remove();
        this._libraryLayersDataPopup.remove();
        this._pointFeaturePopup.remove();
    }

    get activeSummaryLevel() {
        if (this._data.mapInstanceData.preferredSummaryLevel)
            return this._data.mapInstanceData.preferredSummaryLevel;
        return this.mapConfig.getSummaryLevelOnZoom(this.map.getZoom());
    }

    get highlightedFeatures() {
        return this._highlightedFeatures;
    }

    get clickedFeatures() {
        return this._clickedFeatures;
    }

    onMapLoad(e) {
        if (e.source.id !== this.mapInstanceId) return;
        this._isMapLoaded = true;
    }

    onMouseOut(e) {
        if (
            e.mapInstanceId === this.mapInstanceId ||
            e.isMimicking ||
            (this.highlightedFeatures.length === 0 && this.clickedFeatures.length === 0)
        )
            return;
        this.clearHighlightedFeatures(e);
        this.emit('MAP_FEATURES_ROLL_OUT', {
            source: this.mapViewer,
            originalEvent: e,
            isMimicking: true,
        });
    }

    // Highlight hovered features in HOVER mode
    onHighlightHoveredFeatures = e => {
        if (e.source.id !== this.mapInstanceId || !e.interactive) return;

        if (e.originalEvent.point) {
            this._lastFeaturesCheckPoint = e.originalEvent.point.clone();
        }

        // When MAP_MOUSE_MOVE event is emitted on MAP_ZOOM last registered feature point may not be set
        // In that case skip further execution
        if (!this._lastFeaturesCheckPoint) return;
        if (this._featuresHoverHandler) return;

        this._featuresHoverHandler = setTimeout(() => {
            this.highlightFeatures(e.originalEvent, this.highlightedFeatures, InfoBubbleMode.HOVER);
            this._featuresHoverHandler = undefined;
        }, 5);
    };

    // Highlight clicked features ( when in CLICK mode)
    onHighlightClickedFeatures = e => {
        if (e.source.id !== this.mapInstanceId || !e.interactive) return;

        if (e.originalEvent.point) {
            this._lastFeaturesCheckPoint = e.originalEvent.point.clone();
        }
        this.highlightFeatures(e.originalEvent, this.clickedFeatures, InfoBubbleMode.CLICK);
    };

    // Highlight features without showing the info bubble
    onSimpleHighlightHoveredFeatures = e => {
        if (e.source.id !== this.mapInstanceId || !e.interactive) return;

        if (e.originalEvent.point) {
            this._lastFeaturesCheckPoint = e.originalEvent.point.clone();
        }

        // When MAP_MOUSE_MOVE event is emitted on MAP_ZOOM last registered feature point may not be set
        // In that case skip further execution
        if (!this._lastFeaturesCheckPoint) return;
        if (this._featuresHoverHandler) return;

        this._featuresHoverHandler = setTimeout(() => {
            this.highlightOtherFeatures(e.originalEvent);
            this._featuresHoverHandler = undefined;
        }, 5);
    };

    clearHighlightedFeatures(e) {
        if (
            e.mapInstanceId === this.mapInstanceId ||
            e.isMimicking ||
            (this.highlightedFeatures.length === 0 && this.clickedFeatures.length === 0)
        )
            return;
        this._highlightedFeatures = [];
        this._clickedFeatures = [];
        this.map.painter.clearFeaturesStyles();
    }

    // highlight the features that the user clicked or hovered on and show the info bubble
    highlightFeatures(e, selectedFeatures, infoBubbleMode) {
        if (e.point) {
            this._lastFeaturesCheckPoint = e.point.clone();
        }

        const filterLayers = this._data.mapInstanceData.rendererData.layers.map(dl => dl.id);
        // add points (geoJson) layers to filterLayers
        filterLayers.push(this._data.geoJsonLayersData.pointsLayerId);

        // add user layer to filter layer if there are any on the map
        this._data.userDataLayersData.layers.forEach(dl => {
            if (dl.metadata.userData && dl.metadata.userData.interactive) {
                filterLayers.push(dl.id);
            }
        });

        // For each specified interactive layer group add layers to features query filter
        this.mapInstance.libraryDataLayers.forEach(layerGroup => {
            if (layerGroup.metadata.interactivity) {
                layerGroup.layersDefinition.forEach(layer => {
                    const layerMetadata = layer.metadata.socialexplorer;
                    if (layerMetadata.interactivity && layerMetadata.interactivity.enabled) {
                        filterLayers.push(layer.id);
                    }
                });
            }
        });

        // retrieve hovered features
        const featuresUnderMouse = this.map.queryRenderedFeatures(this._lastFeaturesCheckPoint, {
            layers: filterLayers,
        });

        // handle hovered user layers data
        this._handleHoveredUserDataLayersFeatures(e, featuresUnderMouse, infoBubbleMode);

        // handle library layers data features
        this._handleHoveredLibraryLayersFeatures(e, featuresUnderMouse, infoBubbleMode);

        // handle point data (geoJson) features
        this._handleHoveredPointFeatures(featuresUnderMouse);

        // handle hovered data features
        this._handleSelectedDataFeatures(e, featuresUnderMouse, selectedFeatures, infoBubbleMode);
    }

    // when in click mode we still want to highlight the features on the map without effect on info bubble
    highlightOtherFeatures(e) {
        if (e.point) {
            this._lastFeaturesCheckPoint = e.point.clone();
        }

        const filterLayers = this._data.mapInstanceData.rendererData.layers.map(dl => dl.id);

        this._data.userDataLayersData.layers.forEach(dl => {
            if (dl.metadata.userData && dl.metadata.userData.interactive) {
                filterLayers.push(dl.id);
            }
        });
        // retrieve hovered features
        const featuresUnderMouse = this.map.queryRenderedFeatures(this._lastFeaturesCheckPoint, {
            layers: filterLayers,
        });

        // handle hovered user layers data should be implemented

        // handle hovered data features
        this._handleOtherHoveredDataFeatures(e, featuresUnderMouse);
    }

    // For the side by side map mirror the highlighted feature
    highlightMirroredFeatures(e) {
        // do not mirror features if:
        // this map viewer is the one that sent the feature roll over event
        // event is already a result of mirroring features
        // map viewers are showing different summary levels
        if (
            e.source.id === this.mapInstanceId ||
            e.isMimicking ||
            !this.activeSummaryLevel ||
            !e.source.activeSummaryLevel ||
            this.activeSummaryLevel.id !== e.source.activeSummaryLevel.id ||
            !this._isMapLoaded
        ) {
            return;
        }
        // Depending on the info bubble mode mirroring will affect the clicked or highlighted features array
        const currentFeatures =
            e.infoBubbleMode === InfoBubbleMode.HOVER
                ? this.highlightedFeatures
                : this.clickedFeatures;
        const oldHighlightedFeatures = [...currentFeatures];
        if (e.features) {
            this._highlightMirrorFeaturesData(e.features, currentFeatures);
        }

        if (currentFeatures.length === 0 && oldHighlightedFeatures.length > 0) {
            this.map.painter.clearFeaturesStyles();
            this.emit('MAP_FEATURES_ROLL_OUT', {
                source: this.mapViewer,
                originalEvent: e,
                isMimicking: true,
            });
            return;
        }

        if (currentFeatures.length === 0) return;
        this.emit('MAP_FEATURES_ROLL_OVER', {
            source: this.mapViewer,
            originalEvent: e,
            features: currentFeatures,
            isMimicking: true,
        });
    }

    onOpenPointInfo = (newLocationAnalysisItem, visualReportProps) => {
        this._pointFeaturePopup.remove();
        delete this._hoveredPointFeatures;
        this.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
            selectedItem: newLocationAnalysisItem,
            mapInstanceId: this.mapInstance.id,
            showInsights: true,
            visualReportProps,
        });
    };

    /**
     * @param {import('mapbox-gl').MapMouseEvent} e
     * @param {import('../../').UserLayerFeature[]} features
     * @param {'HOVER'|'CLICK'} infoBubbleMode
     */
    _handleHoveredUserDataLayersFeatures(e, features, infoBubbleMode) {
        const hoveredUserDataMarkers = features.filter(
            feature =>
                feature.layer && feature.layer.metadata && feature.layer.metadata.isUserDataLayer,
            // feature.layer && feature.layer.source === this._data.userDataLayersData.source.id,
        );

        if (hoveredUserDataMarkers.length) {
            const hoveredUserDataMarker = hoveredUserDataMarkers[0];

            this._hoveredUserDataFeature = hoveredUserDataMarker;
            const userDataLayer = this.mapInstance.userDataLayers.find(
                udl =>
                    udl.id === this._hoveredUserDataFeature.layer.metadata.userData.userDataLayerId,
            );

            // switch (userDataLayer.styleRules[0].type) {
            //     case 'symbol':
            //         hoveredUserDataMarker.layer = this.map.getLayer(
            //             `${this._hoveredUserDataFeature.layer.id}__highlight-layer`,
            //         );
            //         this.map.painter.setFeaturesStyles(
            //             [hoveredUserDataMarker, hoveredUserDataMarker],
            //             [
            //                 {
            //                     'bubble-max-radius': 100,
            //                     'bubble-outline-color': 'rgba(255, 255, 255, 0.75)',
            //                 },
            //                 { 'bubble-max-radius-outline-color': 'rgba(100, 100, 100, 0.5)' },
            //             ],
            //         );
            //         break;
            //     case 'shaded-bubble':
            //     case 'single-color-bubble':
            //         this.map.painter.setFeaturesStyles(
            //             [hoveredUserDataMarker, hoveredUserDataMarker],
            //             [
            //                 { 'bubble-outline-color': 'rgba(255, 255, 255, 0.75)' },
            //                 { 'bubble-max-radius-outline-color': 'rgba(255, 255, 255, 0.75)' },
            //             ],
            //         );
            //         break;
            // }
            hoveredUserDataMarker.layer = this.map.getLayer(
                `${this._hoveredUserDataFeature.layer.id}__highlight-layer`,
            );
            this.map.painter.setFeaturesStyles(
                [hoveredUserDataMarker, hoveredUserDataMarker],
                [
                    {
                        'bubble-max-radius': 100,
                        'bubble-outline-color': 'rgba(255, 255, 255, 0.75)',
                    },
                    { 'bubble-max-radius-outline-color': 'rgba(100, 100, 100, 0.5)' },
                ],
            );

            // generate the feature tooltip html and display a dragonfly popup and make the cursor a 'pointer'. Show popup.
            this.map.getCanvas().style.cursor = 'pointer';
            // generate tooltip html content

            /** @type {string} */
            const html = ReactDOMServer.renderToString(
                <InfoBubbleUserData
                    featureProperties={this._hoveredUserDataFeature.properties}
                    titleColumn={userDataLayer.popupTitleColumn}
                    dataColumns={userDataLayer.popupOtherColumns}
                    isClickMode={infoBubbleMode === InfoBubbleMode.CLICK}
                />,
            );
            this._userDataPopup
                .setLngLat(this._hoveredUserDataFeature.geometry.coordinates)
                .setHTML(html)
                .addTo(this.map);
            this._userDataPopup._container.classList.add('user-data-popup-container');
        } else if (this._hoveredUserDataFeature) {
            this.map.painter.clearFeaturesStyles();
            this.map.getCanvas().style.cursor = '';
            this._userDataPopup.remove();
            delete this._hoveredUserDataFeature;
        }
    }

    _handleSelectedDataFeatures(e, features, selectedFeatures, infoBubbleMode) {
        // only if hovered features do not contain hovered search data, library data or point features continue to highlight data features
        const otherFeaturesHovered =
            !this._hoveredUserDataFeature &&
            !this._hoveredLibraryLayerDataFeature &&
            !this._hoveredPointFeatures;
        let dataFeatures = otherFeaturesHovered
            ? features.filter(
                  f =>
                      //   f.layer.source !==
                      //       this._data.searchData.markersSourceId &&
                      f.layer.source !== this._data.searchData.geometriesSourceId,
              )
            : [];

        if (dataFeatures.length === 0 && selectedFeatures.length > 0) {
            this.map.painter.clearFeaturesStyles();
            // this._highlightedFeatures = [];
            selectedFeatures.splice(0, selectedFeatures.length);
            this.emit('MAP_FEATURES_ROLL_OUT', {
                source: this.mapViewer,
                originalEvent: e,
            });
            return;
        }

        if (dataFeatures.length === 0) {
            this.emit('MAP_FEATURES_NONE', {
                source: this.mapViewer,
                originalEvent: e,
            });
            return;
        }
        // add computed values
        dataFeatures.forEach(feature => {
            this.mapInstance.dataTheme.rendering[0].fieldList.calculateComputed(feature.properties);
        });

        let allFeaturesAlreadyHighlighted = true,
            higlightLayerFeature;
        const featuresToHighlight = [],
            featuresStyles = [];

        // if multiple bubbles are overlapping we need to highlight the geography of the smallest bubble
        if (this.mapInstance.dataTheme.isBubblesVisualization) {
            let smallestBubbleFeature;
            // since bubble is interactive, found feature can be bubble highlight or geography highlight
            // if found feature is bubble highlight, find corresponding geography highlight
            if (dataFeatures[0].layer.type === 'bubble') {
                smallestBubbleFeature = dataFeatures[0];
                // if we have more that one feature under the mouse
                // find feature that corresponds to the smallest bubble
                if (dataFeatures.length > 1) {
                    const bubbleSizePropertyName = parseFieldName(
                        this.mapInstance.dataTheme.rendering[0].bubbleSizeFieldName,
                    ).variableGuid;
                    smallestBubbleFeature = dataFeatures.sort(
                        (a, b) =>
                            a.properties[bubbleSizePropertyName] >
                            b.properties[bubbleSizePropertyName],
                    )[0];
                }
                dataFeatures = [smallestBubbleFeature];
                higlightLayerFeature = Object.assign({}, smallestBubbleFeature);
                higlightLayerFeature.layer = this.map.getLayer(
                    this._data.mapInstanceData.highlightLayer.id,
                );
            } else {
                higlightLayerFeature = dataFeatures[0];
                smallestBubbleFeature = Object.assign({}, higlightLayerFeature);
                dataFeatures = [higlightLayerFeature];
            }
            smallestBubbleFeature = Object.assign({}, smallestBubbleFeature);
            smallestBubbleFeature.layer = this.map.getLayer(
                this._data.mapInstanceData.dataLayers[0].id,
            );
            this._createBubbleHighlightFeatureStyles(
                featuresToHighlight,
                featuresStyles,
                smallestBubbleFeature,
            );
        } else {
            // only take the first feature for now
            higlightLayerFeature = dataFeatures[0];
            dataFeatures = [dataFeatures[0]];
        }

        this._createLineHighlightFeatureStyle(
            featuresToHighlight,
            featuresStyles,
            higlightLayerFeature,
        );

        dataFeatures.forEach(f => {
            if (selectedFeatures.findIndex(hf => hf.id === f.id) === -1) {
                allFeaturesAlreadyHighlighted = false;
            }
        });

        if (allFeaturesAlreadyHighlighted) {
            return;
        }

        this.map.painter.setFeaturesStyles(featuresToHighlight, featuresStyles);

        selectedFeatures.splice(0, selectedFeatures.length);
        selectedFeatures.push(...dataFeatures);
        this.emit('MAP_FEATURES_ROLL_OVER', {
            source: this.mapViewer,
            originalEvent: e,
            lngLat: this.map.unproject(this._lastFeaturesCheckPoint),
            features: selectedFeatures,
            infoBubbleMode,
        });
    }

    /**
     * @param {import('mapbox-gl').MapMouseEvent} e
     * @param {import('../../').LayerLibraryFeature[]} features
     */
    _handleHoveredLibraryLayersFeatures(e, features) {
        const hoveredLibraryLayersData = features.filter(
            feature => feature.layer.source === this._data.libraryLayersData.source.id,
        );

        if (hoveredLibraryLayersData.length) {
            this._hoveredLibraryLayerDataFeature = hoveredLibraryLayersData[0];

            // generate the feature tooltip html and display a dragonfly popup and make the cursor a 'pointer'. Show popup.
            this.map.getCanvas().style.cursor = 'pointer';
            // generate tooltip html content
            const { titleColumn, dataColumns, tooltipColumns } =
                this._hoveredLibraryLayerDataFeature.layer.metadata.socialexplorer.interactivity;

            /**
             * @type {[number, number] | [number, number][]}
             * Coordinates example:
             * Single point => [71.5554, 58.8888]
             * Multiple points => [[71.5554, 58.8888], [71.6554, 58.9888], [71.5854, 58.8788]]
             * Multiple coordinate points exist on line like geographies, and single for point like geographies
             */
            const coordinates = this._hoveredLibraryLayerDataFeature.geometry.coordinates;
            let popupCoordinates;
            // If coordinates array contains multiple points use the
            // current mouse point coordinates to show the tooltip
            if (Array.isArray(coordinates[0])) {
                // In some cases the event does not contain lngLat information
                // like example in case of the zoom event.
                // When this case happens we will do nothing :)
                if (!e.lngLat) {
                    return;
                }
                popupCoordinates = [e.lngLat.lng, e.lngLat.lat];
            } else {
                // Otherwise use the geo coordinates
                popupCoordinates = coordinates;
            }

            const { libraryDataLayers } = this.mapInstance;
            const layerId = this._hoveredLibraryLayerDataFeature.layer.id;
            const libraryGroup = libraryDataLayers.find(lg =>
                lg.layersDefinition.find(ld => ld.id === layerId),
            );
            let surveyCode;
            if (libraryGroup.metadata && libraryGroup.metadata.report) {
                surveyCode = libraryGroup.metadata.report.surveyCode;
            }

            const popupNode = document.createElement('div');
            ReactDOM.render(
                <IntlProvider locale={locale} messages={messages} >
                    <InfoBubbleLibraryLayer
                        featureId={this._hoveredLibraryLayerDataFeature.id.toString()}
                        featureProperties={this._hoveredLibraryLayerDataFeature.properties}
                        titleColumn={titleColumn}
                        dataColumns={dataColumns}
                        tooltipColumns={tooltipColumns}
                        locationAnalysisItem={this.mapInstance.locationAnalysisItem}
                        surveyCode={surveyCode}
                        point={{ lng: popupCoordinates[0], lat: popupCoordinates[1] }}
                        suggestEditFormClickBack={payload => {
                            this.emit('SUBMIT_EDITED_FACILITY_POPUP_REQUEST', payload);
                        }}
                        openMoreInfoClickBack={(newLocationAnalysisItem, visualReportProps) => {
                            this._libraryLayersDataPopup.remove();
                            delete this._hoveredLibraryLayerDataFeature;

                            this.emit('ENTER_UPDATE_LOCATION_ANALYSIS_MODE', {
                                selectedItem: newLocationAnalysisItem,
                                mapInstanceId: this.mapInstance.id,
                                showInsights: true,
                                visualReportProps,
                            });
                        }}
                        isContentBlurred={this._isUserStarterTier}
                    />
                </IntlProvider>,
                popupNode,
            );

            this._libraryLayersDataPopup
                .setLngLat(popupCoordinates)
                .setDOMContent(popupNode)
                .addTo(this.map);

            // Shared style for school data and user data popup
            this._libraryLayersDataPopup._container.classList.add('layers-popup-container');
        } else if (this._hoveredLibraryLayerDataFeature) {
            this.map.painter.clearFeaturesStyles();
            this.map.getCanvas().style.cursor = '';
            this._libraryLayersDataPopup.remove();
            delete this._hoveredLibraryLayerDataFeature;
        }
    }

    _handleHoveredPointFeatures(features) {
        const pointFeatures = features.filter(
            feature => feature.layer.source === this._data.geoJsonLayersData.sourceId,
        );
        const idProperty = this._data.geoJsonLayersData.idProperty;
        const locationAnalysisItem = this.mapInstance.locationAnalysisItem;
        /**
         * isOpenFromSearchBox is used to determine if user hovered over facility
         * that is the same as one openned from search box. If it is, our new popup
         * should be ignored
         */
        let isOpenFromSearchBox = false;
        if (this._searchBoxPopup && locationAnalysisItem && this._searchBoxPopup.isOpen()) {
            isOpenFromSearchBox = !!pointFeatures.find(
                feature => feature.properties[idProperty] === locationAnalysisItem.id,
            );
        }
        if (pointFeatures.length) {
            this.map.getCanvas().style.cursor = 'pointer';
            this._hoveredPointFeatures = pointFeatures;
            if (isOpenFromSearchBox === false) {
                const popupNode = document.createElement('div');
                ReactDOM.render(
                    <IntlProvider locale={locale} messages={messages} >
                        <InfoBubblePointFeature
                            popupFields={this._pointFeaturePopupFields}
                            locationAnalysisItem={locationAnalysisItem}
                            features={this._hoveredPointFeatures}
                            onSelect={featureIndex => {
                                this.handlePointFeaturePopupSelect(featureIndex);
                            }}
                            onOpenPointInfo={this.onOpenPointInfo}
                            suggestEditFormClickBack={payload => {
                                this.emit('SUBMIT_EDITED_FACILITY_POPUP_REQUEST', payload);
                            }}
                            isContentBlurred={this._isUserStarterTier}
                        />
                    </IntlProvider>,
                    popupNode,
                );
                this._pointFeaturePopup
                    .setLngLat(this._hoveredPointFeatures[0].geometry.coordinates)
                    .setDOMContent(popupNode)
                    .addTo(this.map);
            }
        } else if (this._hoveredPointFeatures) {
            this._pointFeaturePopup.remove();
            this.map.getCanvas().style.cursor = '';
            delete this._hoveredPointFeatures;
        }
    }

    onShowLocationAnalysisPopup = () => {
        const locationAnalysisItem = this.mapInstance.locationAnalysisItem;
        // If a popup already exists, let's remove it
        if (this._searchBoxPopup) {
            this._searchBoxPopup.remove();
        }

        this._searchBoxPopup = new dragonfly.Popup({
            closeOnClick: false,
            closeButton: true,
            offset: POPUP_OFFSET,
        });

        const coordinates = [locationAnalysisItem.point.lng, locationAnalysisItem.point.lat];
        const locationMarkerNode = document.createElement('div');
        /**
         * We use switch case here to determine which popup should be
         * rendered. Now if you search for Points on map, you should
         * be presented with popup with all the info about that point.
         */
        switch (locationAnalysisItem.searchBoxOrigin) {
            case 'GEO': {
                ReactDOM.render(
                    <div className="marker-content">
                        <div className="marker-content__geo-heading">
                            <h4
                                className="marker-content__geo-type"
                                title={locationAnalysisItem._analysisTypeId}
                            >
                                {locationAnalysisItem.type.toUpperCase()}
                            </h4>
                            <button
                                className="dragonfly-popup-close-button"
                                type="button"
                                aria-label="Close popup"
                                onClick={() => {
                                    this._searchBoxPopup.remove();
                                }}
                            >
                                ×
                            </button>
                        </div>
                        <div className="marker-content__geo-name">
                            <h4 title={locationAnalysisItem._analysisTypeId}>
                                {locationAnalysisItem.value}
                            </h4>
                            <a
                                className="popup-google-map-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://www.google.com/maps/search/?api=1&query=${[
                                    ...coordinates,
                                ]
                                    .reverse()
                                    .join(',')}`}
                            >
                                Open in Google maps
                            </a>
                        </div>
                    </div>,
                    locationMarkerNode,
                );
                break;
            }
            case 'POINT': {
                // This info point bubble won't have the More info button
                // since we are already in the location analysis mode
                ReactDOM.render(
                    <IntlProvider locale={locale} messages={messages}>
                        <InfoBubblePointFeature
                            locationAnalysisItem={locationAnalysisItem}
                            popupFields={this._pointFeaturePopupFields}
                            features={[locationAnalysisItem.feature]}
                            onSelect={undefined}
                            onOpenPointInfo={this.onOpenPointInfo}
                            suggestEditFormClickBack={payload => {
                                this.emit('SUBMIT_EDITED_FACILITY_POPUP_REQUEST', payload);
                            }}
                            isContentBlurred={this._isUserStarterTier}
                        />
                    </IntlProvider>,
                    locationMarkerNode,
                );
                break;
            }
            default: {
                ReactDOM.render(
                    <div className="marker-content">
                        <div className="marker-content__heading">
                            <h5 title={locationAnalysisItem.value}>{locationAnalysisItem.value}</h5>
                            <button
                                className="dragonfly-popup-close-button"
                                type="button"
                                aria-label="Close popup"
                                onClick={() => {
                                    this._searchBoxPopup.remove();
                                }}
                            >
                                ×
                            </button>
                        </div>
                        <div className="marker-content__link">
                            <a
                                className="google-map-link"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://www.google.com/maps/search/?api=1&query=${[
                                    ...coordinates,
                                ]
                                    .reverse()
                                    .join(',')}`}
                            >
                                Open in Google maps
                            </a>
                        </div>
                    </div>,
                    locationMarkerNode,
                );
            }
        }

        this._searchBoxPopup
            .setLngLat([coordinates[0], coordinates[1]])
            .setDOMContent(locationMarkerNode)
            .addTo(this.map);
        // add popup class and ignore ts problem, since there is no different
        // way to add a class to the popup container in the mapbox/dragonfly
        // version that we use
        // @ts-ignore
        // this._searchBoxPopup._container.classList.add('user-location-popup');
    };

    handlePointFeaturePopupSelect(featureIndex) {
        this.emit('FEATURE_TO_LOCATION_ANALYSIS', {
            feature: this._hoveredPointFeatures[featureIndex],
            mapInstanceId: this.mapInstance.id,
        });
        this._pointFeaturePopup.remove();
        this.map.getCanvas().style.cursor = '';
        delete this._hoveredPointFeatures;
    }

    /**
     * Handle other hovered data features without activating the info bubble
     * @param {import('mapbox-gl').MapMouseEvent} e
     * @param {import('../../').LayerLibraryFeature[]} features
     */
    _handleOtherHoveredDataFeatures(e, features) {
        // only if hovered features do not contain hovered search data continue to highlight data features
        let dataFeatures = features.filter(
            f => f.layer.source !== this._data.searchData.geometriesSourceId,
        );

        // when a clicked feature exist but the mouse goes outside the map we should clear only the highlighted features
        // but the clicked feature as well as the info bubble should remain
        if (dataFeatures.length === 0 && this.highlightedFeatures.length > 0) {
            this.map.painter.clearFeaturesStyles();
            this._highlightedFeatures = [];

            if (this._clickedFeatures.length > 0) {
                const featuresToHighlight = [],
                    featuresStyles = [];
                this._createLineHighlightFeatureStyle(
                    featuresToHighlight,
                    featuresStyles,
                    this._clickedFeatures[0],
                );
                this.map.painter.setFeaturesStyles(featuresToHighlight, featuresStyles);
            }
            return;
        }

        if (dataFeatures.length === 0) {
            return;
        }

        // add computed values
        dataFeatures.forEach(feature => {
            this.mapInstance.dataTheme.rendering[0].fieldList.calculateComputed(feature.properties);
        });

        let allFeaturesAlreadyHighlighted = true,
            higlightLayerFeature;
        const featuresToHighlight = [],
            featuresStyles = [];

        // if multiple bubbles are overlapping we need to highlight the geography of the smallest bubble
        if (this.mapInstance.dataTheme.isBubblesVisualization) {
            let smallestBubbleFeature;
            // since bubble is interactive, found feature can be bubble highlight or geography highlight
            // if found feature is bubble highlight, find corresponding geography highlight
            if (dataFeatures[0].layer.type === 'bubble') {
                smallestBubbleFeature = dataFeatures[0];
                // if we have more that one feature under the mouse
                // find feature that corresponds to the smallest bubble
                if (dataFeatures.length > 1) {
                    const bubbleSizePropertyName = parseFieldName(
                        this.mapInstance.dataTheme.rendering[0].bubbleSizeFieldName,
                    ).variableGuid;
                    smallestBubbleFeature = dataFeatures.sort(
                        (a, b) =>
                            a.properties[bubbleSizePropertyName] >
                            b.properties[bubbleSizePropertyName],
                    )[0];
                }
                dataFeatures = [smallestBubbleFeature];
                higlightLayerFeature = Object.assign({}, smallestBubbleFeature);
                higlightLayerFeature.layer = this.map.getLayer(
                    this._data.mapInstanceData.highlightLayer.id,
                );
            } else {
                higlightLayerFeature = dataFeatures[0];
                smallestBubbleFeature = Object.assign({}, higlightLayerFeature);
                dataFeatures = [higlightLayerFeature];
            }
            smallestBubbleFeature = Object.assign({}, smallestBubbleFeature);
            smallestBubbleFeature.layer = this.map.getLayer(
                this._data.mapInstanceData.dataLayers[0].id,
            );
            this._createBubbleHighlightFeatureStyles(
                featuresToHighlight,
                featuresStyles,
                smallestBubbleFeature,
            );
        } else {
            // only take the first feature for now
            higlightLayerFeature = dataFeatures[0];
            dataFeatures = [dataFeatures[0]];
        }

        this._createLineHighlightFeatureStyle(
            featuresToHighlight,
            featuresStyles,
            higlightLayerFeature,
        );
        if (this.clickedFeatures.length > 0)
            this._createLineHighlightFeatureStyle(
                featuresToHighlight,
                featuresStyles,
                this._clickedFeatures[0],
            );

        dataFeatures.forEach(f => {
            if (this._highlightedFeatures.findIndex(hf => hf.id === f.id) === -1) {
                allFeaturesAlreadyHighlighted = false;
            }
        });

        if (allFeaturesAlreadyHighlighted) {
            return;
        }

        this.map.painter.setFeaturesStyles(featuresToHighlight, featuresStyles);

        this._highlightedFeatures = dataFeatures;
    }

    _highlightMirrorFeaturesData(mirrorFeatures, currentFeatures) {
        const dataDragonflySource = this._data.mapInstanceData.rendererData.source;
        if (
            !this.map ||
            !this.map.isStyleLoaded() ||
            !this.map.isSourceLoaded(dataDragonflySource.id)
        ) {
            return;
        }
        currentFeatures.splice(0, currentFeatures.length);
        const featuresToHighlight = [],
            featuresStyles = [];
        mirrorFeatures.forEach(mirrorFeature => {
            const featureFirstField = this.mapInstance.dataTheme.rendering[0].fieldList.fields[0];
            const activeSummaryLevel = this.activeSummaryLevel;
            this.mapConfig.getDataSourceForSummaryLevelId(activeSummaryLevel.id);
            const dataSource = this.mapConfig.getDataSourceForSummaryLevelId(activeSummaryLevel.id);
            const dataset = dataSource.findDataset(
                featureFirstField.surveyName,
                featureFirstField.datasetAbbreviation,
            );
            // find mirror feature for current event map feature
            const queriedFeatures = this.map.querySourceFeatures(
                this._data.mapInstanceData.rendererData.source.id,
                {
                    sourceLayer: dataSource.layerId,
                    filter: [
                        'all',
                        ['!=', dataset.primaryKeyField, undefined],
                        ['!=', dataset.primaryKeyField, ''],
                        [
                            '==',
                            dataset.primaryKeyField,
                            mirrorFeature.properties[dataset.primaryKeyField],
                        ],
                    ],
                },
            );
            queriedFeatures.forEach((feature, i) => {
                if (currentFeatures.findIndex(f => f.id === feature.id) === -1) {
                    queriedFeatures[i].layer = this.map.getLayer(
                        this._data.mapInstanceData.highlightLayer.id,
                    );
                    currentFeatures.push(feature);
                    this._createLineHighlightFeatureStyle(
                        featuresToHighlight,
                        featuresStyles,
                        feature,
                    );
                    if (this.mapInstance.dataTheme.isBubblesVisualization) {
                        const bubbleFeature = Object.assign({}, feature);
                        bubbleFeature.layer = this.map.getLayer(
                            this._data.mapInstanceData.dataLayers[0].id,
                        );
                        this._createBubbleHighlightFeatureStyles(
                            featuresToHighlight,
                            featuresStyles,
                            bubbleFeature,
                        );
                    }
                }
            });
        });
        if (currentFeatures.length > 0) {
            // add computed values
            currentFeatures.forEach(feature => {
                this.mapInstance.dataTheme.rendering[0].fieldList.calculateComputed(
                    feature.properties,
                );
            });
            this.map.painter.setFeaturesStyles(featuresToHighlight, featuresStyles);
        }
    }

    _createBubbleHighlightFeatureStyles(featuresToHighlight, featuresStyles, bubbleFeature) {
        featuresToHighlight.push(bubbleFeature);
        featuresStyles.push({
            'bubble-outline-color': 'rgba(255,255,255,0.7)',
            'bubble-max-radius-outline-color': 'rgba(255,255,255,0.7)',
            'bubble-opacity': 1,
        });
    }

    _createLineHighlightFeatureStyle(featuresToHighlight, featuresStyles, lineFeature) {
        featuresToHighlight.push(lineFeature);
        featuresStyles.push({
            'line-gap-width': 2,
            'line-color': 'rgba(180, 180, 180, 0.8)',
            'line-blur': 2,
            'line-width': 2,
        });
        featuresToHighlight.push(lineFeature);
        featuresStyles.push({
            'line-color': 'rgba(255, 255, 255, 1)',
        });
    }
}

export default InteractivityHandler;

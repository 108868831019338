import React from 'react';

import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import MapLayersEditor from '../mapLayersEditor/MapLayersEditor';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import AppConfig from '../../appConfig';
import MapControls from '../mapControls/MapControls';
import MapFiltersIndicator from '../mapIndicators/MapFiltersIndicator';

class MapEditorFrame extends BusComponent {
    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.MAP_EDITOR);
        AppConfig.sentryRecordEvent('entered map editor frame');
    }

    render() {
        const { mapInstance, isUserPro, initialView } = this.props;

        return (
            <div className="frame frame--layer-editing">
                <div className="frame__map">
                    <MapViewer
                        mapInstance={mapInstance}
                        annotations
                    />
                </div>
                <div className="frame__browser frame__browser--left">
                    <MapLayersEditor
                        mapInstance={mapInstance}
                        isUserPro={isUserPro}
                        initialView={initialView}
                    />
                </div>
                <div className="frame__components">
                    <div className="frame__components-block frame__components-block--top-right">
                        <MapFiltersIndicator
                            mapInstance={mapInstance}
                            applicationFrameType={ApplicationFrameType.MAP_EDITOR}
                        />
                    </div>
                    <div className="frame__components-block frame__components-block--bottom-right">
                        <MapControls mapInstance={mapInstance} />
                    </div>
                </div>
            </div>
        );
    }
}

export default MapEditorFrame;

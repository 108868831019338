// @ts-check
import React from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';

import BusComponent from '../BusComponent';
import { isEqualJson } from '../../helpers/Util';

/**
 * @typedef Props
 * @property {import('../../types').VisualReport} visualReport
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {boolean} saved
 * @property {import('../../types').VisualReport} visualReport
 * @property {import('../../types').VisualReport[]} param0.savedReports
 *
 * @extends {BusComponent<Props, State>}
 */
class VisualReportSave extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            saved: false,
            visualReport: props.visualReport,
            savedReports: [],
        };
    }

    componentDidMount() {
        this.bindGluBusEvents({
            VISUAL_REPORT_SAVE_SUCCESS: this.onSaveSuccess,
            SHOW_INSIGHTS: this.onReset,
            VISUAL_REPORTS: this.onRetrievedVisualReports,
        });
        this.emit('VISUAL_REPORTS_REQUEST');
    }

    componentWillReceiveProps(nextProps) {
        this.checkIfAlreadySaved(nextProps.visualReport, this.state.savedReports);
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
    }

    onRetrievedVisualReports = payload => {
        const savedReports = payload.visualReports;
        this.setState({ savedReports }, () => {
            this.checkIfAlreadySaved(this.props.visualReport, savedReports);
        });
    };

    /**
     * @param {import('../../types').VisualReport} visualReport
     * @param {import('../../types').VisualReport[]} savedReports
     */
    checkIfAlreadySaved = (visualReport, savedReports) => {
        let saved = false;
        if (visualReport.facilityId) {
            saved = savedReports.some(report => report.facilityId === visualReport.facilityId);
        }
        else {
            saved = savedReports.some(report => isEqualJson(report.point, visualReport.point));
        }

        this.setState({ saved });
    };

    onSaveSuccess = () => {
        this.setState({ saved: true });
    };

    onSave = () => {
        this.emit('VISUAL_REPORT_SAVE_POPUP_REQUEST', {
            visualReport: this.props.visualReport,
        });
    };

    onReset = visualReport => {
        // TODO: a bit unstable check, since JSON.stringify will return 2 different
        // strings for following objects: {a:1,b:2} and {b:2,a:1}
        if (
            JSON.stringify(this.state.visualReport) !==
            JSON.stringify(visualReport)
        ) {
            this.setState({ saved: false, visualReport });
        }
    };

    render() {
        const disabled = this.state.saved;
        const classes = classNames('btn-raised', {
            disabled,
        });

        return (
            <button
                className={classes}
                disabled={disabled}
                onClick={this.onSave}
            >
                {this.props.intl.formatMessage({
                    id: this.state.saved ? 'saved' : 'save',
                })}
            </button>
        );
    }
}

export default injectIntl(VisualReportSave);

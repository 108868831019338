import React from 'react';
import PropTypes from 'prop-types';

import AppConfig from '../../appConfig';
import LocationAnalysisItem from '../../objects/LocationAnalysisItem';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import * as SelfStoragePointType from '../../enums/SelfStoragePointType';

class InfoBubbleMoreDetails extends React.Component {
    onClick = () => {
        const {
            featureId,
            featureProperties,
            titleColumnId,
            point,
            surveyCode,
            onOpenMoreInfo,
        } = this.props;

        const pointType = SelfStoragePointType.resolvePointTypeFromSurveyCode(surveyCode);

        if (!pointType) {
            return;
        }

        const title = featureProperties[titleColumnId];

        const newLocationAnalysisItem = new LocationAnalysisItem({
            id: featureId,
            type: pointType,
            value: title,
            point,
            itemOrigin: LocationAnalysisItemOrigin.LIBRARY_LAYER_FEATURE,
            analysisTypeId: AppConfig.constants.defaultLocationAnalysisSelectionType,
            selection: new Set(
                AppConfig.constants.defaultLocationAnalysisSelection,
            ),
        });
        
        const visualReportProps = {
            report: 'selfstorage-point-info',
            pointType,
            facilityId: featureId,
        };

        onOpenMoreInfo(newLocationAnalysisItem, visualReportProps);
    };

    render() {
        const {
            surveyCode,
        } = this.props;

        const pointType = SelfStoragePointType.resolvePointTypeFromSurveyCode(surveyCode);

        if (!pointType) {
            return null;
        }

        return (
            <div className="footer">
                <button
                    className="btn btn-link"
                    onClick={this.onClick}
                >
                    More details
                </button>
            </div>
        );
    }
}

InfoBubbleMoreDetails.propTypes = {
    featureId: PropTypes.string.isRequired,
    featureProperties: PropTypes.object.isRequired,
    titleColumnId: PropTypes.string.isRequired,
    surveyCode: PropTypes.string,
    point: PropTypes.object.isRequired,
    onOpenMoreInfo: PropTypes.func.isRequired,
};

InfoBubbleMoreDetails.defaultProps = {
    surveyCode: null,
};

export default InfoBubbleMoreDetails;

// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import format from '../../helpers/NumberFormatter';
import Distance from '../distance/Distance';
import Time from '../distance/Time';
import SuggestEditFacilityButton from './SuggestEditFacilityButton';
import InfoBubbleMoreDetails from './InfoBubbleMoreDetails';
import InfoBubbleHeader from './InfoBubbleHeader';
import BlurOverlay from '../BlurOverlay';

const HIDDEN_COLUMNS_NAMES = ['Project Description']; // TODO this should be extracted to config or added to dingo-maps

/**
 * @param {object} param0
 * @param {object} [param0.featureProperties]
 * @param {string} param0.titleColumn
 * @param {string[]} param0.tooltipColumns
 * @param {import('../../').LayerLibraryInteractivityDataColumn[]} param0.dataColumns
 * @param {import('../../objects/LocationAnalysisItem').default} param0.locationAnalysisItem
 * @param {string} param0.featureId
 * @param {string} param0.surveyCode
 * @param {object} param0.point
 * @param {function} param0.suggestEditFormClickBack
 * @param {function} param0.openMoreInfoClickBack
 * @param {boolean} param0.isContentBlurred
 */
const InfoBubbleLibraryLayer = ({
    featureId,
    featureProperties,
    titleColumn,
    surveyCode,
    point,
    tooltipColumns,
    dataColumns,
    locationAnalysisItem,
    suggestEditFormClickBack,
    openMoreInfoClickBack,
    isContentBlurred
}) => {
    // Empty popup if feature doesn't have properties
    if (!featureProperties) return null;

    let openInGoogleMaps;
    const openInGoogleMapsColumn = dataColumns.find(c => c.isGoogleMapsLink);
    if (openInGoogleMapsColumn) {
        // If it's a link for google maps two columns are used to compose (latitude and longitude)
        const lat = featureProperties[openInGoogleMapsColumn.latColumn];
        const lng = featureProperties[openInGoogleMapsColumn.lngColumn];
        const { editFormFields } = openInGoogleMapsColumn;
        const editFormPayload = {};
        const value = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        if (editFormFields) {
            Object.keys(openInGoogleMapsColumn.editFormFields).forEach(key => {
                const fields = openInGoogleMapsColumn.editFormFields[key];
                editFormPayload[key] = fields.map(f => {
                    if (!f.title && !f.id) return '';
                    const fieldTitle = f.title ? `${f.title} ` : '';
                    if (!f.id) return fieldTitle;
                    const fieldValue = f.formatting ? format({ number: featureProperties[f.id], numberFormat: f.formatting }) : featureProperties[f.id];
                    return `${fieldTitle}${fieldValue}`;
                }).join(',');
            });
        }
        openInGoogleMaps = (
            <div className="open-in-google-maps">
                <a className="google-map-link" target="_blank" rel="noopener noreferrer" href={value}>
                    {openInGoogleMapsColumn.linkTitle}
                </a>
                {editFormFields && <SuggestEditFacilityButton editFormPayload={editFormPayload} clickBack={suggestEditFormClickBack} />}
            </div>
        );
    }
    let distanceFromPin;
    const distanceFromPinColumn = dataColumns.find(c => c.isDistanceValue && c.isTimeValue && locationAnalysisItem);
    if (distanceFromPinColumn) {
        const lat = featureProperties[distanceFromPinColumn.latColumn];
        const lng = featureProperties[distanceFromPinColumn.lngColumn];

        const showTime = locationAnalysisItem.isComplete && locationAnalysisItem.isIsochrone;

        distanceFromPin = (
            <div className="padded-12 flex-it center">
                {showTime && (
                    <Time
                        startPoint={locationAnalysisItem.point}
                        endPoint={{ lng, lat }}
                        mapboxRoutingProfile={locationAnalysisItem.mapboxRoutingProfile}
                    />
                )}
                {showTime && <i className="divider divider--vertical-small divider--btn-separator" />}
                <div className="tag">
                    <Distance
                        startPoint={locationAnalysisItem.point}
                        endPoint={{ lng, lat }}
                    />
                </div>
            </div>
        );
    }


    // tooltipColumns can be empty in which case all the data columns will be shown
    // if not empty then we show only the columns who's id is in the tooltipColumns array
    const title = featureProperties[titleColumn];
    const rows = dataColumns
        .filter(column => !HIDDEN_COLUMNS_NAMES.includes(column.title))
        .filter(column => !column.isGoogleMapsLink && !column.isDistanceValue && !column.isTimeValue)
        .filter(column => tooltipColumns.length === 0 || tooltipColumns.includes(column.id))
        .map(column => {
            let value = featureProperties[column.id];

            // Don't show column in info bubble if data is not defined or if value is None for Link
            if (!value || (column.isLink && value === 'None')) return null;
            if (column.formatting) {
                value = format({ number: value, numberFormat: column.formatting });
            }
            // Handle link case
            if (column.isLink) {
                value = (
                    <a target="_blank" rel="noopener noreferrer" href={value}>
                        {column.linkTitle}
                    </a>
                );
            }
            let titleNode;
            if (column.title) {
                titleNode = (
                    <div className="data-row__label data-row__label--no-width">
                        {column.title}
                    </div>
                );
            }

            return (
                <li key={column.id} className={classNames('data-row', { 'flex-flow: column': column.isFullWidth })}>
                    {titleNode}
                    <div className={classNames('data-row__layer-library-value', { 'margin-top-8': column.isFullWidth })}>
                        {value}
                    </div>
                </li>
            );
        });

    return (
        <div className="self-storage-info-bubble feature-group">
            <InfoBubbleHeader
                surveyCode={surveyCode}
            />
            <div className="position-relative">
                { isContentBlurred && <BlurOverlay /> }  
                <div className="padded-12">
                    <h4>{title}</h4>
                </div>
                {openInGoogleMaps}
                {distanceFromPin}
                <ul>{rows}</ul>
                <InfoBubbleMoreDetails
                    featureId={featureId}
                    featureProperties={featureProperties}
                    titleColumnId={titleColumn}
                    surveyCode={surveyCode}
                    point={point}
                    onOpenMoreInfo={openMoreInfoClickBack}
                />
            </div>
        </div>
    );
};

InfoBubbleLibraryLayer.propTypes = {
    featureId: PropTypes.string.isRequired,
    featureProperties: PropTypes.object.isRequired,
    tooltipColumns: PropTypes.array.isRequired,
    dataColumns: PropTypes.array.isRequired,
    titleColumn: PropTypes.string.isRequired,
    surveyCode: PropTypes.string,
    point: PropTypes.object.isRequired,
    locationAnalysisItem: PropTypes.object,
    suggestEditFormClickBack: PropTypes.func.isRequired,
    openMoreInfoClickBack: PropTypes.func.isRequired,
    isContentBlurred: PropTypes.bool,
};

InfoBubbleLibraryLayer.defaultProps = {
    locationAnalysisItem: undefined,
    surveyCode: null,
    tooltipColumns: [],
    isContentBlurred: false,
};

export default InfoBubbleLibraryLayer;

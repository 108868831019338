import Units from '../enums/Unit';
import NumberFormat from '../enums/NumberFormat';
import format from '../helpers/NumberFormatter';

// for some reason, release of Chrome/Chromium between 126.x and 128.x changed the behavior of certain WebGL methods
// so that #ToRestrictedFloat method is used to validate passed arguments
// this does not only include values such as +-Infinity, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY,
// but also the values as large as Number.MAX_VALUE for which #isFinite returns true!
// this is why we need to clamp to the largest value smaller than Number.MAX_VALUE which seems to pass the restriction
// which is Number.MAX_SAFE_INTEGER
// if value is undefined or null, value itself is returned
export function restrictFloatToFinite(value) {
    if (value === undefined) return undefined;
    if (value === null) return null;

    if (isFinite(value)) {
        if (value > Number.MAX_SAFE_INTEGER) {
            // handle finite restricted values such as Number.MAX_VALUE
            return Number.MAX_SAFE_INTEGER;
        } else if (value < Number.MIN_SAFE_INTEGER) {
            // handle finite restricted values such as -Number.MAX_VALUE
            return Number.MIN_SAFE_INTEGER;
        } else {
            // handle finite unrestricted values
            return value;
        }
    } else {
        // value such as +-Infinity, Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY detected
        if (value > 0) {
            // positive infinity values
            return Number.MAX_SAFE_INTEGER
        }
        // negative infinity values
        return Number.MIN_SAFE_INTEGER;
    }
}

export function parseIntWithDefault(input, base, defValue) {
    const result = parseInt(input, base);
    return (isNaN(result) ? defValue : result);
}

export function parseFloatWithDefault(input, defValue, restrictToFinite = false) {
    const parsedInput = parseFloat(input);
    const result = isNaN(parsedInput) ? defValue : parsedInput;
    if (restrictToFinite) {
        return restrictFloatToFinite(result);
    }
    return result;
}

export function booleanFromString(input) {
    return (input !== undefined && input !== null ? input.toLocaleLowerCase() === 'true' : false);
}

export function parseColorWithDefault(color, defaultColor) {
    if (color === undefined || color === null) {
        return defaultColor;
    }

    if (color.indexOf('#') !== -1) {
        return `#${'0'.repeat(7 - color.length)}${color.substr(1)}`;
    }

    return parseColorWithDefault(`#${parseInt(color, 10).toString(16)}`, defaultColor);
}

export function parseFieldName(fieldName) {
    const result = {
        surveyName: '',
        datasetAbbreviation: '',
        variableGuid: '',
    };

    if (fieldName.indexOf('.') !== -1) {
        [result.surveyName, result.datasetAbbreviation, result.variableGuid] = fieldName.split('.');
    } else {
        result.variableGuid = fieldName;
    }

    return result;
}

export function optimizeStopFunction(stopsFn) {
    if (stopsFn === undefined || stopsFn === null || stopsFn.stops === undefined || stopsFn.stops.length === 0) {
        return undefined;
    }

    if (stopsFn.stops.length === 1) {
        return stopsFn.stops[0][1];
    }

    const newStops = stopsFn.stops.reduce((s, v, currentIndex) => {
        if (currentIndex === 0) {
            s.push(v);
        } else if (s[s.length - 1][1] !== v[1]) {
            s.push(v);
        }

        return s;
    }, []);

    if (newStops.length === 1) {
        return newStops[0][1];
    }

    return {
        base: stopsFn.base,
        stops: newStops.sort((a, b) => a[0] - b[0]),
    };
}

export function setDefaults(defaults, target) {
    if (target === undefined) return defaults;

    const newTarget = JSON.parse(JSON.stringify(target));

    Object.keys(defaults).forEach(key => {
        if (newTarget[key] === undefined || newTarget[key] === null) {
            newTarget[key] = defaults[key];
        }
    });

    return newTarget;
}

export function overrideObjectProperties(override, target) {
    if (override && target && override.constructor === {}.constructor && target.constructor === {}.constructor) {
        Object.keys(override).forEach(key => {
            if (override[key] && target[key] &&
                override[key].constructor === {}.constructor && target[key].constructor === {}.constructor) {
                overrideObjectProperties(override[key], target[key]);
            } else {
                target[key] = override[key];
            }
        });
    }
}

export function cloneHashObject(object) {
    if (!object) return undefined;
    const clone = {};
    Object.keys(object).forEach(key => {
        clone[key] = object[key];
    });
    return clone;
}

export function hasParentNode(currentNode, node) {
    if (currentNode === node) return true;
    if (currentNode.parentNode) {
        if (currentNode.parentNode === node) {
            return true;
        }
        return hasParentNode(currentNode.parentNode, node);
    }
    return false;
}

export function hasParentNodeWithClass(currentNode, nodeClass) {
    if (currentNode.classList.contains(nodeClass)) {
        return true;
    }
    return currentNode.parentElement ? hasParentNodeWithClass(currentNode.parentElement, nodeClass) : false;
}

export function focusFirstChild(element) {
    if (!element || !element.childElementCount) {
        return;
    }

    const firstFocusableChild = element.querySelector('a, button, input, [tabindex="0"]');
    if (firstFocusableChild) {
        firstFocusableChild.focus();
    }
}


export function getCurrentGeoType(mapViewer) {
    return Object.keys(mapViewer.activeLayers).map(k => mapViewer.activeLayers[k])
        .filter(l => l.layer.isDataLayer && l.layer.getRenderersVisibilityAt(mapViewer.dragonflyMap.getZoom(), mapViewer.activeLayers)
            .some(el => el === true));
}

export function canVariableSelectionUseDotDensity(variableSelection, metadata) {
    if (!variableSelection || !variableSelection.items || variableSelection.isEmpty) return false;
    const firstVariableSelectionItem = variableSelection.items[0];
    const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
    const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
    const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
    return variableSelection.items.find(item => !metaTable.getVariableByGuid(item.variableGuid)
        .canUseDotDensity()) === undefined;
}

/**
 * @param {import('../objects/VariableSelection').default} variableSelection
 * @param {import('../objects/Metadata').default} metadata
 * @returns {boolean}
 */
export function canVariableSelectionUseShaded(variableSelection, metadata) {
    if (!variableSelection || !variableSelection.items || variableSelection.isEmpty) return false;
    const firstVariableSelectionItem = variableSelection.items[0];
    const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
    const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
    const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);

    // A variable selection can use shaded if none of the variables in selection is a parent variable
    // e.g you can select population White Only, population Asian Alone which is ok for shaded
    // If total population would be added the visualization would switch to dot density
    const countOnlyVariable = variableSelection.items.find(
        item => metaTable.getVariableByGuid(item.variableGuid).isVariableCountOnly
    );
    // If it's only parent count only variable (like total population) than shaded is ok
    if (variableSelection.items.length > 1 && countOnlyVariable) return false;
    // otherwise shaded not available
    return true;
}

export function canVariableSelectionUseBubbles(variableSelection, metadata) {
    if (!variableSelection || !variableSelection.items || variableSelection.isEmpty || variableSelection.isMultiVariable) return false;
    const firstVariableSelectionItem = variableSelection.items[0];
    const metaSurvey = metadata.surveys[firstVariableSelectionItem.surveyName];
    const metaDataset = metaSurvey.datasets[firstVariableSelectionItem.datasetAbbreviation];
    const metaTable = metaDataset.getTableByGuid(firstVariableSelectionItem.tableGuid);
    const metaVariable = metaTable.getVariableByGuid(firstVariableSelectionItem.variableGuid);
    return metaVariable.canUseBubbles();
}

/**
 * @param {import('../objects/VariableSelectionItem').default} variableSelectionItem
 * @param {import('../objects/Metadata').default} metadata
 * @returns {import('../../../index').VariableSelectionItemMetadata}
 */
export function getMetadataObjectsFromVariableSelectionItem(variableSelectionItem, metadata) {
    if (!variableSelectionItem || !metadata) return {};
    const metaSurvey = metadata.surveys[variableSelectionItem.surveyName];
    const metaDataset = metaSurvey.datasets[variableSelectionItem.datasetAbbreviation];
    const metaTable = metaDataset.getTableByGuid(variableSelectionItem.tableGuid);
    const metaVariable = metaTable.getVariableByGuid(variableSelectionItem.variableGuid);
    return {
        survey: metaSurvey,
        dataset: metaDataset,
        table: metaTable,
        variable: metaVariable,
    };
}

export function getMetadataObjectsFromVariableSelection(variableSelection, metadata) {
    if (!variableSelection || !metadata || !variableSelection.items || variableSelection.items.length === 0) return {};
    const metaSurvey = metadata.surveys[variableSelection.items[0].surveyName];
    const metaDataset = metaSurvey.datasets[variableSelection.items[0].datasetAbbreviation];
    const metaTable = metaDataset.getTableByGuid(variableSelection.items[0].tableGuid);
    const metaVariables = variableSelection.items.map(item => metaTable.getVariableByGuid(item.variableGuid));
    return {
        survey: metaSurvey,
        dataset: metaDataset,
        table: metaTable,
        variables: metaVariables,
    };
}

export function getDotValue(zoom, dotValueHint) {
    const referentZoomValue = Math.floor(zoom) + dotValueHint;
    switch (referentZoomValue) {
    case 25:
    case 24:
    case 23:
    case 22:
    case 21:
    case 20:
    case 19:
    case 18:
        return 1;
    case 17:
        return 2;
    case 16:
        return 5;
    case 15:
        return 10;
    case 14:
        return 25;
    case 13:
        return 50;
    case 12:
        return 100;
    case 11:
        return 200;
    case 10:
        return 500;
    case 9:
        return 1000;
    case 8:
        return 2500;
    case 7:
        return 5000;
    case 6:
        return 10000;
    case 5:
        return 25000;
    case 4:
        return 50000;
    case 3:
        return 100000;
    case 2:
        return 200000;
    case 1:
        return 500000;
    case 0:
        return 1000000;
    case -1:
        return 2000000;
    case -2:
        return 4000000;
    case -3:
        return 8000000;
    case -4:
        return 16000000;
    case -5:
        return 32000000;
    }
    // eslint-disable-next-line
    return 1 << Math.max(16 - referentZoomValue, 0);
}

export function addEvent(object, type, callback, propagation) {
    if (object === null || typeof (object) === 'undefined') return;
    if (object.addEventListener) {
        object.addEventListener(type, callback, propagation === undefined ? false : propagation);
    } else if (object.attachEvent) {
        object.attachEvent(`on${type}`, callback);
    } else {
        object[`on${type}`] = callback;
    }
}

export function removeEvent(object, type, callback, propagation) {
    if (object === null || typeof (object) === 'undefined') return;
    if (object.removeEventListener) {
        object.removeEventListener(type, callback, propagation === undefined ? false : propagation);
    } else if (object.attachEvent) {
        object.dettachEvent(`on${type}`, callback);
    } else {
        object[`on${type}`] = callback;
    }
}

export function convertNumberToColor(number) {
    const stringFormat = number.toString(16);
    return stringFormat.length !== 6 ? `#${`000000${stringFormat}`.substr(-6)}` : `#${stringFormat}`;
}

export function rgb2hex(rgb) {
    const rgbArray = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    function hex(x) {
        return (`0${parseInt(x, 10).toString(16)}`).slice(-2);
    }

    return `#${hex(rgbArray[1])}${hex(rgbArray[2])}${hex(rgbArray[3])}`;
}

export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (result) {
        return {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        };
    }

    return null;
}

export function copyTextFromElementTargetToClipboard(inp) {
    if (inp && inp.select) {
        inp.select();
        try {
            document.execCommand('copy');
            inp.blur();
        } catch (err) {
            console.error(err);
        }
    }
}

export function composeUrl(url, data) {
    let queryParams = '';
    if (data !== undefined) {
        Object.keys(data).forEach((linkParam, index) => {
            if (linkParam && data[linkParam] !== undefined) {
                queryParams = `${queryParams}${encodeURIComponent(linkParam)}=${encodeURIComponent(data[linkParam])}`;
            }
            if (index !== Object.keys(data).length - 1) {
                queryParams = `${queryParams}&`;
            }
        });
    }
    return `${url}${queryParams}`;
}

/**
 * Draws a rounded rectangle using the current state of the canvas.
 * If you omit the last three params, it will draw a rectangle
 * outline with a 5 pixel border radius
 * @param {CanvasRenderingContext2D} ctx
 * @param {Number} x The top left x coordinate
 * @param {Number} y The top left y coordinate
 * @param {Number} width The width of the rectangle
 * @param {Number} height The height of the rectangle
 * @param {Number} [radius = 5] The corner radius; It can also be an object
 *                 to specify different radii for corners
 * @param {Number} [radius.tl = 0] Top left
 * @param {Number} [radius.tr = 0] Top right
 * @param {Number} [radius.br = 0] Bottom right
 * @param {Number} [radius.bl = 0] Bottom left
 * @param {Boolean} [fill = false] Whether to fill the rectangle.
 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
 */
export function roundRect(ctx, x, y, width, height, fill, stroke = true, radius = 5) {
    let radii;
    if (typeof radius === 'number') {
        radii = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
        const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
        Object.keys(defaultRadius).forEach(side => {
            if (radii[side] !== undefined) {
                radii[side] = radii[side] || defaultRadius[side];
            }
        });
    }
    ctx.beginPath();
    ctx.moveTo(x + radii.tl, y);
    ctx.lineTo(x + (width - radii.tr), y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radii.tr);
    ctx.lineTo(x + width, y + (height - radii.br));
    ctx.quadraticCurveTo(x + width, y + height, (x + width) - radii.br, y + height);
    ctx.lineTo(x + radii.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, (y + height) - radii.bl);
    ctx.lineTo(x, y + radii.tl);
    ctx.quadraticCurveTo(x, y, x + radii.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (stroke) {
        ctx.stroke();
    }
}

export function retrieveFontHeight(fontSize, fontWeight, fontFamily) {
    const root = document.getElementById('root-container');
    const dummy = document.createElement('div');
    const dummyText = document.createTextNode('M');
    dummy.appendChild(dummyText);
    dummy.setAttribute('style', `font-size:${fontSize}px;font-weight:${fontWeight};font-family:${fontFamily};position:absolute;top:0;left:0`);
    root.appendChild(dummy);
    const result = dummy.offsetHeight;
    root.removeChild(dummy);
    return result;
}

export function canTextFit(availableHeight, availableWidth, text, fontSize, fontWeight, fontFamily) {
    const root = document.getElementById('root-container');
    const dummy = document.createElement('div');
    dummy.appendChild(document.createTextNode(text));
    dummy.setAttribute('style', `font-size:${fontSize}px;font-weight:${fontWeight};font-family:${fontFamily};position:absolute;top:0;left:0;width:${availableWidth}px`);
    root.appendChild(dummy);
    const doesTextFit = dummy.offsetHeight <= availableHeight;
    root.removeChild(dummy);
    return doesTextFit;
}

export function fitText(availableHeight, availableWidth, text, currentFontSize, context, fontStyle, maxFontSize, minFontSize) {
    let textFits = canTextFit(availableHeight, availableWidth, text, currentFontSize, fontStyle.fontWeight, fontStyle.fontFamily);
    let newFontSize = currentFontSize, newText = text;
    // if text fits try to increase font size
    if (textFits && currentFontSize + 1 <= maxFontSize) {
        while (textFits && newFontSize + 1 <= maxFontSize) {
            textFits = canTextFit(availableHeight, availableWidth, text, newFontSize + 1, fontStyle.fontWeight, fontStyle.fontFamily);
            newFontSize = textFits ? newFontSize + 1 : newFontSize;
        }
        textFits = true;
    } else if (!textFits && currentFontSize - 1 >= minFontSize) {
        while (!textFits && newFontSize - 1 >= minFontSize) {
            textFits = canTextFit(availableHeight, availableWidth, text, newFontSize - 1, fontStyle.fontWeight, fontStyle.fontFamily);
            newFontSize -= 1;
        }
    }

    if (!textFits) {
        const words = text.split(' ');
        while (!textFits) {
            words.pop();
            newText = `${words.join(' ')}...`;
            textFits = canTextFit(availableHeight, availableWidth, newText, newFontSize, fontStyle.fontWeight, fontStyle.fontFamily);
        }
    }
    return {
        fontSize: newFontSize,
        text: newText,
    };
}

export function wrapText(context, text, x, y, maxWidth, lineHeight) {
    const words = text.split(' ');
    let line = '', numberOfLines = 1;
    const positionX = x;
    let positionY = y;
    for (let n = 0; n < words.length; n += 1) {
        const testLine = `${line + words[n]} `;
        const metrics = context.measureText(testLine);
        const testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
            context.fillText(line, positionX, positionY);
            numberOfLines += 1;
            line = `${words[n]} `;
            positionY += lineHeight;
        } else {
            line = testLine;
        }
    }
    context.fillText(line, positionX, positionY);
    return {
        x: positionX,
        y: positionY,
        numberOfLines,
    };
}

export function calculateTextLines(context, text, maxWidth) {
    const words = text.split(' ');
    let line = '', numberOfLines = 1;
    for (let n = 0; n < words.length; n += 1) {
        const testLine = `${line + words[n]} `;
        const metrics = context.measureText(testLine);
        if (metrics.width > maxWidth && n > 0) {
            numberOfLines += 1;
            line = `${words[n]} `;
        } else {
            line = testLine;
        }
    }
    return numberOfLines;
}

export function composeScales() {
    const meterScale = [];
    const mileScale = [];

    let value = 0;
    let val = 1;
    let valueMeters = 0;
    let label = '';
    let valM = 1;
    let insideim = 1;
    let insidei = 1;

    for (let i = 0; i < 20; i += 1) {
        // do miles
        if (value < 2000) {
            switch (insidei) {
            case 1:
                value = val * 10;
                insidei = 2;
                break;
            case 2:
                value = val * 20;
                insidei = 5;
                break;
            case 5:
                value = val * 50;
                val *= 10;
                insidei = 1;
                break;
            }
            label = `${value} ft`;
            mileScale.push({ value, label });
        } else {
            if (value >= 2000 && value < 5280) {
                value = 5280;
                val = 5280;
                insidei = 2;
            } else {
                switch (insidei) {
                case 1:
                    value = val;
                    insidei = 2;
                    break;
                case 2:
                    value = val * 2;
                    insidei = 5;
                    break;
                case 5:
                    value = val * 5;
                    val *= 10;
                    insidei = 1;
                    break;
                }
            }
            label = `${value / 5280} mi`;
            mileScale.push({ value, label });
        }

        // do meters
        switch (insideim) {
        case 1:
            valueMeters = valM * 10;
            insideim = 2;
            break;
        case 2:
            valueMeters = valM * 20;
            insideim = 5;
            break;
        case 5:
            valueMeters = valM * 50;
            valM *= 10;
            insideim = 1;
            break;
        }

        if (valueMeters >= 1000) {
            label = `${valueMeters / 1000} km`;
        } else {
            label = `${valueMeters} m`;
        }
        meterScale.push({ value: valueMeters, label });
    }

    return {
        meterScale,
        mileScale,
    };
}

export function getBestScaleIndex(resolution, scale) {
    for (let i = scale.length; i > 0; i -= 1) {
        if (scale[i - 1].value < resolution) {
            return i - 1;
        }
    }
    return 0;
}

export function getMapResolutionInMeters(center, zoom) {
    if (center === undefined || zoom === undefined) {
        return undefined;
    }

    return (156543.04 * Math.cos(center.lat * (Math.PI / 180))) / (2 ** (zoom + 1));
}

export function getFormattedPageViewUrlRuby(eventName, project, ...params) {
    // always include the project view code, it tells us what project was loaded when the event occured. you always want that.
    let returnValue = `${eventName}?instance=dingo${(project ? `&view_code=${encodeURIComponent(project.viewCode)}` : '')}`;

    const args = params.length === 1 && params[0] instanceof Array ? params[0] : params;
    args.forEach((arg, i) => {
        if (args[i] && args[i].toString() !== '' && i % 2 === 0) {
            const value = args[i + 1] ? args[i + 1].toString() : '';
            returnValue += `&${args[i].toString()}=${encodeURIComponent(value)}`;
        }
    });
    return returnValue;
}

export function functionName(fun) {
    let ret = fun.toString();
    ret = ret.substr('function '.length);
    ret = ret.substr(0, ret.indexOf('('));
    return ret;
}

export function findDistanceInMilesFromLatLong(lnglat1, lnglat2) {
    const startPoint = {
        lat: (lnglat1.lat * Math.PI) / 180,
        lng: (lnglat1.lng * Math.PI) / 180,
    };
    const endPoint = {
        lat: (lnglat2.lat * Math.PI) / 180,
        lng: (lnglat2.lng * Math.PI) / 180,
    };
    const R = 3959; // earth radius in miles
    const deltaLat = Math.abs(startPoint.lat - endPoint.lat);
    const deltaLng = Math.abs(startPoint.lng - endPoint.lng);
    const a = (Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)) + (Math.cos(startPoint.lat) * Math.cos(endPoint.lat) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}

export function roundValue(value, numberOfDecimals) {
    let valueCopy = value;
    let factor = numberOfDecimals !== undefined ? numberOfDecimals * 10 : 1;
    if (valueCopy < 1) {
        while (valueCopy < 1) {
            valueCopy *= factor;
            factor *= 10;
        }
    }
    return Math.round(valueCopy) / factor;
}

export function debounce(func, wait = 50, immediate = false) {
    let timeout, args, context, timestamp, result;

    const later = function () { // eslint-disable-line func-names
        const last = Date.now() - timestamp;

        if (last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
        } else {
            timeout = null;
            if (!immediate) {
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            }
        }
    };

    return function (...argz) { // eslint-disable-line func-names
        context = this;
        args = argz;
        timestamp = Date.now();
        const callNow = immediate && !timeout;
        if (!timeout) timeout = setTimeout(later, wait);
        if (callNow) {
            result = func.apply(context, args);
            context = args = null;
        }

        return result;
    };
}

export function isStorageAvailable(storage) {
    try {
        const x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return false;
    }
}

export function roundNumberToPrecision(number, precision = 1000) {
    if (number < 100) return number;
    else if (number < 1000) return Math.round(number / 100) * 100;
    return Math.round(number / precision) * precision;
}

export function getLegendItemColor(annotation) {
    switch (annotation.type) {
    case 'Polygon':
    case 'FlowArrow':
    case 'Shape':
    case 'Marker':
        return annotation.fillColor;
    case 'Polyline':
    case 'Freehand':
    case 'Hotspot':
        return annotation.strokeColor;
    case 'Label':
        return annotation.textColor;
    default:
        return '#000000';
    }
}

export function download(data, strFileName, strMimeType) {
    const self = window, // this script is only for browsers anyway...
        defaultMime = 'application/octet-stream', // this default mime also triggers iframe downloads
        anchor = document.createElement('a'),
        toString = a => String(a);
    let blob,
        MyBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
        mimeType = strMimeType || defaultMime,
        payload = data,
        fileName = strFileName || 'download',
        reader;
    const url = !strFileName && !strMimeType && payload;

    MyBlob = MyBlob.call ? MyBlob.bind(self) : Blob;

    if (String(this) === 'true') { // reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
        payload = [payload, mimeType];
        mimeType = payload[0];
        payload = payload[1];
    }

    if (url && url.length < 2048) { // if no filename and no mime, assume a url was passed as the only argument
        fileName = url.split('/').pop().split('?')[0];
        anchor.href = url; // assign href prop to temp anchor
        if (anchor.href.indexOf(url) !== -1) { // if the browser determines that it's a potentially valid url path:
            const ajax = new XMLHttpRequest();
            ajax.open('GET', url, true);
            ajax.responseType = 'blob';
            ajax.onload = e => {
                download(e.target.response, fileName, defaultMime);
            };
            setTimeout(() => {
                ajax.send();
            }, 0); // allows setting custom ajax headers using the return:
            return ajax;
        } // end if valid url?
    } // end if url?

    function dataUrlToBlob(strUrl) {
        const parts = strUrl.split(/[:;,]/),
            type = parts[1],
            decoder = parts[2] === 'base64' ? atob : decodeURIComponent,
            binData = decoder(parts.pop()),
            mx = binData.length,
            uiArr = new Uint8Array(mx);
        let i = 0;
        // eslint-disable-next-line
        for (i; i < mx; ++i) uiArr[i] = binData.charCodeAt(i);

        return new MyBlob([uiArr], { type });
    }

    function saver(urlParam, winMode) {
        let u = urlParam;
        if ('download' in anchor) { // html5 A[download]
            anchor.href = u;
            anchor.setAttribute('download', fileName);
            anchor.className = 'download-js-link';
            anchor.innerHTML = 'downloading...';
            anchor.style.display = 'none';
            document.body.appendChild(anchor);
            setTimeout(() => {
                anchor.click();
                document.body.removeChild(anchor);
                if (winMode === true) {
                    setTimeout(() => {
                        self.URL.revokeObjectURL(anchor.href);
                    }, 250);
                }
            }, 66);
            return true;
        }

        // handle non-a[download] safari as best we can:
        if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
            u = u.replace(/^data:([\w\/\-\+]+)/, defaultMime);  // eslint-disable-line no-useless-escape
            if (!window.open(u)) { // popup blocked, offer direct download:
                if (confirm('Displaying New Document\n\nUse Save As... to download, then click back to return to this page.')) { // eslint-disable-line no-alert
                    location.href = u;
                }
            }
            return true;
        }

        // do iframe dataURL download (old ch+FF):
        const f = document.createElement('iframe');
        document.body.appendChild(f);

        if (!winMode) { // force a mime that will download:
            u = `data:${u.replace(/^data:([\w\/\-\+]+)/, defaultMime)}`; // eslint-disable-line no-useless-escape
        }
        f.src = u;
        setTimeout(() => {
            document.body.removeChild(f);
        }, 333);
        return false;
    } // end saver

    // go ahead and download dataURLs right away

    if (/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)) { // eslint-disable-line no-useless-escape
        if (payload.length > (1024 * 1024 * 1.999) && MyBlob !== toString) {
            payload = dataUrlToBlob(payload);
            mimeType = payload.type || defaultMime;
        } else {
            return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                navigator.msSaveBlob(dataUrlToBlob(payload), fileName) :
                saver(payload); // everyone else can save dataURLs un-processed
        }
    } // end if dataURL passed?

    // eslint-disable-next-line
    blob = payload instanceof MyBlob ?
        payload :
        new MyBlob([payload], { type: mimeType });

    if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
        return navigator.msSaveBlob(blob, fileName);
    }

    if (self.URL) { // simple fast and modern way using Blob and URL:
        saver(self.URL.createObjectURL(blob), true);
    } else {
        // handle non-Blob()+non-URL browsers:
        if (typeof blob === 'string' || blob.constructor === toString) {
            try {
                return saver(`data:${mimeType};base64,${self.btoa(blob)}`);
            } catch (y) {
                return saver(`data:${mimeType},${encodeURIComponent(blob)}`);
            }
        }

        // Blob but not URL support:
        reader = new FileReader();
        reader.onload = () => {
            saver(this.result);
        };
        reader.readAsDataURL(blob);
    }
    return true;
}

/* end download() */

export function isNumberFinite(value) {
    const func = Number.isFinite || (() => typeof value === 'number' && isFinite(value));
    return func(value);
}

export function invertColor(color) {
    const originalRGB = chroma(color).rgb();
    return chroma(255 - originalRGB[0], 255 - originalRGB[1], 255 - originalRGB[2]).hex();
}

export function isValidNumber(value) {
    return typeof value === 'number' || (typeof value === 'string' && !isNaN(value.replace(',', '')));
}

export function variableQualifiedName(survey, dataset, variable) {
    if (!survey || !dataset || !variable) {
        return undefined;
    }
    return `${survey.name}.${dataset.abbrevation}.${variable.uuid}`;
}

export function pointRadius(point1, point2) {
    return Math.sqrt(
        ((point1[0] - point2[0]) * (point1[0] - point2[0])) + ((point1[1] - point2[1]) * (point1[1] - point2[1]))
    );
}

export function checkRectCointainsRect(biggerRect, smallerRect) {
    const topLeft = { x: Math.min(biggerRect[0].x, biggerRect[1].x), y: Math.min(biggerRect[0].y, biggerRect[1].y) };
    const bottomRight = {
        x: Math.max(biggerRect[0].x, biggerRect[1].x),
        y: Math.max(biggerRect[0].y, biggerRect[1].y),
    };

    return smallerRect.every(point => topLeft.x < point.x && topLeft.y < point.y && bottomRight.x > point.x && bottomRight.y > point.y);
}

// Explanation and formula source:
// https://www.movable-type.co.uk/scripts/latlong.html
export function calculatePointForBearingAndDistanceInMiles(centerPointLngLat, distanceInMiles, bearingInDegree) {
    const bearingInRad = bearingInDegree * (Math.PI / 180);
    const R = 6371000; // earth radius in meters
    const d = parseFloat(distanceInMiles) * 1000 * 1.609344; // convert miles into meters
    const { lat: centerLat, lng: centerLng } = centerPointLngLat;
    const centerLatInRad = centerLat * (Math.PI / 180);
    const centerLngInRad = centerLng * (Math.PI / 180);

    const latInRad = Math.asin(Math.sin(centerLatInRad) * Math.cos(d / R)) + (Math.cos(centerLatInRad) * Math.sin(d / R) * Math.cos(bearingInRad));
    const lngInRad = centerLngInRad + Math.atan2(Math.sin(bearingInRad) * Math.sin(d / R) * Math.cos(centerLatInRad), Math.cos(d / R) - (Math.sin(centerLatInRad) * Math.sin(latInRad)));

    return { lng: lngInRad * (180 / Math.PI), lat: latInRad * (180 / Math.PI) };
}

export function convertToMiles(value, inputUnit) {
    switch (inputUnit) {
    case Units.MILES:
        return value;
    case Units.YARDS:
        return value / 1760;
    case Units.FEET:
        return value / 5280;
    case Units.KILOMETERS:
        return value / 1.609344;
    case Units.METERS:
        return value / 1609.344;
    default:
        console.error('Unknown input unit: ', inputUnit);
    }
    return undefined;
}

export function convertFromMiles(value, outputUnit) {
    switch (outputUnit) {
    case Units.MILES:
        return value;
    case Units.YARDS:
        return value * 1760;
    case Units.FEET:
        return value * 5280;
    case Units.KILOMETERS:
        return value * 1.609344;
    case Units.METERS:
        return value * 1609.344;
    default:
        console.error('Unknown output unit: ', outputUnit);
    }
    return undefined;
}

export function getRulesLabels(fields, rules, nullRuleIdx, insuffRuleIdx) {
    return rules.map((rule, idx) => {
        // null rule and insufficient rule have no labels
        if (idx === nullRuleIdx || idx === insuffRuleIdx) {
            return undefined;
        }
        // only range filter with number values have labels
        if (rule.filter.to === Number.MAX_SAFE_INTEGER || Number.isNaN(rule.filter.to) || rule.filter.to === undefined) {
            return undefined;
        }
        // get formatting from filter or from field
        let valueFormat;
        if (rule.filter.valueFormat && rule.filter.valueFormat !== '') {
            valueFormat = rule.filter.valueFormat;
        } else {
            const parsedFieldName = parseFieldName(rule.filter.fieldName);
            const ruleField = fields.find(f => f.fieldName === parsedFieldName.variableGuid);
            valueFormat = ruleField.formatting || NumberFormat.FORMAT_NUMBER;
        }
        // show all decimal places during formatting
        if (!valueFormat || valueFormat === '') {
            valueFormat = NumberFormat.FORMAT_NUMBER;
        } else if (valueFormat.startsWith(NumberFormat.FORMAT_NUMBER)) {
            valueFormat = NumberFormat.FORMAT_NUMBER;
        } else if (valueFormat.startsWith(NumberFormat.FORMAT_PERCENT)) {
            valueFormat = NumberFormat.FORMAT_PERCENT;
        } else if (valueFormat.startsWith(NumberFormat.FORMAT_CURRENCY)) {
            valueFormat = NumberFormat.FORMAT_CURRENCY;
        } else if (valueFormat.startsWith(NumberFormat.FORMAT_REAL_PERCENT)) {
            valueFormat = NumberFormat.FORMAT_REAL_PERCENT;
        } else if (valueFormat.startsWith(NumberFormat.FORMAT_NUMBER_NO_FORMAT)) {
            valueFormat = NumberFormat.FORMAT_NUMBER_NO_FORMAT;
        }

        // In the horizontal color palette for legend of shaded area visualizations
        // we do not want to show more than 1 decimal for any value - regardless is it
        // the whole number or the percent value.
        // The value here is always a non language related number
        const numberLimitedToOneDecimal = rule.filter.to.toLocaleString('en-US', {
            maximumFractionDigits: 1,
        });
        return format({
            number: numberLimitedToOneDecimal,
            numberFormat: valueFormat,
        });
    });
}

function clamp(number, min, max) {
    return Math.min(Math.max(number, min), max);
}

function fixSourceImageDimensions(sourceWidth, sourceHeight, sourceImage) {
    // https://stackoverflow.com/questions/46914128/indexsizeerror-on-drawimage-on-ie-and-edge
    // If the image height is 300px,
    // The max source height can only be also 300px
    return {
        height: clamp(sourceHeight, 1, sourceImage.height),
        width: clamp(sourceWidth, 1, sourceImage.width),
    };
}

export function resizeCanvas(sourceCanvas, targetCanvasWidth = 90, targetCanvasHeight = 56) {
    const canvas = document.createElement('canvas');
    canvas.width = targetCanvasWidth;
    canvas.height = targetCanvasHeight;
    const ctx = canvas.getContext('2d');

    const sourceWidth = sourceCanvas.width;
    const sourceHeight = sourceCanvas.height;
    const targetWidth = canvas.width;
    const targetHeight = canvas.height;
    const scaleFactor = Math.min(sourceWidth / targetWidth, sourceHeight / targetHeight);

    const xShift = sourceWidth - (targetWidth * scaleFactor);
    const yShift = sourceHeight - (targetHeight * scaleFactor);

    const scaleStepCount = Math.floor(scaleFactor / 4);

    if (scaleStepCount > 0) {
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = sourceWidth / 2;
        tempCanvas.height = sourceHeight / 2;
        const resizeCtx = tempCanvas.getContext('2d');

        // Clamp source image dimensions to its own, so IE/Edge doesn't kill itself. See above.
        let clampedDimensions = fixSourceImageDimensions(sourceWidth - xShift, sourceHeight - yShift, sourceCanvas);
        resizeCtx.drawImage(sourceCanvas, xShift / 2, yShift / 2, clampedDimensions.width, clampedDimensions.height, 0, 0, sourceWidth / 2, sourceHeight / 2);

        for (let i = 2; i <= scaleStepCount; i += 1) {
            clampedDimensions = fixSourceImageDimensions(sourceWidth / (2 * (i - 1)), sourceHeight / (2 * (i - 1)), tempCanvas);
            resizeCtx.drawImage(tempCanvas, 0, 0, clampedDimensions.width, clampedDimensions.height, 0, 0, sourceWidth / (2 * i), sourceHeight / (2 * i));
        }
        clampedDimensions = fixSourceImageDimensions(sourceWidth / (scaleStepCount * 2), sourceHeight / (scaleStepCount * 2), tempCanvas);
        ctx.drawImage(tempCanvas, 0, 0, clampedDimensions.width, clampedDimensions.height, 0, 0, targetWidth, targetHeight);
    } else {
        const clampedDimensions = fixSourceImageDimensions(sourceWidth - xShift, sourceHeight - yShift, sourceCanvas);
        ctx.drawImage(sourceCanvas, xShift / 2, yShift / 2, clampedDimensions.width, clampedDimensions.height, 0, 0, targetWidth, targetHeight);
    }

    return canvas;
}

export function loadScript(src, async = false) {
    return new Promise((resolve, reject) => {
        const tag = document.createElement('script');
        tag.async = async;
        tag.src = src;
        tag.addEventListener('load', () => {
            resolve();
        });
        tag.addEventListener('error', error => {
            reject(error);
        });
        document.body.appendChild(tag);
    });
}

export function uniqueValues(objects, property) {
    const uniques = {};

    objects.forEach(object => {
        if (!uniques.hasOwnProperty(object[property])) {
            uniques[object[property]] = object;
        }
    });

    return Object.values(uniques);
}

export function getMapCanvasImageData(mapCanvas) {
    try {
        const gl = mapCanvas.getContext('webgl') || mapCanvas.getContext('experimental-webgl');
        const data = new Uint8Array(4 * mapCanvas.width * mapCanvas.height);
        gl.readPixels(0, 0, mapCanvas.width, mapCanvas.height, gl.RGBA, gl.UNSIGNED_BYTE, data);
        return new ImageData(new Uint8ClampedArray(data), mapCanvas.width, mapCanvas.height);
    } catch (e) {
        return null;
    }
}

/**
 * @param { {geometry: { coordinates: [number, number][][] | number[][][]}}[] } features
 * @returns {import('../../..').BoundingBox}
 */
export function getFeatureBoundingBox(features) {
    /** @type {import('../../..').BoundingBox} */
    const bounds = {
        xMin: Number.MAX_SAFE_INTEGER,
        xMax: -Number.MAX_SAFE_INTEGER,
        yMin: Number.MAX_SAFE_INTEGER,
        yMax: -Number.MAX_SAFE_INTEGER,
    };
    /*
       In case we get a multipolygon, we use flat function
       to put all of arrays into one array (since multipolygon
       is an array of arrays), and then calculate bounds
    */
    features.forEach(feature => {
        let coords;
        if (feature.geometry.type === 'MultiPolygon') {
            coords = feature.geometry.coordinates.flat(2);
        } else {
            coords = feature.geometry.coordinates[0];
        }
        for (let i = 0; i < coords.length; i += 1) {
            const longitude = coords[i][0];
            const latitude = coords[i][1];

            bounds.xMin = Math.min(bounds.xMin, longitude);
            bounds.xMax = Math.max(bounds.xMax, longitude);
            bounds.yMin = Math.min(bounds.yMin, latitude);
            bounds.yMax = Math.max(bounds.yMax, latitude);
        }
    });

    return bounds;
}

export function getLatLngFromPixel(pixel) {
    let { x, y } = pixel;
    const resolution = 0.0093306919292081153;
    x *= resolution;
    y *= -resolution;
    let xGeo1 = x,
        yGeo1 = y;
    const pi = 3.1415926535897932384626433832795;
    const rho = 180 / pi;
    const er = 6378137; // 6371000;
    xGeo1 = (x * rho) / er;
    yGeo1 = 2 * rho * (Math.atan(Math.exp(y / er)) - (pi / 4));
    x = xGeo1;
    y = yGeo1;
    return { lng: x, lat: y };
}

// Check this link for more info:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals
export function taggedTemplate(strings, ...keys) {
    return ((...values) => {
        const dict = values[values.length - 1] || {};
        const result = [strings[0]];
        keys.forEach((key, i) => {
            const value = Number.isInteger(key) ? values[key] : dict[key];
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    });
}

/**
 * URL check
 * @param string input
 * @returns boolean
 */
export function isStringURL(input) {
    if (!input || typeof input !== 'string') return false;
    try {
        const url = new URL(input);
        return !!url.host;
    } catch (e) {
        return false;
    }
}

// TODO optimize or not (discussion: http://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript)
/**
 * @template A
 * @param {A} obj to be deep copied
 * @returns {A}
 */
export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export function updateObject(objDestination, objSource) {
    Object.keys(objDestination).forEach(key => delete objDestination[key]);
    Object.keys(objSource).forEach(key => objDestination[key] = objSource[key]);
}

export function uuid() {
    let _p8 = s => {
        let p = (Math.random().toString(16) + '000000000').substr(2, 8);
        return s ? '-' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
    };
    return _p8() + _p8(true) + _p8(true) + _p8();
}

/**
 * Compares two JSON-compatible objects for equality.
 * This function ensures a stable comparison by sorting object keys before stringifying.
 * 
 * Note: This method only works reliably for plain objects and arrays.
 * It does not handle complex data types like functions, `Map`, `Set`, or `Date` objects.
 * 
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - Returns `true` if objects are deeply equal, otherwise `false`.
 */
export function isEqualJson(obj1, obj2) {
    return JSON.stringify(obj1, Object.keys(obj1).sort()) === JSON.stringify(obj2, Object.keys(obj2).sort());
}

import ExcelNumberFormat from '../enums/ExcelNumberFormat';

export const FACILITY_REPORT_DATA = [
    {
        variable: 'facility_id',
        title: 'Facility ID',
    },
    {
        variable: 'name',
        title: 'Company',
    },
    {
        variable: 'management_type',
        title: 'Management type',
    },
    {
        variable: 'full_address',
        title: 'Full Address',
    },
    {
        variable: 'street_name',
        title: 'Street Address',
    },
    {
        variable: 'city',
        title: 'City',
    },
    {
        variable: 'zip',
        title: 'Zip',
    },
    {
        variable: 'county',
        title: 'County',
    },
    {
        variable: 'state',
        title: 'State',
    },
    {
        variable: 'telephone',
        title: 'Phone',
    },
    {
        variable: 'website',
        title: 'Website',
    },
    {
        variable: 'under_construction',
        title: 'New Construction',
    },
    {
        variable: 'distance_miles',
        title: 'Distance (miles)',
    },
    {
        variable: 'latitude',
        title: 'Latitude',
    },
    {
        variable: 'longitude',
        title: 'Longitude',
    },
    {
        variable: 'has_cc_units',
        title: 'Has Climate Control',
    },
    {
        variable: 'sqft_driveup',
        title: 'Gross Drive-up Square Footage',
    },
    {
        variable: 'sqft_indoor',
        title: 'Gross Indoor Square Footage',
    },
    {
        variable: 'rent_sqft_driveup',
        title: 'Rentable Drive-up Square Footage',
    },
    {
        variable: 'rent_sqft_indoor',
        title: 'Rentable Indoor Square Footage',
    },
    {
        variable: 'sqft',
        title: 'Total Gross Square Footage',
    },
    {
        variable: 'rent_sqft',
        title: 'Total Rentable Square Footage',
    },
    {
        variable: 'project_description',
        title: 'Project Description',
    },
    {
        variable: 'project_value',
        title: 'Project Value',
    },
    {
        variable: 'start_date',
        title: 'Start Date',
    },
    {
        variable: 'end_date',
        title: 'End Date',
    },
    {
        variable: 'submitted_date',
        title: 'Submitted Date',
    },
    {
        variable: 'last_updated_date',
        title: 'Last Updated Date',
    },
    {
        variable: 'number_of_stories',
        title: 'Stories',
    },
    {
        variable: 'floors_below_grade',
        title: 'Floors Below Grade',
    },
    {
        variable: 'owner_name',
        title: 'Owner Name',
    },
    {
        variable: 'owner_phone',
        title: 'Owner Phone',
    },
    {
        variable: 'owner_email',
        title: 'Owner Email',
    },
    {
        variable: 'owner_website',
        title: 'Owner Website',
    },
    {
        variable: 'owner_company_name',
        title: 'Owner Company Name',
    },
    {
        variable: 'developer_name',
        title: 'Developer Name',
    },
    {
        variable: 'developer_phone',
        title: 'Developer Phone',
    },
    {
        variable: 'developer_email',
        title: 'Developer Email',
    },
    {
        variable: 'developer_website',
        title: 'Developer Website',
    },
    {
        variable: 'developer_company_name',
        title: 'Developer Company Name',
    },
    {
        variable: 'stage',
        title: 'Stage',
    },
    {
        variable: 'zoning',
        title: 'Zoning Code',
    },
    {
        variable: 'zoning_description',
        title: 'Zoning Description',
    },
    {
        variable: 'parcel_address',
        title: 'Parcel Address',
    },
    {
        variable: 'parcel_owners',
        title: 'Parcel Owner(s)',
    },
    {
        variable: 'mailing_addresses',
        title: 'Mailing Address(es)',
    },
    {
        variable: 'year_built',
        title: 'Structure Year Built',
    },
    {
        variable: 'parcel_value_type',
        title: 'Parcel Value Type',
    },
    {
        variable: 'improvement_value',
        title: 'Improvement Value',
    },
    {
        variable: 'land_value',
        title: 'Land Value',
    },
    {
        variable: 'parcel_value',
        title: 'Total Parcel Value',
    },
    {
        variable: 'agricultural_value',
        title: 'Agricultural Value',
    },
    {
        variable: 'sale_price',
        title: 'Last Sale Price',
    },
    {
        variable: 'sale_date',
        title: 'Last Sale Date',
    },
    {
        variable: 'last_county_refresh_date',
        title: 'Last County Refresh Date',
    },
    {
        variable: 'source_url',
        title: 'Source URL',
    },
    {
        variable: 'acres',
        title: 'Acres',
    },
    {
        variable: 'project_notes',
        title: 'Project Notes',
    },
    {
        variable: 'has_pricing',
        title: 'Has Pricing',
    },
];

export const DEMOGRAPHY_REPORT_DATA = [
    {
        title: 'SE:T001:Population & Growth',
        items: [
            {
                variable: 'population',
                title: '2023 Total Population',
            },
            {
                variable: 'population_density',
                title: '2023 Population Density',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population_2020_current_growth',
                title: '2020-2023 Growth',
            },
            {
                variable: 'population_2020_current_avg_annual_growth',
                title: '2020-2023 Average Annual Growth',
            },
            {
                variable: 'population_projected_census',
                title: '2028 Total Population',
            },
            {
                variable: 'population_density_projected',
                title: '2028 Population Density',
            },
            {
                variable: 'population_growth_projected',
                title: '2023-2028 Projected Population Growth',
            },
            {
                variable: 'population_avg_annual_growth_projected',
                title: '2023-2028 Projected Average Annual Growth',
            },
            {
                variable: 'population_2020',
                title: '2020 Population',
            },
            {
                variable: 'population_2010',
                title: '2010 Total Population',
            },
            {
                variable: 'population_2000',
                title: '2000 Population',
            },
        ],
    },
    {
        title: 'SE:T002:Households & Growth',
        items: [
            {
                variable: 'households',
                title: '2023 Households',
            },
            {
                variable: 'households_2020_current_growth',
                title: '2020-2023 Growth',
            },
            {
                variable: 'households_2020_current_avg_annual_growth',
                title: '2020-2023 Average Annual Growth',
            },
            {
                variable: 'households_projected',
                title: '2028 Households',
            },
            {
                variable: 'households_growth_projected',
                title: '2023-2028 Growth',
            },
            {
                variable: 'households_avg_annual_growth_projected',
                title: '2023-2028 Average Annual Growth',
            },
            {
                variable: 'households_2020',
                title: '2020 Households',
            },
        ],
    },
    {
        title: 'SE:T003:Families',
        items: [
            {
                variable: 'families',
                title: '2023 Families',
            },
            {
                variable: 'families_projected',
                title: '2028 Families',
            },
        ],
    },
    {
        title: 'SE:T004:Income',
        items: [
            {
                variable: 'total_household_income',
                title: '2023 Aggregate Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'average_household_income',
                title: '2023 Average Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'median_household_income',
                title: '2023 Median Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'income_per_capita',
                title: '2023 Per Capita Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'total_household_income_projected',
                title: '2028 Aggregate Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'average_household_income_projected',
                title: '2028 Average Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'median_household_income_projected',
                title: '2028 Median Household Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'income_per_capita_projected',
                title: '2028 Per Capita Income',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
        ],
    },
    {
        title: 'SE:T005:Daytime Demographics',
        items: [
            {
                variable: 'population_daytime',
                title: 'Daytime Population',
            },
            {
                variable: 'employees',
                title: 'Employees, Total',
            },
            {
                variable: 'total_retail_sales',
                title: 'Total Retail Sales',
            },
        ],
    },
    {
        title: 'SE:T006:Population by Race',
        items: [
            {
                variable: 'population_race_white',
                title: '2023 White Population, Alone',
            },
            {
                variable: 'population_race_black',
                title: '2023 Black Population, Alone',
            },
            {
                variable: 'population_race_asian',
                title: '2023 Asian Population, Alone',
            },
            {
                variable: 'population_race_native',
                title: '2023 American Indian and Alaska Native Population, Alone',
            },
            {
                variable: 'population_race_other',
                title: '2023 Other Race Population, Alone',
            },
            {
                variable: 'population_race_multi',
                title: '2023 Two or More Races Population',
            },
            {
                variable: 'population_race_white_projected',
                title: '2028 White Population, Alone',
            },
            {
                variable: 'population_race_black_projected',
                title: '2028 Black Population, Alone',
            },
            {
                variable: 'population_race_asian_projected',
                title: '2028 Asian Population, Alone',
            },
            {
                variable: 'population_race_native_projected',
                title: '2028 American Indian and Alaska Native Population, Alone',
            },
            {
                variable: 'population_race_other_projected',
                title: '2028 Other Race Population, Alone',
            },
            {
                variable: 'population_race_multi_projected',
                title: '2028 Two or More Races Population',
            },
        ],
    },
    {
        title: 'SE:T007:Population by Ethnicity',
        items: [
            {
                variable: 'population_ethnic_hispanic',
                title: '2023 Hispanic Population',
            },
            {
                variable: 'population_ethnic_white_nonhispanic',
                title: '2023 White Non-Hispanic Population',
            },
            {
                variable: 'population_ethnic_hispanic_projected',
                title: '2028 Hispanic Population',
            },
            {
                variable: 'population_ethnic_white_nonhispanic_projected',
                title: '2028 White Non-Hispanic Population',
            },
        ],
    },
    {
        title: 'SE:T008:Population by Sex',
        items: [
            {
                variable: 'population_male',
                title: '2023 Male',
            },
            {
                variable: 'population_female',
                title: '2023 Female',
            },
            {
                variable: 'population_male_projected',
                title: '2028 Male',
            },
            {
                variable: 'population_female_projected',
                title: '2028 Female',
            },
        ],
    },
    {
        title: 'SE:T009:Population by Sex by Age',
        items: [
            {
                variable: 'population_male_age_to_5yrs',
                title: '2023 Males Aged 0 to 5 Years',
            },
            {
                variable: 'population_male_age_6_to_11yrs',
                title: '2023 Males Aged 6 to 11 Years',
            },
            {
                variable: 'population_male_age_12_to_17yrs',
                title: '2023 Males Aged 12 to 17 Years',
            },
            {
                variable: 'population_male_age_18_to_24yrs',
                title: '2023 Males Aged 18 to 24 Years',
            },
            {
                variable: 'population_male_age_25_to_34yrs',
                title: '2023 Males Aged 25 to 34 Years',
            },
            {
                variable: 'population_male_age_35_to_44yrs',
                title: '2023 Males Aged 35 to 44 Years',
            },
            {
                variable: 'population_male_age_45_to_54yrs',
                title: '2023 Males Aged 45 to 54 Years',
            },
            {
                variable: 'population_male_age_55_to_64yrs',
                title: '2023 Males Aged 55 to 64 Years',
            },
            {
                variable: 'population_male_age_65_to_74yrs',
                title: '2023 Males, Aged 65 to 74 Years',
            },
            {
                variable: 'population_male_age_75_to_84yrs',
                title: '2023 Males Aged 75 to 84 Years',
            },
            {
                variable: 'population_male_age_above_85yrs',
                title: '2023 Males Aged 85 Years and Older',
            },
            {
                variable: 'population_female_age_to_5yrs',
                title: '2023 Females Aged 0 to 5 Years',
            },
            {
                variable: 'population_female_age_6_to_11yrs',
                title: '2023 Females Aged 6 to 11 Years',
            },
            {
                variable: 'population_female_age_12_to_17yrs',
                title: '2023 Females Aged 12 to17 Years',
            },
            {
                variable: 'population_female_age_18_to_24yrs',
                title: '2023 Females Aged 18 to 24 Years',
            },
            {
                variable: 'population_female_age_25_to_34yrs',
                title: '2023 Females Aged 25 to 34 Years',
            },
            {
                variable: 'population_female_age_35_to_44yrs',
                title: '2023 Females Aged 35 to 44 Years',
            },
            {
                variable: 'population_female_age_45_to_54yrs',
                title: '2023 Females Aged 45 to 54 Years',
            },
            {
                variable: 'population_female_age_55_to_64yrs',
                title: '2023 Females Aged 55 to 64 Years',
            },
            {
                variable: 'population_female_age_65_to_74yrs',
                title: '2023 Females Aged 65 to 74 Years',
            },
            {
                variable: 'population_female_age_75_to_84yrs',
                title: '2023 Females Aged 75 to 84 Years',
            },
            {
                variable: 'population_female_age_above_85yrs',
                title: '2023 Females Aged 85 Years and Older',
            },
        ],
    },
    {
        title: 'SE:T010:2023 Population by Age',
        items: [
            {
                variable: 'population_median_age',
                title: '2023 Population, Median Age',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population_age_to_5yrs',
                title: '2023 Population Aged 0 to 5 Years',
            },
            {
                variable: 'population_age_6_to_11yrs',
                title: '2023 Population Aged 6 to 11 Years',
            },
            {
                variable: 'population_age_12_to_17yrs',
                title: '2023 Population Aged 12 to17 Years',
            },
            {
                variable: 'population_age_18_to_24yrs',
                title: '2023 Population Aged 18 to 24 Years',
            },
            {
                variable: 'population_age_25_to_34yrs',
                title: '2023 Population Aged 25 to 34 Years',
            },
            {
                variable: 'population_age_35_to_44yrs',
                title: '2023 Population Aged 35 to 44 Years',
            },
            {
                variable: 'population_age_45_to_54yrs',
                title: '2023 Population Aged 45 to 54 Years',
            },
            {
                variable: 'population_age_55_to_64yrs',
                title: '2023 Population Aged 55 to 64 Years',
            },
            {
                variable: 'population_age_65_to_74yrs',
                title: '2023 Population Aged 65 to 74 Years',
            },
            {
                variable: 'population_age_75_to_84yrs',
                title: '2023 Population Aged 75 to 84 Years',
            },
            {
                variable: 'population_age_above_85yrs',
                title: '2023 Population Aged 85 Years and Older',
            },
        ],
    },
    {
        title: 'SE:T011:2028 Population by Age',
        items: [
            {
                variable: 'population_median_age_projected',
                title: '2028 Population, Median Age',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population_age_to_5yrs_projected',
                title: '2028 Population Aged 0 to 5 Years',
            },
            {
                variable: 'population_age_6_to_11yrs_projected',
                title: '2028 Population Aged 6 to 11 Years',
            },
            {
                variable: 'population_age_12_to_17yrs_projected',
                title: '2028 Population Aged 12 to17 Years',
            },
            {
                variable: 'population_age_18_to_24yrs_projected',
                title: '2028 Population Aged 18 to 24 Years',
            },
            {
                variable: 'population_age_25_to_34yrs_projected',
                title: '2028 Population Aged 25 to 34 Years',
            },
            {
                variable: 'population_age_35_to_44yrs_projected',
                title: '2028 Population Aged 35 to 44 Years',
            },
            {
                variable: 'population_age_45_to_54yrs_projected',
                title: '2028 Population Aged 45 to 54 Years',
            },
            {
                variable: 'population_age_55_to_64yrs_projected',
                title: '2028 Population Aged 55 to 64 Years',
            },
            {
                variable: 'population_age_65_to_74yrs_projected',
                title: '2028 Population Aged 65 to 74 Years',
            },
            {
                variable: 'population_age_75_to_84yrs_projected',
                title: '2028 Population Aged 75 to 84 Years',
            },
            {
                variable: 'population_age_above_85yrs_projected',
                title: '2028 Population Aged 85 Years and Older',
            },
        ],
    },
    {
        title: 'SE:T012:Child Population',
        items: [
            {
                variable: 'population_age_to_5yrs',
                title: '2023 Population Aged 0 to 5 Years (Infant-K)',
            },
            {
                variable: 'population_age_6_to_17yrs',
                title: '2023 Population Aged 6 to 17 Years (Gradeschool)',
            },
            {
                variable: 'population_age_to_5yrs_projected',
                title: '2028 Population Aged 0 to 5 Years (Infant-K)',
            },
            {
                variable: 'population_age_6_to_17yrs_projected',
                title: '2028 Population Aged 6 to 17 Years (Gradeschool)',
            },
        ],
    },
    {
        title: 'SE:T013:Educational Attainment  (Aged 25 & Over)',
        items: [
            {
                variable: 'population_age_above_25yrs',
                title: '2023 Population Aged 25 and Over',
            },
            {
                variable: 'population_education_less_than_high_school',
                title: '   Less Than High School Diploma',
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_high_school',
                title: '   High School Diploma',
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_college',
                title: '   Some College',
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_associate_degree',
                title: "   Associate's Degree",
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_bachelor_degree',
                title: "   Bachelor's Degree",
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_master_degree',
                title: "   Master's Degree",
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_professional_degree',
                title: '   Professional Degree',
                percentageOf: 'population_age_above_25yrs',
            },
            {
                variable: 'population_education_doctorate_degree',
                title: '   Doctorate Degree',
                percentageOf: 'population_age_above_25yrs',
            },
        ],
    },
    {
        title: 'SE:T014:Employment & Transportation to Work',
        items: [
            {
                variable: 'population_employed_older_than_16yrs',
                title: 'Employed Population 16 and Over',
            },
            {
                variable: 'travel_method_work_at_home',
                title: '   Work at Home',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_car_truck_van',
                title: '   Car, Truck, Van to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_alone',
                title: '      Alone',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_carpool',
                title: '      Carpool',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_public_transport',
                title: '   Public Transportation to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_bus_or_trolley_bus',
                title: '      Bus or Trolley Bus to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_streetcar_or_trolley_car',
                title: '      Streetcar or Trolley Car to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_subway_or_elevated',
                title: '      Subway or Elevated to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_railroad',
                title: '      Railroad to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_ferry',
                title: '      Ferry to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_taxi',
                title: '   Taxi to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_motorcycle',
                title: '   Motorcycle to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_bicycle',
                title: '   Bicycle to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_walked',
                title: '   Walked to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_method_work_other_transport',
                title: '   Other Transportation to Work',
                percentageOf: 'population_employed_older_than_16yrs',
            },
        ],
    },
    {
        title: 'SE:T015:Travel Time to Work',
        items: [
            {
                variable: 'population_employed_older_than_16yrs',
                title: 'Employed Population 16 and Over',
            },
            {
                variable: 'travel_time_work_to_30m',
                title: '   Travel Time Less than 30 Minutes',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_time_work_30m_to_60m',
                title: '   Travel Time 30-59 Min',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_time_work_60m_to_90m',
                title: '   Travel Time 60-89 Min',
                percentageOf: 'population_employed_older_than_16yrs',
            },
            {
                variable: 'travel_time_work_above_90m',
                title: '   Travel Time 90+ Min',
                percentageOf: 'population_employed_older_than_16yrs',
            },
        ],
    },
    {
        title: 'SE:T016:2023 Households by Income',
        items: [
            {
                variable: 'median_household_income',
                title: 'Household Income, Median',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'households_income_to_25k',
                title: 'Households with Income Less than $25,000 (Estimates) *',
            },
            {
                variable: 'households_income_25k_to_35k',
                title: 'Households with Income $25,000 to $34,999',
            },
            {
                variable: 'households_income_35k_to_50k',
                title: 'Households with Income $35,000 to $49,999',
            },
            {
                variable: 'households_income_50k_to_75k',
                title: 'Households with Income $50,000 to $74,999',
            },
            {
                variable: 'households_income_75k_to_100k',
                title: 'Households with Income $75,000 to $99,999',
            },
            {
                variable: 'households_income_100k_to_125k',
                title: 'Households with Income $100,000 to $124,999',
            },
            {
                variable: 'households_income_above_125k',
                title: 'Households with Income $125,000 and Above (Estimates) *',
            },
        ],
    },
    {
        title: 'SE:T017:2028 Households by Income',
        items: [
            {
                variable: 'median_household_income_projected',
                title: 'Household Income, Median',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'households_income_to_25k_projected',
                title: 'Households with Income Less than $25,000 (Estimates) *',
            },
            {
                variable: 'households_income_25k_to_35k_projected',
                title: 'Households with Income $25,000 to $34,999',
            },
            {
                variable: 'households_income_35k_to_50k_projected',
                title: 'Households with Income $35,000 to $49,999',
            },
            {
                variable: 'households_income_50k_to_75k_projected',
                title: 'Households with Income $50,000 to $74,999',
            },
            {
                variable: 'households_income_75k_to_100k_projected',
                title: 'Households with Income $75,000 to $99,999',
            },
            {
                variable: 'households_income_100k_to_125k_projected',
                title: 'Households with Income $100,000 to $124,999',
            },
            {
                variable: 'households_income_above_125k_projected',
                title: 'Households with Income $125,000 and Above (Estimates) *',
            },
        ],
    },
    {
        title: 'SE:T018:2023 Household Structure',
        items: [
            {
                variable: 'households',
                title: 'Total Number of Households',
            },
            {
                variable: 'households_1_person',
                title: '   Households, 1 Person',
                percentageOf: 'households',
            },
            {
                variable: 'households_2_person',
                title: '   Households, 2 Person',
                percentageOf: 'households',
            },
            {
                variable: 'households_3_plus_person',
                title: '   Households, 3 or More People',
                percentageOf: 'households',
            },
            {
                variable: 'median_household_size',
                title: 'Households, Median Size',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population_in_families',
                title: 'Family Population',
            },
            {
                variable: 'population_non_family',
                title: 'Non-Family Population',
            },
            {
                variable: 'families',
                title: 'Families',
            },
            {
                variable: 'families_married',
                title: '   Families, Married',
                percentageOf: 'families',
            },
            {
                variable: 'families_other',
                title: '   Other Families',
                percentageOf: 'families',
            },
            {
                variable: 'families_male_householder_no_wife',
                title: '      Male Householder, No Wife Present',
                percentageOf: 'families',
            },
            {
                variable: 'families_female_householder_no_husband',
                title: '      Female Householder, No Husband Present',
                percentageOf: 'families',
            },
            {
                variable: 'families_married_children_u18',
                title: 'Families, Married with Children Under 18',
            },
            {
                variable: 'families_married_no_children_u18',
                title: 'Families, Married with No Children Under 18',
            },
            {
                variable: 'non_families',
                title: 'Non-Family Households',
            },
            {
                variable: 'non_families_male_householder_people_u18',
                title: '   Male Householder with People Under 18',
                percentageOf: 'non_families',
            },
            {
                variable: 'non_families_male_householder_no_people_u18',
                title: '   Male Householder with No People Under 18',
                percentageOf: 'non_families',
            },
            {
                variable: 'non_families_female_householder_people_u18',
                title: '   Female Householder with People Under 18',
                percentageOf: 'non_families',
            },
            {
                variable: 'non_families_female_householder_no_people_u18',
                title: '   Female Householder with No People Under 18',
                percentageOf: 'non_families',
            },
        ],
    },
    {
        title: 'SE:T019:2028 Household Structure',
        items: [
            {
                variable: 'households_projected',
                title: 'Total Number of Households',
            },
            {
                variable: 'households_1_person_projected',
                title: '   Households, 1 Person',
                percentageOf: 'households_projected',
            },
            {
                variable: 'households_2_person_projected',
                title: '   Households, 2 Person',
                percentageOf: 'households_projected',
            },
            {
                variable: 'households_3_plus_person_projected',
                title: '   Households, 3 or More People',
                percentageOf: 'households_projected',
            },
            {
                variable: 'median_household_size_projected',
                title: 'Households, Median Size',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population_in_families_projected',
                title: 'Family Population',
            },
            {
                variable: 'population_non_family_projected',
                title: 'Non-Family Population',
            },
            {
                variable: 'families_projected',
                title: 'Families',
            },
            {
                variable: 'families_married_projected',
                title: '   Families, Married',
                percentageOf: 'families_projected',
            },
            {
                variable: 'families_other_projected',
                title: '   Other Families',
                percentageOf: 'families_projected',
            },
            {
                variable: 'families_male_householder_no_wife_projected',
                title: '      Male Householder, No Wife Present',
                percentageOf: 'families_projected',
            },
            {
                variable: 'families_female_householder_no_husband_projected',
                title: '      Female Householder, No Husband Present',
                percentageOf: 'families_projected',
            },
            {
                variable: 'families_married_children_u18_projected',
                title: 'Families, Married with Children Under 18',
            },
            {
                variable: 'families_married_no_children_u18_projected',
                title: 'Families, Married with No Children Under 18',
            },
            {
                variable: 'non_families_projected',
                title: 'Non-Family Households',
            },
            {
                variable: 'non_families_male_householder_people_u18_projected',
                title: '   Male Householder with People Under 18',
                percentageOf: 'non_families_projected',
            },
            {
                variable: 'non_families_male_householder_no_people_u18_projected',
                title: '   Male Householder with No People Under 18',
                percentageOf: 'non_families_projected',
            },
            {
                variable: 'non_families_female_householder_people_u18_projected',
                title: '   Female Householder with People Under 18',
                percentageOf: 'non_families_projected',
            },
            {
                variable: 'non_families_female_householder_no_people_u18_projected',
                title: '   Female Householder with No People Under 18',
                percentageOf: 'non_families_projected',
            },
        ],
    },
    {
        title: 'SE:T020:2023 Householders by Age',
        items: [
            {
                variable: 'households',
                title: 'Total Number of Households',
            },
            {
                variable: 'householder_under_25_yr',
                title: '   Householder, Aged Under 25 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_25_to_34_yr',
                title: '   Householder, Aged 25 to 34 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_35_to_44_yr',
                title: '   Householder, Aged 35 to 44 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_45_to_54_yr',
                title: '   Householder, Aged 45 to 54 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_55_to_64_yr',
                title: '   Householder, Aged 55 to 64 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_65_to_74_yr',
                title: '   Householder, Aged 65 to 74 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_75_to_84_yr',
                title: '   Householder, Aged 75 to 84 Years',
                percentageOf: 'households',
            },
            {
                variable: 'householder_over_85_yr',
                title: '   Householder, Aged 85 Years and Over',
                percentageOf: 'households',
            },
        ],
    },
    {
        title: 'SE:T021:2028 Householders by Age',
        items: [
            {
                variable: 'households_projected',
                title: 'Total Number of Households',
            },
            {
                variable: 'householder_under_25_yr_projected',
                title: '   Householder, Aged Under 25 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_25_to_34_yr_projected',
                title: '   Householder, Aged 25 to 34 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_35_to_44_yr_projected',
                title: '   Householder, Aged 35 to 44 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_45_to_54_yr_projected',
                title: '   Householder, Aged 45 to 54 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_55_to_64_yr_projected',
                title: '   Householder, Aged 55 to 64 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_65_to_74_yr_projected',
                title: '   Householder, Aged 65 to 74 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_75_to_84_yr_projected',
                title: '   Householder, Aged 75 to 84 Years',
                percentageOf: 'households_projected',
            },
            {
                variable: 'householder_over_85_yr_projected',
                title: '   Householder, Aged 85 Years and Over',
                percentageOf: 'households_projected',
            },
        ],
    },
    {
        title: 'SE:T022:Housing',
        items: [
            {
                variable: 'units',
                title: '2023, Units',
            },
            {
                variable: 'housing_occupied',
                title: '   Occupied Units',
                percentageOf: 'units',
            },
            {
                variable: 'homeowners',
                title: '      Owner Occupied',
                percentageOf: 'units',
            },
            {
                variable: 'renters',
                title: '      Renter Occupied',
                percentageOf: 'units',
            },
            {
                variable: 'housing_vacant',
                title: '   Vacant Units',
                percentageOf: 'units',
            },
            {
                variable: 'median_rent',
                title: '2023, Median Rent ($)',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'housing_median_year_built',
                title: '2023, Median Year Built',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
            {
                variable: 'housing_median_year_moved_in',
                title: '2023, Median Year Moved In',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
            {
                variable: 'units_2020',
                title: '2020, Units',
            },
            {
                variable: 'households_2020',
                title: '   Occupied Units',
                percentageOf: 'units_2020',
            },
            {
                variable: 'homeowners_2020',
                title: '      Owner Occupied',
                percentageOf: 'units_2020',
            },
            {
                variable: 'renters_2020',
                title: '      Renter Occupied',
                percentageOf: 'units_2020',
            },
            {
                variable: 'housing_vacant_2020',
                title: '   Vacant Units',
                percentageOf: 'units_2020',
            },
            {
                variable: 'median_rent_2020',
                title: '2020 Median Rent ($)',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'housing_median_year_built_2020',
                title: '2020 Median Year Built',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
            {
                variable: 'housing_median_year_moved_in_2020',
                title: '2020 Median Year Moved In',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
            {
                variable: 'units_projected',
                title: '2028, Units',
            },
            {
                variable: 'housing_occupied_projected',
                title: '   Occupied Units',
                percentageOf: 'units_projected',
            },
            {
                variable: 'homeowners_projected',
                title: '      Owner Occupied',
                percentageOf: 'units_projected',
            },
            {
                variable: 'renters_projected',
                title: '      Renter Occupied',
                percentageOf: 'units_projected',
            },
            {
                variable: 'housing_vacant_projected',
                title: '   Vacant Units',
                percentageOf: 'units_projected',
            },
            {
                variable: 'median_rent_projected',
                title: '2028, Median Rent ($)',
                format: ExcelNumberFormat.FORMAT_CURRENCY,
            },
            {
                variable: 'housing_median_year_built_projected',
                title: '2028, Median Year Built',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
            {
                variable: 'housing_median_year_moved_in_projected',
                title: '2028, Median Year Moved In',
                format: ExcelNumberFormat.FORMAT_NUMBER_NO_FORMAT,
            },
        ],
    },
];

export const SQFT_PER_CAPITA_REPORT_DATA = [
    {
        title: 'SE:A10064:Projections',
        items: [
            {
                variable: 'population_density',
                title: 'Population Density',
                format: ExcelNumberFormat.FORMAT_NUMBER_1_DECIMAL,
            },
            {
                variable: 'population',
                title: 'Current Population',
            },
            {
                variable: 'incoming_population_census',
                title: 'Incoming Population - Census',
            },
            {
                variable: 'incoming_population_housing',
                title: 'Incoming Population - Housing Construction',
            },
            {
                variable: 'population_projected_census',
                title: 'Projected Population - Census',
            },
            {
                variable: 'population_projected_housing',
                title: 'Projected Population - Housing Construction',
            },
            {
                variable: 'population_growth_census',
                title: 'Population Growth - Census (Percentage)',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'population_growth_housing',
                title: 'Population Growth - Housing (Percentage)',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'median_household_income',
                title: 'Current Median Household Income',
            },
            {
                variable: 'median_household_income_projected',
                title: 'Projected Median Household Income',
            },
            {
                variable: 'average_household_income',
                title: 'Current Average Household Income',
            },
            {
                variable: 'households',
                title: 'Current Number of Households',
            },
            {
                variable: 'housing_occupied_percent',
                title: 'Households, Owner Occupied (Percentage)',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'housing_renters_percent',
                title: 'Households, Renter Occupied (Percentage)',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'facilities_count',
                title: 'Current Number of Facilities',
            },
            {
                variable: 'facilities_cc',
                title: 'Current Number of Climate Controlled Facilities',
            },
            {
                variable: 'facilities_cc_percent',
                title: 'Current Number of Facilities Offering Climate Control (Percentage)',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'facilities_count_incoming',
                title: 'Number of Incoming Facilities',
            },
            {
                variable: 'facilities_count_projected',
                title: 'Projected Number of Facilities',
            },
            {
                variable: 'gross_sqft',
                title: 'Current Gross Square Footage',
            },
            {
                variable: 'gross_sqft_incoming',
                title: 'Incoming Gross Square Footage',
            },
            {
                variable: 'gross_sqft_projected',
                title: 'Projected Gross Square Footage',
            },
            {
                variable: 'rent_sqft',
                title: 'Current Rentable Square Footage',
            },
            {
                variable: 'rent_sqft_incoming',
                title: 'Incoming Rentable Square Footage',
            },
            {
                variable: 'rent_sqft_projected',
                title: 'Projected Rentable Square Footage',
            },
            {
                variable: 'sqft_driveup_percent',
                title: '%Gross Drive-up Square Footage',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'rent_sqft_driveup_percent',
                title: '%Rentable Drive-up Square Footage',
                format: ExcelNumberFormat.FORMAT_PERCENT,
            },
            {
                variable: 'sqft_per_capita',
                title: 'Current Square Footage per Capita',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_household',
                title: 'Current Square Footage per Household',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_driveup',
                title: 'Current Rentable Drive Up Square Footage Per Capita',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_indoor',
                title: 'Current Rentable Indoor Square Footage Per Capita',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_projected_census',
                title: 'Projected Square Footage per Capita - Census',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_projected_housing',
                title: 'Projected Square Footage per Capita - Housing Construction',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_driveup_projected_census',
                title: 'Projected Drive Up Square Footage Per Capita - Census',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_driveup_projected_housing',
                title: 'Projected Drive Up Square Footage Per Capita - Housing Construction',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_indoor_projected_census',
                title: 'Projected Indoor Square Footage Per Capita - Census',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
            {
                variable: 'sqft_per_capita_indoor_projected_housing',
                title: 'Projected Indoor Square Footage Per Capita - Housing Construction',
                format: ExcelNumberFormat.FORMAT_NUMBER_2_DECIMAL,
            },
        ],
    },
];

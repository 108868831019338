// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import BusComponent from '../BusComponent';
import SearchResultAnnotation from './SearchResultAnnotation';
import { STATE_TYPES } from './LocationPanel';
import LocationActions from './LocationActions';

import ApplicationMode from '../../enums/ApplicationMode';
import LocationAnalysisItemOrigin from '../../enums/LocationAnalysisItemOrigin';
import AppConfig from '../../appConfig';

/**
 * @typedef Props
 * @property {string} mapInstanceId
 * @property {import('../../objects/LocationAnalysisItem').default} selectedItem
 * @property {import('react-intl').intlShape} intl
 *
 * @typedef State
 * @property {import('../../objects/UserInfo').default | {}} userInfo
 *
 * @extends BusComponent<Props, State>
 */
class LocationSubheader extends BusComponent {
    constructor(props, context) {
        super(props, context);
        /** @type {State} */
        this.state = {
            userInfo: {},
        };
    }

    componentDidMount() {
        this.bus.once('USER_INFO_GET_SUCCESS', this.onUserInfo);
        this.emit('USER_INFO_GET_REQUEST', { source: this });
    }

    componentWillUnmount() {
        this.unbindGluBusEvents();
        this.bus.off('USER_INFO_GET_SUCCESS', this.onUserInfo);
    }

    /**
     * @param {import('../../objects/UserInfo').default} userInfo
     * @param {BusComponent} source
     */
    onUserInfo = (userInfo, source) => {
        if (source !== this) return;
        this.setState({ userInfo });
    };

    render() {
        // Don't render the options in view and embed mode
        if (ApplicationMode.isViewMode(this.context.applicationMode)) {
            return null;
        }

        const { selectedItem, mapInstanceId } = this.props;

        const showAddToAnnotationsButton =
            selectedItem.itemOrigin ===
            LocationAnalysisItemOrigin.SEARCH_RESULT && AppConfig.constants.shouldShowAddToAnnotationsButton && false;

        const actions = [];


        if (showAddToAnnotationsButton) {
            actions.push(<div key="spacer2" className="spacer" />);
            actions.push(
                <SearchResultAnnotation
                    key="annotationsButton"
                    selectedItem={selectedItem}
                    mapInstanceId={mapInstanceId}
                />,
            );
        }

        return (
            <div className="flex-it column">
                <div className="location-info__location-actions flex-it center wrap">
                    <div key="spacer1" className="spacer" />
                    <LocationActions>{actions}</LocationActions>
                </div>
                <div className="location-info__section-divider" />
            </div>
        );
    }
}

LocationSubheader.propTypes = {
    mapInstanceId: PropTypes.string.isRequired,
    selectedItem: PropTypes.object.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(LocationSubheader);

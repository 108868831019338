import React from 'react';

import BusComponent from '../BusComponent';
import MapViewer from '../MapViewer';
import MapMaskEditor from '../mapMask/MapMaskEditor';
import ApplicationFrameType from '../../enums/ApplicationFrameType';
import AppConfig from '../../appConfig';
import MapControls from '../mapControls/MapControls';
import MapFiltersIndicator from '../mapIndicators/MapFiltersIndicator';

class MapMaskEditorFrame extends BusComponent {
    componentWillMount() {
        this.emit('APPLICATION_FRAME_TYPE_CHANGED', ApplicationFrameType.MASK_EDITOR);
        AppConfig.sentryRecordEvent('entered map mask frame');
    }

    render() {
        const { mapInstance } = this.props;
        return (
            <div className="frame frame--masking">
                <div className="frame__map">
                    <MapViewer
                        mapInstance={mapInstance}
                        selection
                        mask
                        annotations
                    />
                </div>
                <div className="frame__browser frame__browser--left">
                    <MapMaskEditor mapInstance={mapInstance} />
                </div>
                <div className="frame__components">
                    <div className="frame__components-block frame__components-block--top-right">
                        <MapFiltersIndicator
                            mapInstance={mapInstance}
                            applicationFrameType={ApplicationFrameType.MASK_EDITOR}
                        />
                    </div>
                    <div className="frame__components-block frame__components-block--bottom-right">
                        <MapControls mapInstance={mapInstance} />
                    </div>
                </div>
            </div>
        );
    }
}

export default MapMaskEditorFrame;

import GLU from '../glu2.js/src/index';

class VisualReportsApi extends GLU.Api {
  constructor(endpoint) {
    super(endpoint);

    this.createApiActions(
      {
        name: 'getVisualReports',
        method: GLU.Api.Get,
        path: '',
        credentials: true,
      },
      {
        name: 'addVisualReport',
        method: GLU.Api.Post,
        path: '',
        credentials: true,
      },
      {
        name: 'updateVisualReport',
        method: GLU.Api.Post,
        path: '/update',
        credentials: true,
      },
      {
        name: 'deleteVisualReport',
        method: GLU.Api.Delete,
        path: '',
        credentials: true,
      },
    );
  }
}

export default VisualReportsApi;

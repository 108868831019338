module.exports={
  "byWithSufix": "By {suffix}",
  "by": "by",
  "as": "as",
  "census": "Census",
  "moreInfo": "More info",
  "info": "Info",
  "mapping": "Mapping",
  "feedback": "Feedback",
  "share": "Share",
  "more": "More",
  "less": "Less",
  "help": "Help",
  "moreOptions": "More options",
  "loadingPleaseWait": "Loading, please wait...",
  "details": "Details",
  "copyToClipboard": "Copy to clipboard",
  "delete": "Delete",
  "pasteFromClipboard": "Paste from clipboard",
  "done": "Done",
  "send": "Send",
  "add": "Add",
  "added": "Added",
  "remove": "Remove",
  "upload": "Upload",
  "show": "Show",
  "hide": "Hide",
  "off": "Off",
  "of": "of",
  "numberOfLimitSaved": "{number} of {limit} saved",
  "title": "Title",
  "reset": "Reset",
  "create": "Create",
  "ok": "Ok",
  "okGotIt": "OK, Got It",
  "rename": "Rename",
  "description": "Description",
  "descriptionOptional": "Description (optional)",
  "save": "Save",
  "saving": "Saving...",
  "pleaseWait": "Please wait...",
  "exportLayerAs": "Export layer as",
  "cancel": "Cancel",
  "continue": "Continue",
  "continuing": "Continuing...",
  "detailsAboutTitle": "Details about {title}",
  "confirm": "Confirm",
  "topic": "Topic",
  "source": "Source",
  "geography": "Geography",
  "close": "Close",
  "exitPreview": "Exit preview",
  "slides": "Slides",
  "original": "Original",
  "export": "Export",
  "categories": "Categories",
  "dataset": "Dataset",
  "tables": "Tables",
  "classes": "Classes",
  "browse": "Browse",
  "zoom": "Zoom",
  "format": "Format",
  "saveAs": "Save as",
  "untitledProject": "Untitled project",
  "untitled": "Untitled",
  "saved": "Saved",
  "annotations": "Annotations",
  "featured": "Featured",
  "upgrade": "UPGRADE",
  "viewSavedList": "Open saved list",
  "areYouSureYouWantToDelete": "Are you sure you want to delete",
  "sendFeedback": "Send Feedback",
  "year": "Year",
  "allSources": "All sources",
  "allYears": "All years",
  "preview": "Preview",
  "noData": "No data",
  "insufficientData": "Insufficient data",
  "cancelUppercase": "CANCEL",
  "saveUppercase": "SAVE",
  "name": "Name",
  "survey": "Survey",
  "table": "Table",
  "variable": "Variable",
  "proFeature": "Pro feature",
  "units": "Units",
  "noResults": "No results",
  "noResultsFound": "No results found",
  "loading": "Loading...",
  "searchIllustration": "Search illustration",
  "openEmbedInApp": "Open project in app",
  "visitWebsite": "Visit Website",
  "citation": "Citation",
  "back": "Back",
  "min": "min",
  "miles": "{count, plural, =1 {mile} other {miles}}",
  "edit": "Edit",
  "getInsights": "Get Insights",
  "change": "Change",
  "showInLegend": "Show in legend",
  "emptyValue": "Empty Value",
  "allItems": "All items",
  "toggleVisibility": "Toggle visibility",
  "clear": "Clear",
  "apply": "Apply",
  "any": "any",
  "selectValue": "Select value",
  "sortBy": "Sort by",
  "downloading": "Downloading...",
  "deleteAll": "Delete all",
  "seeAll": "See all"
}

module.exports={
  "createReport": "Create report",
  "createaReport": "Create a report",
  "creatingReport": "Creating report...",
  "downloadReport": "Download report",
  "createATableReportBySelectingGeographiesOnTheMap": "Create a table report by selecting geographies on the map.",
  "createReportFrom": "Create report from:",
  "createReportFor": "Create report for",
  "loadingReportInformation": "Loading report information",
  "yourReportWillBeDisplayedInNewWindow": "Your report will be displayed in new window. {br} Make sure your popup blocker is not enabled.",
  "yourReportWillBeDownloaded": "Preparing the report for download… please wait. The report will be downloaded as Microsoft Excel (.xlsx).",
  "notAvailable": "Not available",
  "notAvailableForCot": "Not available for COT",
  "changeOverTimeIsApplied": "Change over time is applied",
  "notAvailableForSelectedSurvey": "Not available for selected survey",
  "resetCustomAnalysisValues": {
    "description": "Reset will remove your custom added values. You can always add them again.",
    "sendFeedback": "If you think we should add some other values as default, please",
    "sendFeedbackButton": "Send us feedback.",
    "help": "Help"
  },
  "noReportsAvailable": "No reports available",
  "upgradeToProForAccess": "Upgrade to Pro for Access"
}

// @ts-check
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import BusComponent from '../../BusComponent';
import UserUploadDataLegendItem from './UserUploadDataLegendItem';
import VisibilityToggle from '../../form/VisibilityToggle';

/**
 * @typedef Props
 * @property {import('../../../objects/UserDataLayer').default} layerGroup
 * @property {string} mapInstanceId
 * @property {import('react-intl').intlShape} intl
 *
 * @extends {BusComponent<Props>}
 */
class UserUploadDataGroup extends BusComponent {
    onVisibilityToggle = () => {
        const { mapInstanceId, layerGroup } = this.props;

        setTimeout(() => {
            this.emit('UPDATE_USER_LAYER_VISIBILITY', {
                mapInstanceId: mapInstanceId,
                id: layerGroup.id
            });
        }, 100);
    };

    render() {
        const { layerGroup, mapInstanceId } = this.props;
        const { title, styleRules, visible } = layerGroup;

        const legendClassesItems = styleRules
            .map(styleRule => (
                <UserUploadDataLegendItem
                    key={styleRule.id}
                    item={styleRule}
                    mapInstanceId={mapInstanceId}
                    singleItem={styleRules.length === 1}
                />
            ));

        const titleClasses = classNames({
            disabled: !visible,
        });

        return (
            <div className="layer-library-legend__group">
                <div className="layer-library-legend__title">
                    <span className={titleClasses}>{title}</span>
                    <div className="flex-it center">
                        <VisibilityToggle
                            onClick={this.onVisibilityToggle}
                            visible={!!visible}
                        />
                    </div>
                </div>
                {visible && <div className="layer-library-legend__items">
                    {legendClassesItems}
                </div>}
            </div>
        );
    }
}

UserUploadDataGroup.propTypes = {
    layerGroup: PropTypes.object.isRequired,
    mapInstanceId: PropTypes.string.isRequired,
    intl: PropTypes.object.isRequired,
};

export default injectIntl(UserUploadDataGroup);

import React from 'react';
import PropTypes from 'prop-types';

const Indicator = ({
       isCondensedLayout,
       isReportEditor,
       clickHandle,
       text,
       onClickClear,
       multipleOptions,
       editFiltersDialogVisible }) =>
    (<div className="mask-indicator">
        <button
            className="mask-indicator__title padding-left-10 flex-it justify-center center grow"
            disabled={isCondensedLayout}
            onClick={clickHandle}
        >
            <div className="flex-it justify-center center">
                <i className="material-icons md-14" style={{paddingRight: '12px'}}> tune </i>
                <span style={{color: 'rgba(0, 0, 0, 0.87)'}}> {text} </span>
                {multipleOptions && <i className="material-icons md-18 mask-indicator__toggle">
                    {editFiltersDialogVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
                </i>}
            </div>
        </button>
        {(!multipleOptions && !isReportEditor) &&
            <button className="mask-indicator__close" onClick={onClickClear}>
                <i className="material-icons md-18">close</i>
            </button>
        }
    </div>);

Indicator.propTypes = {
    isCondensedLayout: PropTypes.bool,
    text: PropTypes.string.isRequired,
    clickHandle: PropTypes.func.isRequired,
    onClickClear: PropTypes.func,
    multipleOptions: PropTypes.bool,
    editFiltersDialogVisible: PropTypes.bool,
    isReportEditor: PropTypes.bool,
};

Indicator.defaultProps = {
    isCondensedLayout: false,
    multipleOptions: false,
    editFiltersDialogVisible: false,
    onClickClear: undefined,
    isReportEditor: false,
};

export default Indicator;
